<template>
  <b-card>
    <b-tabs pills>
      <!-- Tab: SSRM -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">SSRM</span>
        </template>
        <ssrm class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Distribution -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Token Distribution</span>
        </template>
        <tokenDistribution class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Transaction Fees -->
      <b-tab>
        <template #title>
          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Transaction Fees</span>
        </template>
        <transactionFees class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: valueOfRMZ -->
      <b-tab>
        <template #title>
          <feather-icon icon="CreditCardIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Value Of RMZ / RMZr</span>
        </template>
        <valueOfRMZ class="mt-2 pt-75" />
      </b-tab>
      <!-- Tab: withdrawalRequests -->
      <b-tab>
        <template #title>
          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Withdrawal Requests</span>
        </template>
        <withdrawalRequests class="mt-2 pt-75" />
      </b-tab>
      <!-- Tab: withdrawals -->
      <b-tab>
        <template #title>
          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Withdrawals</span>
        </template>
        <withdrawals class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import ssrm from "./srrm";
import tokenDistribution from "./token-distribution";
import transactionFees from "./transaction-fees";
import valueOfRMZ from "./value-of-RMZr";
import withdrawalRequests from "./withdrawl-requests";
import withdrawals from "./withdrawls";
export default {
  name: "token-managements",
  components: { withdrawals, withdrawalRequests, valueOfRMZ, ssrm, tokenDistribution, transactionFees },
};
</script>

<style></style>
