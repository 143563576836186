<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar :src="userData.picture" :text="avatarText(userData.fullName)" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button @click="() => this.$emit('edit')" variant="primary"> Edit </b-button>
              <!-- <b-button variant="outline-danger" class="ml-1"> Delete </b-button> -->
            </div>
          </div>
        </div>
        <div>
          <b-card-text class="mb-0"> OTP Code </b-card-text>
          <b-form-checkbox
            :disabled="!userData.otp_enabled"
            @change="toggleOTP"
            v-model="userData.otp_enabled"
            class="custom-control-primary"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ userData.total_rmz }}</h5>
              <small>Total RMZ</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ userData.total_rmzr }}</h5>
              <small>Total RMZr</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="LinkIcon" class="mr-75" />
              <span class="font-weight-bold">Dedicated IP</span>
            </th>

            <b-badge variant="primary">
              {{ userData.dedicated_ip }}
            </b-badge>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Phone Number Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="userData.phone_number_verified ? 'success' : 'warning'">
                {{ userData.phone_number_verified ? "Verified" : "Unverified" }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Phone Number</span>
            </th>
            <td class="pb-50">
              {{ userData.phone_number }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PocketIcon" class="mr-75" />
              <span class="font-weight-bold">Activated</span>
            </th>
            <td class="pb-50">
              {{ userData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="ClockIcon" class="mr-75" />
              <span class="font-weight-bold">Average daily active time</span>
            </th>
            <td class="pb-50">
              {{ this.calcTime(userData.daily_active_time) }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon icon="ClockIcon" class="mr-75" />
              <span class="font-weight-bold">Register Date</span>
            </th>
            <td class="pb-50">
              {{ getRegisterDate(userData.register_date) }}
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon icon="UsersIcon" class="mr-75" />
              <span class="font-weight-bold">No. Referrer</span>
            </th>
            <td class="pb-50">
              {{ userData.no_refferer }}
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon icon="Minimize2Icon" class="mr-75" />
              <span class="font-weight-bold">Number of Exchanges API</span>
            </th>
            <td class="pb-50">
              {{ userData.num_of_exchange }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-modal id="disable-otp" v-model="showDisableOTP" size="lg" title="Disable OTP" hide-footer centered scrollable>
      <div class="d-flex flex-column align-items-center">
        <span> Are you sure you want to disable otp? you can't enable it again </span>
        <div class="d-flex justify-content-between">
          <button @click="() => disableOTP(exchangeGoingToDelete)" class="btn btn-success mr-4 ml-4">YES</button>
          <button @click="hideModal" class="btn btn-warning mr-4 ml-4">NO</button>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useUsersList from "../useUsersList";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  data() {
    return {
      showDisableOTP: false,
    };
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList();
    return {
      avatarText,
      resolveUserRoleVariant,
    };
  },
  methods: {
    getRegisterDate(input) {
      const date = new Date(input);

      const stringDate = `${date.getFullYear()}/${
        date.getMonth() + 1
      }/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
      return stringDate;
    },
    hideModal() {
      this.$bvModal.hide("disable-otp");
      this.userData.otp_enabled = true;
    },
    disableOTP(value) {
      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/user/info/otp/disable`, { user_id: this.userData.id })
        .then((res) => {
          this.$bvModal.hide("disable-otp");
        });
    },
    toggleOTP(value) {
      if (value == false) {
        this.showDisableOTP = true;
      }
    },
    calcTime(time = 0) {
      let hour = Math.floor(time / 3600);
      let minute = Math.round((time % 3600) / 60);
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      return `${hour}:${minute}`;
    },
  },
};
</script>

<style></style>
