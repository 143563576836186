<template>
    <b-card>
      <b-row>
        <b-col md="3">
          <div class="input-item m-1">
            <div class="input-label">Name</div>
            <input v-model="service.name" class="form-control" type="text" required />
          </div>
        </b-col>
        <b-col md="3">
          <div class="input-item m-1">
            <div class="input-label">Profile Logo</div>
            <input v-model="service.profileLogo" class="form-control" type="text" />
          </div>
        </b-col>
        <b-col md="3">
          <div class="input-item m-1">
            <div class="input-label">WebPage Link</div>
            <input v-model="service.webPageLink" class="form-control" type="text" />
          </div>
        </b-col>
        <b-col md="3">
          <div class="input-item m-1">
            <div class="input-label">Developer Team</div>
            <input v-model="service.developerTeam" class="form-control" type="text" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        
        
        <b-col md="3">
          <div class="input-item m-1">
            <div class="input-label">alarmConfigCount</div>
            <input v-model="service.alarmConfigCount" class="form-control" type="number" step="0.0001" required min="0" />
          </div>
        </b-col>

        <b-col md="3">
          <div class="input-item m-1">
            <div class="input-label">Total Price</div>
            <input v-model="service.totalPrice" class="form-control" type="number" step="0.0001" required min="0" />
          </div>
        </b-col>
        <b-col md="3">
          <div class="input-item m-1">
            <div class="input-label">Each Success Price</div>
            <input
              v-model="service.eachSuccessPrice"
              class="form-control"
              type="number"
              step="0.00001"
              required
              min="0"
            />
          </div>
        </b-col>
        <b-col md="3">
          <div class="input-item m-1">
            <div class="input-label">Number Of Subscribers</div>
            <input v-model="service.noOfSubscribers" class="form-control" type="number" step="1" required min="0" />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import vSelect from "vue-select";
  
  export default {
    components: {
      vSelect,
    },
    props: {
      service: {
        name: String,
        profileLogo: String,
        webPageLink: String,
        developerTeam: String,
        noOfUsers: Number,
        noOfExchanges: Number,
        noOfPairs: Number,
        noOfOpenPositions: Number,
        price24: Number,
        noOfSubscribers: Number,
      },
    },
  };
  </script>
  <style lang=""></style>
  