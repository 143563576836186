<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="input-item">
          <div class="input-label">Name</div>
          <input class="form-control" type="text" v-model="currentUser.name" />
        </div>
        <!-- <div class="input-item">
          <div class="input-label ">Status</div>
          <input class="form-control " type="checkbox" v-model="currentUser.status" />
        </div> -->

        <div class="input-item" v-if="!currentUser.id">
          <div class="input-label">Password</div>
          <input class="form-control" type="text" v-model="currentUser.password" />
        </div>
        <div class="input-item">
          <div class="input-label">User Tier</div>
          <select class="form-control" v-model="currentUser.user_tier">
            <option v-for="tier in tiers" :value="tier.name" :key="tier.name">{{ tier.name }}</option>
          </select>
        </div>

        <div class="input-item">
          <div class="input-label">Total RMZ</div>
          <input class="form-control" type="text" v-model="currentUser.total_rmz" />
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="input-item">
          <div class="input-label">Email</div>
          <input class="form-control" type="text" v-model="currentUser.email" :disabled="currentUser.id" />
        </div>
        <div class="input-item">
          <div class="input-label">Username</div>
          <input class="form-control" type="text" v-model="currentUser.username" />
        </div>
        <div class="input-item">
          <div class="input-label">Total RMZr</div>
          <input class="form-control" type="text" v-model="currentUser.total_rmzr" />
        </div>
        <div class="input-item">
          <b-form-group>
            <label>Tier Expire at</label>
            <form class="d-flex">
              <flat-pickr
                v-model="expireDate"
                class="form-control"
                @on-close="doSomethingOnClose"
                :config="dateTimePicker"
              />
              <button class="btn btn-outline-primary ml-2">
                <feather-icon icon="XCircleIcon" size="14" />
              </button>
            </form>
          </b-form-group>
        </div>
      </div>
    </div>
    <div v-if="Object.keys(errors).length" class="mt-3">
      <label class="text-bold text-danger">Please fix the following error(s)</label>
      <ul>
        <li v-for="err in errors" :key="err" class="text-danger ml-2">{{ err }}</li>
      </ul>
    </div>
    <div class="row text-center mx-n1">
      <div class="col-6 p-1">
        <button
          v-if="currentUser.id"
          class="btn btn-success w-100"
          :class="isSaving && 'is-loading'"
          @click="updateUser"
        >
          Save
        </button>
        <button v-else class="btn btn-success w-100" :class="isSaving && 'is-loading'" @click="createNewUser">
          Create
        </button>
      </div>
      <div class="col-6 p-1">
        <button class="btn btn-outline-dark w-100" @click="$emit('cancel')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import validate from "@/helpers/validate";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import flatPickr from "vue-flatpickr-component";

Vue.use(VueReCaptcha, {
  siteKey: "6Lc1YnsaAAAAAOHriQlDAzPzbF4wpMPM8XYnm13e",
  loaderOptions: {
    useRecaptchaNet: true,
  },
});
export default {
  components: { flatPickr },
  data() {
    return {
      dateTimePicker: {
        enableTime: true,
        dateFormat: "d-m-Y H:i",
      },
      currentUser: {
        total_rmzr: 0,
        total_rmz: 0,
      },
      isSaving: false,
      errors: {},
      tiers: [],
      expireDate: "",
    };
  },
  methods: {
    doSomethingOnClose(date) {
      this.currentUser.tier_expire_at = +date[0];
    },
    async getTiers() {
      console.log("get tiers");
      await this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/userTiers/getTiers`).then(({ data }) => {
        // this.tiers.push(...data.data);
        this.tiers = data.data;
      });
    },
    updateUser() {
      this.isSaving = true;
      this.$http
        .patch(
          `${this.$store.state.appConfig.base_url}/api/v1/admin/users/updateUser/${this.currentUser.id}`,
          this.currentUser
        )
        .then(({ data }) => {
          this.$bvToast.toast(`User was Updated successfully`, {
            title: `User Update`,
            variant: "success",
            solid: true,
          });
          this.$emit("updated");
          this.$emit("cancel");
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async createNewUser() {
      this.errors = {};
      let validationResult = validate("newUser", this.currentUser);
      if (validationResult) return (this.errors = validationResult);
      this.isSaving = true;
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");
      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/admin/users/createUser`, {
          ...this.currentUser,
          captcha: token,
        })
        .then(({ data }) => {
          this.$set(this.currentUser, "id", data.data?.id);
          this.$bvToast.toast(`User was created successfully`, {
            title: `User Created`,
            variant: "success",
            solid: true,
          });
          this.$emit("cancel");
        })
        .catch((err) => {
          let errMsg = err.response.data && err.response.data.message;
          this.errors.respError = errMsg || "An error occured";
        })
        .finally(() => {
          this.isSaving = false;
          this.$emit("cancel");
        });
    },
    calcTime(time = 0) {
      let hour = Math.floor(time / 3600);
      let minute = Math.round((time % 3600) / 60);
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      return `${hour}:${minute}`;
    },
  },
  created() {
    this.getTiers();
  },
};
</script>

<style scoped>
.input-item {
  margin-bottom: 10px;
}
.input-item .input-label {
  margin-bottom: 3px;
}
.input-item > * {
  width: 100%;
}
</style>
