<template>
  <!-- <div> -->
  <button v-if="type == 'info'" class="btn border" :class="value && 'btn-info'" @click="$emit('input', !value)">
    <slot />
  </button>
  <button
    v-else-if="type == 'warning'"
    class="btn border"
    :class="value && 'btn-warning'"
    @click="$emit('input', !value)"
  >
    <slot />
  </button>
  <button v-else class="btn border" :class="value && 'btn-success'" @click="$emit('input', !value)">
    <slot />
  </button>
  <!-- </div> -->
</template>

<script>
export default {
  name: "ToggleButton",
  props: ["value", "type"],
};
</script>
