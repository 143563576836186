<template>
  <div>
    <b-card>
      <div>
        <b-table striped :items="exchangeList" :fields="fields" :busy="isBusy">
          <template #cell(international)="international">
            <b-form-checkbox
              v-model="international.item.international"
              @change="() => toggleInternational(international.item)"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </template>

          <template #cell(is_active)="is_active">
            <b-form-checkbox
              v-model="is_active.item.is_active"
              @change="() => toggleActivation(is_active.item)"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </template>

          <template #cell(ccxt_supported)="ccxt_supported">
            <b-alert v-if="ccxt_supported.item.ccxt_supported" class="mb-0" show variant="success">
              <div class="alert-body">Supporting</div>
            </b-alert>
            <b-alert v-else class="mb-0" show variant="warning">
              <div class="alert-body">Not Supporting</div>
            </b-alert>
          </template>

          <template #cell(has_margin)="has_margin">
            <b-alert v-if="has_margin.item.has_margin" class="mb-0" show variant="success">
              <div class="alert-body">Supporting</div>
            </b-alert>
            <b-alert v-else class="mb-0" show variant="warning">
              <div class="alert-body">Not Supporting</div>
            </b-alert>
          </template>

          <template #cell(has_future)="has_future">
            <b-alert v-if="has_future.item.has_future" class="mb-0" show variant="success">
              <div class="alert-body">Supporting</div>
            </b-alert>
            <b-alert v-else class="mb-0" show variant="warning">
              <div class="alert-body">Not Supporting</div>
            </b-alert>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="() => showExchangeDetails(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <!-- <template #cell(index)="data">{{ data.index + 1 }}</template> -->
        </b-table>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {
  name: "exchanges",
  components: {},
  data() {
    return {
      selectedExchange: {},
      switchGroup: "",
      exchangeGoingToDelete: "",
      isBusy: false,
      page: 1,
      exchangePerPage: 12,
      idFetchingData: false,
      exchangeList: [],
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: false,
        },
        {
          key: "fa_name",
          label: "Fa_Name",
          sortable: true,
        },

        {
          key: "ccxt_supported",
          label: "ccxt",
          sortable: true,
        },
        {
          key: "has_future",
          label: "Future",
          sortable: true,
        },
        {
          key: "has_margin",
          label: "Margin",
          sortable: true,
        },

        {
          key: "international",
          label: "international",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
        {
          key: "is_active",
          label: "is Active",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
        {
          key: "actions",
          label: "actions",
        },
      ],
    };
  },
  methods: {
    showExchangeDetails(data) {
      this.showAddExchange = true;
      this.selectedExchange = data;
    },

    toggleActivation(exchange) {
      this.isBusy = !this.isBusy;
      // let act = exchange.is_active === true ? false : true;
      let act = exchange.is_active;

      const b = {
        international: exchange.international,
        is_active: act,
        fa_name: exchange.fa_name,
      };
      const payload = JSON.parse(JSON.stringify(b));

      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/adminxdata/${exchange.id}`, payload)
        .then((res) => {
          console.log(res);
          this.getExchangeList();
        })
        .catch((err) => {
          this.getExchangeList();
        });
    },
    toggleInternational(exchange) {
      this.isBusy = !this.isBusy;
      let act = exchange.international;

      const b = {
        international: act,
        is_active: exchange.is_active,
        fa_name: exchange.fa_name,
      };
      const payload = JSON.parse(JSON.stringify(b));

      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/adminxdata/${exchange.id}`, payload)
        .then((res) => {
          console.log(res);
          this.getExchangeList();
        })
        .catch((err) => {
          this.getExchangeList();
        });
    },
    getExchangeList() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/adminxdata`).then((res) => {
        this.exchangeList = res.data.data;
        console.log(res);
        this.isBusy = false;
      });
    },
  },
  mounted() {
    this.getExchangeList();
  },
};
</script>
