<template>
  <div>
    <h3 class="m-1">Setting</h3>
    <div class="input-label">Percent</div>
    <input class="form-control" required type="number" max="99" min="0" v-model="setting.percent" />

    <div class="d-flex flex-row m-1 mb-2">
      <div class="input-label">Share Percent For Levels</div>
      <input class="form-control" required type="checkbox" v-model="setting.is_share_percent_for_levels" />
    </div>
    <div v-if="setting.is_share_percent_for_levels" class="input-item m-1">
      <div class="input-label">Share Percent For Levels</div>
      <!-- <input class="form-control" required type="text" v-model="setting.share_percent_for_levels" /> -->
      <div>
        <b-form ref="form">
          <div
            v-for="(item, index) in setting.share_percent_for_levels"
            :key="item"
            class="d-flex justify-content-between"
          >
            <div>
              <input
                class="form-control mt-1"
                v-model="setting.share_percent_for_levels[index]"
                placeholder="Enter value"
                type="number"
                max="100"
              />
            </div>
          </div>
        </b-form>
      </div>
    </div>

    <div v-if="setting.is_descending_slope" class="m-1">
      <div class="input-label">Descending Slope Percentage</div>

      <div>
        <div>
          <b-form ref="form" class="" @submit.prevent="repeateAgain">
            <div
              v-for="(item, index) in setting.descending_slope_percentage"
              :key="item"
              class="d-flex justify-content-between"
            >
              <div>
                <input
                  class="form-control mt-1"
                  v-model="setting.descending_slope_percentage[index]"
                  :disabled="index == 0"
                  placeholder="Enter value"
                />
              </div>
              <div>
                <b-button
                  variant="outline-danger"
                  class="mt-1 ml-1 mr-1"
                  @click="removeItem(index, setting.descending_slope_percentage)"
                >
                  <feather-icon icon="XIcon" />
                  <span>Delete</span>
                </b-button>
              </div>
            </div>
          </b-form>
        </div>
        <b-button variant="primary" class="mt-1" @click="repeateAgain(setting.descending_slope_percentage)">
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Add New</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: [],
  data() {
    return {};
  },
  props: ["setting"],
  methods: {
    repeateAgainForLevels(data) {
      if (data[data.length - 1] != 0) {
        data.push(0);
      }
    },
    repeateAgain(data) {
      if (data[data.length - 1] != 0) {
        data.push(0);
      }
    },
    removeItem(index, data) {
      data.splice(index, 1);
    },
  },
};
</script>
