<template>
  <div class="col-12 col-sm-5">
    <b-card>
      <div class="input-label">Exchanges</div>
      <div>
        <span class="m-1">Select All</span>
        <input type="checkbox" v-model="service.hasAllExchanges" />
      </div>
      <div v-if="!service.hasAllExchanges">
        <b-form-group>
          <v-select
            key="label"
            v-model="selectedExchanges"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="exchanges"
            class="select-size-md"
            label="label"
            multiple
            @input="setSelected"
          />
        </b-form-group>

        <vue-perfect-scrollbar :settings="settings" class="scroll-area">
          <div v-for="item in service.exchanges" :key="item.id" class="selectable-exchange">
            <div
              :class="{
                'unactive-exchange': selectedExchange.exchange != item.exchange,
                'active-exchange': selectedExchange.exchange == item.exchange,
              }"
              @click="() => selectExchange(item)"
            >
              {{ item.exchange }}
            </div>
          </div>
        </vue-perfect-scrollbar>
      </div>
    </b-card>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    VuePerfectScrollbar,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
      },
      selectedExchanges: [],
      exchanges: [],
      selectedExchange: {
        pairs: [],
      },
    };
  },
  props: {
    service: {
      name: String,
      profileLogo: String,
      webPageLink: String,
      developerTeam: String,
      noOfUsers: Number,
      noOfExchanges: Number,
      noOfPairs: Number,
      noOfOpenPositions: Number,
      price24: Number,
    },
  },
  methods: {
    selectExchange(exchange) {
      this.selectedExchange = exchange;
      //   this.getPairs();
    },
    setSelected(value) {
      // console.log("exElement this.selectedExchanges", this.selectedExchanges);

      value.forEach((item) => {
        // console.log("setSelected", item);

        if (this.service.exchanges.filter((e) => e.exchange == item).length > 0) {
        } else {
          this.service.exchanges.push({
            exchange: item,
            pairs: [],
          });
        }
      });
    },
    getExchanges() {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v2/trades`)
        .then((result) => {
          this.exchanges = result.data.data;
        })
        .catch((err) => {});
    },
    checkNumberOfExchanges() {
      if (
        this.service.exchanges &&
        this.service.noOfExchanges &&
        this.service.exchanges.length > this.service.noOfExchanges
      ) {
        this.$bvToast.toast("You should not choose exchanges more than 'Number Of Exchanges'", {
          title: "Please check inputs",
          variant: "warning",
          solid: true,
        });
      }
    },
  },
  mounted() {
    this.getExchanges();
  },
  watch: {
    service: {
      handler(newValue, oldValue) {
        this.checkNumberOfExchanges();
      },
      deep: true,
    },
    selectedExchanges: {
      handler(newValue, oldValue) {
        if (this.service.exchanges.length != this.selectedExchanges.length) {
          for (let exIndex = 0; exIndex < this.service.exchanges.length; exIndex++) {
            const exElement = this.service.exchanges[exIndex];

            let findedIndex = -1;
            for (let index = 0; index < this.selectedExchanges.length; index++) {
              const element = this.selectedExchanges[index];

              if (element == exElement.exchange) {
                if (findedIndex == -1) {
                  findedIndex = index;
                }
              }
            }

            if (findedIndex == -1) {
              this.service.exchanges.splice(exIndex, 1);
            }

            if (this.selectedExchanges.length == 0) {
              this.selectedExchange.pairs = [];
            } else {
              this.selectedExchange = this.service.exchanges[0];
            }
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
@import "../../../../../@core/scss/base/components.scss";

.select-type {
  width: 100% !important;
}

.selectable-exchange {
  padding: 2px;
  margin: 2px;
}

.selectable-exchange:hover {
  cursor: pointer;
}

.active-exchange {
  border: 0 solid rgba(60, 60, 60, 0.26);
  // background: $theme-dark-body-bg;
  background: $theme-dark-body-color;
  padding: 20px;
  margin: 2px;
  border-radius: 0.428rem;
}

.unactive-exchange {
  background: whitesmoke;
  padding: 20px;
  margin: 2px;
  border-radius: 0.428rem;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 400px;
  height: 50vh;
}

.select-type {
  width: 220px;
  display: block;
}
</style>
