<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="d-flex align-items-center">
          <div class="input-item">
            <div class="input-label">Name</div>
            <input class="form-control" type="text" v-model="currentUser.name" />
          </div>
          <img
            v-if="currentUser.picture && currentUser.picture != 'null'"
            :src="currentUser.picture"
            class="ml-2"
            style="width: 64px"
          />
          <font-awesome-icon v-else icon="user" class="d-block mx-auto text-gray-light" size="3x" />
        </div>
        <!-- <div class="input-item">
          <div class="input-label">Status</div>
          <input class="form-control" type="text" v-model="currentUser.status" />
        </div> -->
        <div class="input-item" v-if="!currentUser.id">
          <div class="input-label">Password</div>
          <input class="form-control" type="text" v-model="currentUser.password" />
        </div>
        <div class="input-item">
          <div class="input-label">Admin Role</div>
          <select class="form-control" v-model="currentUser.user_tier">
            <option v-for="tier in tiers" :value="tier" :key="tier.name">{{ tier.name }}</option>
          </select>
        </div>

        <div class="input-item">
          <div class="input-label">Total RMZ</div>
          <input class="form-control" type="text" v-model="currentUser.total_rmz" />
        </div>
        <div class="input-item">
          <div class="input-label">Total RMZr</div>
          <input class="form-control" type="text" v-model="currentUser.total_rmzr" />
        </div>
      </div>

      <div class="col-12 col-sm-6"></div>
    </div>
    <div v-if="Object.keys(errors).length" class="mt-3">
      <label class="text-bold text-danger">Please fix the following error(s)</label>
      <ul>
        <li v-for="err in errors" :key="err" class="text-danger ml-2">{{ err }}</li>
      </ul>
    </div>
    <div class="row text-center mx-n1">
      <div class="col-6 p-1">
        <button
          v-if="currentUser.id"
          class="btn btn-success w-100"
          :class="isSaving && 'is-loading'"
          @click="updateUser"
        >
          Save
        </button>
        <button v-else class="btn btn-success w-100" :class="isSaving && 'is-loading'" @click="createNewUser">
          Create
        </button>
      </div>
      <div class="col-6 p-1">
        <button class="btn btn-outline-dark w-100" @click="$emit('cancel')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import validate from "@/helpers/validate";

export default {
  props: ["user", "tiers"],
  data() {
    return {
      currentUser: JSON.parse(JSON.stringify(this.user)),
      isSaving: false,
      errors: {},
    };
  },
  methods: {
    updateUser() {
      this.isSaving = true;
      if (this.currentUser.user_tier.admin_abilities) {
        this.currentUser.admin_abilities = this.currentUser.user_tier.admin_abilities;
        this.currentUser.user_tier = this.currentUser.user_tier.name;
      } else {
        this.currentUser.user_tier = this.currentUser.user_tier.name;
      }
      this.$http
        .patch(
          `${this.$store.state.appConfig.base_url}/api/v1/admin/users/updateUser/${this.currentUser.id}`,
          this.currentUser
        )
        .then(({ data }) => {
          this.$bvToast.toast(`User was Updated successfully`, {
            title: `User Update`,
            variant: "success",
            solid: true,
          });
          this.$emit("updated");
          this.$emit("cancel");
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async createNewUser() {
      this.errors = {};
      let validationResult = validate("newUser", this.currentUser);
      if (validationResult) return (this.errors = validationResult);
      this.isSaving = true;
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");
      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/admin/users/createUser`, {
          ...this.currentUser,
          captcha: token,
        })
        .then(({ data }) => {
          this.$set(this.currentUser, "id", data.data?.id);
          this.$bvToast.toast(`User was created successfully`, {
            title: `User Created`,
            variant: "success",
            solid: true,
          });
          this.$emit("cancel");
        })
        .catch((err) => {
          let errMsg = err.response.data && err.response.data.message;
          this.errors.respError = errMsg || "An error occured";
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    calcTime(time = 0) {
      let hour = Math.floor(time / 3600);
      let minute = Math.round((time % 3600) / 60);
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      return `${hour}:${minute}`;
    },
  },
};
</script>

<style scoped>
.input-item {
  margin-bottom: 10px;
}

.input-item .input-label {
  margin-bottom: 3px;
}

.input-item > * {
  width: 100%;
}
</style>
