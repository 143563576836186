export default [
  {
    path: "/exchange-keys/",
    name: "exchange-keys",
    meta: {
      action: "read",
      resource: "exchangekeysmanagements",
    },
    component: () => import("@/views/pages/exchange-keys"),
  },
];
