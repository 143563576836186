<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }"> User List </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col v-if="!userData.is_system_admin" cols="12" xl="12" lg="8" md="12">
          <user-view-user-info-card :user-data="userData" @edit="showEditUserModal = true" />
        </b-col>
        <b-col v-if="userData.is_system_admin" cols="12" xl="9" lg="8" md="7">
          <user-view-user-info-card :user-data="userData" @edit="showEditUserModal = true" />
        </b-col>
        <b-col v-if="userData.is_system_admin" cols="12" md="5" xl="3" lg="4">
          <user-view-user-plan-card :user-data="userData" />
        </b-col>
      </b-row>
      <!-- {{ ExchangesEnum }} -->
      <b-card cols="12">
        <b-button variant="primary" @click="addPackageModal"> Add New Package </b-button>

        <p>User Packages</p>
        <b-table striped :fields="userPackagesFields" responsive :items="userPackages">
          <template #cell(ex_id)="data">
            {{ getExName(data.value) }}
          </template>
        </b-table>
      </b-card>
    </template>
    <b-modal v-model="showEditUserModal" size="lg" hide-footer title="User Details" centered scrollable>
      <div>
        <div v-if="!userData" class="text-center">
          <b-spinner />
        </div>
        <div v-else>
          <UserDetailsCard
            :user="userData"
            @cancel="showEditUserModal = false"
            :tiers="tiers"
            @updated="() => getUserDetails(userData.id)"
          />
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showAddPackageModal" size="lg" hide-footer title="Add New Package" centered scrollable>
      <div>
        <div v-if="!packages" class="text-center">
          <b-spinner />
        </div>
        <div v-else>
          <div class="input-item">
            <div class="input-label">User Role</div>
            <select class="form-control" v-model="addingPackage">
              <option v-for="tier in packages" :value="tier" :key="tier.name">
                {{ `${tier.category.name} > ${tier.name}` }}
              </option>
            </select>
            <div class="col-6 p-1">
              <button class="btn btn-success w-100" @click="() => addPackageToUser()">Save</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
// import InvoiceList from "@/views/apps/invoice/invoice-list/InvoiceList.vue";
// import userStoreModule from "../userStoreModule";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import UserViewUserPlanCard from "./UserViewUserPlanCard.vue";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import UserViewUserPermissionsCard from "../components/UserViewUserPermissionsCard.vue";
import UserDetailsCard from "./../components/UserDetailsCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    UserDetailsCard,
    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    // InvoiceList,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 1,
      userData: "",
      perPageOptions: [10, 25, 50, 100],
      tiers: [],
      packages: [],
      addingPackage: [],
      userPackages: [],
      userEchanges: [],
      showEditUserModal: false,
      showAddPackageModal: false,
      userPackagesFields: [
        { key: "type", sortable: true },
        { key: "price", sortable: true },
        { key: "name", sortable: true },
        { key: "isActive", sortable: true },
      ],
      // ExchangesEnum: [DEFAULT, BINANCE, EXIR, COINEX, KUCOIN, MAZDAX, NOBITEX, WALLEX, RAMZINEX],
    };
  },

  methods: {
    addPackageToUser() {
      let id = router.currentRoute.params.id;
      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/admin/users/add-package`, {
          package_id: this.addingPackage.id,
          user_id: Number(id),
        })
        .then(({ data }) => {
          this.getPackages();
          this.getUserDetails();
          this.showAddPackageModal = false;
        });
    },
    addPackageModal() {
      this.showAddPackageModal = true;
      this.getPackages();
    },
    getPackages() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packages`).then(({ data }) => {
        this.packages = data.data;
      });
    },
    getExName(exId) {
      switch (exId) {
        case 1:
          return "BINANCE";
        case 2:
          return "EXIR";

        case 3:
          return "COINEX";
        case 4:
          return "KUCOIN";
        case 5:
          return "MAZDAX";
        case 6:
          return "NOBITEX";
        case 7:
          return "WALLEX";
        case 8:
          return "RAMZINEX";
      }
    },
    async getTiers() {
      console.log("get tiers");
      await this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/roles`).then(({ data }) => {
        this.tiers.push(...data.data);
      });
    },
    async getUserPackages(userId) {
      console.log("get tiers");
      await this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/admin/users/${userId}/packages`)
        .then(({ data }) => {
          this.userPackages = data.data;
        });
    },
    getUserDetails() {
      let id = router.currentRoute.params.id;
      console.log(id);
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/admin/users/getUser/${id}`)
        .then(({ data }) => {
          this.userData = data.data;
          this.getUserPackages(id);
        })
        .finally(() => {});
    },
  },
  mounted() {
    this.getUserDetails();
    this.getTiers();
    // this.getUserPackages();
  },
};
</script>

<style></style>
