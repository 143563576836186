<template>
  <div>
    <b-card>
      <div>
        <b-button
          @click="() => addExchange()"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mb-2"
        >
          Add Exchange</b-button
        >
        <b-table striped :items="exchangeList" :fields="fields" :busy="isBusy">
          <template #cell(is_active)="is_active">
            <button
              class="btn btn-success"
              @click="() => toggleActivation(is_active.item)"
              v-if="is_active.item.is_active"
            >
              Active
            </button>

            <button class="btn btn-danger" @click="() => toggleActivation(is_active.item)" v-else>Inactive</button>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }"> -->
              <b-dropdown-item @click="() => showExchangeDetails(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item @click="() => showDeleteWxchangeModal(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <!-- <template #cell(index)="data">{{ data.index + 1 }}</template> -->
        </b-table>

        <b-modal v-model="showAddExchange" size="xl" title="Exchange" hide-footer centered scrollable>
          <add-exchange-modal
            @cancel="
              {
                getExchangeList();
                showAddExchange = false;
              }
            "
            :exchangeProp="selectedExchange"
          />
        </b-modal>

        <b-modal
          id="delete-exchange"
          v-model="showDeleteExchange"
          size="lg"
          title="Delete Exchange"
          hide-footer
          centered
          scrollable
        >
          <div class="d-flex flex-column align-items-center">
            <span>
              Are you sure you want to delete
              <span style="font-size: 20px">{{ exchangeGoingToDelete.name_fa }}</span>
              ?
            </span>

            <div class="d-flex justify-content-between">
              <button @click="() => deleteExchange(exchangeGoingToDelete)" class="btn btn-success mr-4 ml-4">
                YES
              </button>
              <button @click="$bvModal.hide('delete-exchange')" class="btn btn-warning mr-4 ml-4">NO</button>
            </div>
          </div>
        </b-modal>
      </div>
    </b-card>
  </div>
</template>
<script>
import AddExchangeModal from "./AddExchangeModal.vue";
export default {
  name: "exchanges",
  components: { AddExchangeModal },
  data() {
    return {
      selectedExchange: {},
      switchGroup: "",
      exchangeGoingToDelete: "",
      isBusy: false,
      showAddExchange: false,
      showDeleteExchange: false,
      page: 1,
      exchangePerPage: 12,
      idFetchingData: false,
      exchangeList: [],
      fields: [
        {
          key: "name_fa",
          label: "Name",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
        {
          key: "is_active",
          label: "change Status",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
        {
          key: "website",
          label: "website",
        },
        {
          key: "actions",
          label: "actions",
        },
      ],
    };
  },
  methods: {
    showDeleteWxchangeModal(data) {
      this.showDeleteExchange = true;
      this.exchangeGoingToDelete = data;
    },
    showExchangeDetails(data) {
      this.showAddExchange = true;
      this.selectedExchange = data;
    },
    deleteExchange(data) {
      console.log(this.exchangeGoingToDelete);
      this.isBusy = true;

      this.$http
        .delete(`${this.$store.state.appConfig.base_url}/api/v1/exchanges/deleteExchange/${data.id}`)
        .then((res) => {
          console.log(res);
          this.isBusy = false;

          this.getExchangeList();
          this.showDeleteExchange = false;
        });
    },
    addExchange() {
      this.selectedExchange = {};
      this.showAddExchange = true;
    },
    toggleActivation(exchange) {
      this.isBusy = !this.isBusy;
      let act = exchange.is_active === 1 ? false : true;
      console.log(exchange.is_active === 1);

      const payload = JSON.parse(JSON.stringify({ is_active: act }));

      console.log(exchange);
      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/exchanges/changeActivation/${exchange.id}`, payload)
        .then((res) => {
          console.log(res);
          this.getExchangeList();
        });
    },
    getExchangeList() {
      const params = {
        page: this.page,
        limit: this.exchangePerPage,
      };
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/exchanges`, {
          params,
        })
        .then((res) => {
          this.exchangeList = res.data.data;
          console.log(res);
          this.isBusy = false;
        });
    },
  },
  mounted() {
    this.getExchangeList();
  },
};
</script>
