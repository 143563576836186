<template>
  <div>
    <div>
      <div v-if="isLoading">
        <b-card>
          <loading />
        </b-card>
      </div>
      <div v-else>
        <b-card>
          <b-row>
            <b-col md="4">
              <div>
                <label>Referrer Name </label>
                <input v-model="searchObj.referrer_name" type="text" class="form-control" placeholder="Username" />
              </div>
            </b-col>

            <b-col md="4">
              <div class="d-flex flex-row m-1 align-items-center justify-content-between">
                <label>Last Payment </label>
                <input
                  v-model="searchObj.last_payment"
                  type="checkbox"
                  class="checkbox-input"
                  placeholder="last_payment"
                />
              </div>
            </b-col>

            <b-col md="4">
              <div>
                <label>Expire Tier From Date </label>
                <input
                  v-model="searchObj.expire_tier_from_date"
                  type="date"
                  class="form-control"
                  placeholder="Username"
                />
              </div>
            </b-col>

            <b-col md="4">
              <div>
                <label>User Name </label>
                <input v-model="searchObj.user_name" type="text" class="form-control" placeholder="Username" />
              </div>
            </b-col>

            <b-col md="4">
              <div class="d-flex flex-row m-1 align-items-center justify-content-between">
                <label>Expire Tier </label>
                <input
                  v-model="searchObj.expire_tier"
                  type="checkbox"
                  class="checkbox-input"
                  placeholder="expire_tier"
                />
              </div>
            </b-col>

            <b-col md="4">
              <div>
                <label>Expire Tier From Date </label>
                <input
                  v-model="searchObj.expire_tier_from_date"
                  type="date"
                  class="form-control"
                  placeholder="Username"
                />
              </div>
            </b-col>
            <b-col md="4">
              <div>
                <label>Get Point User Id </label>
                <input v-model="searchObj.get_point_user_id" type="text" class="form-control" placeholder="Username" />
              </div>
            </b-col>

            <b-col md="4">
              <div class="d-flex flex-row m-1 align-items-center justify-content-between">
                <label>Assign Point From Ramzarz </label>
                <input
                  v-model="searchObj.assign_point_from_ramzarz"
                  type="checkbox"
                  class="checkbox-input"
                  placeholder="expire_tier"
                />
              </div>
            </b-col>

            <b-col md="4">
              <div>
                <label>Get Point From Date </label>
                <input
                  v-model="searchObj.get_point_from_date"
                  type="date"
                  class="form-control"
                  placeholder="Username"
                />
              </div>
            </b-col>

            <b-col md="4">
              <div>
                <label>Referral Code </label>
                <input v-model="searchObj.referral_code" type="text" class="form-control" placeholder="Username" />
              </div>
            </b-col>

            <b-col md="4">
              <div class="d-flex flex-row m-1 align-items-center justify-content-between">
                <label>Get Point </label>
                <input v-model="searchObj.get_point" type="checkbox" class="checkbox-input" placeholder="expire_tier" />
              </div>
            </b-col>

            <b-col md="4">
              <div>
                <label>get_point_to_date </label>
                <input v-model="searchObj.get_point_to_date" type="date" class="form-control" placeholder="Username" />
              </div>
            </b-col>

            <b-col md="4">
              <div class="d-flex flex-row m-1 align-items-center justify-content-between">
                <label>Used Point </label>
                <input
                  v-model="searchObj.used_point"
                  type="checkbox"
                  class="checkbox-input"
                  placeholder="expire_tier"
                />
              </div>
            </b-col>

            <b-col md="4">
              <div class="d-flex flex-row m-1 align-items-center justify-content-between">
                <label>Get Users Profit </label>
                <input
                  v-model="searchObj.get_users_profit"
                  type="checkbox"
                  class="checkbox-input"
                  placeholder="expire_tier"
                />
              </div>
            </b-col>

            <b-col md="4">
              <div class="d-flex flex-row m-1 align-items-center justify-content-between">
                <label>Is Referral Users </label>
                <input
                  v-model="searchObj.is_referral_users"
                  type="checkbox"
                  class="checkbox-input"
                  placeholder="expire_tier"
                />
              </div>
            </b-col>

            <b-col md="4">
              <div class="ml-2">
                <label>Search</label>
                <div class="d-flex">
                  <button @click="getLogs" class="btn btn-outline-primary">Search</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-table
            responsive
            @sort-changed="sortingChanged"
            id="referral-logs-table"
            striped
            :items="logs"
            :fields="fields"
          >
            <template #cell(index)="data">
              {{ getItemIndex(data.index + 1) }}
              <!-- {{ logsPerPage * currentPage * data.index + 1 }} -->
            </template>
          </b-table>
        </b-card>

        <b-row class="m-2">
          <b-col cols="3" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="logsPerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="4">
            <b-pagination v-model="currentPage" hide-goto-end-buttons :total-rows="logsCount" />
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal v-model="showAddNewReferralModal" size="lg" hide-footer title="User Details" centered scrollable>
      <div>
        <div>
          <add-referral-modal @cancel="showAddNewUserModal = false" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import loading from "./../../components/loading";

export default {
  components: { loading },
  data() {
    return {
      searchObj: {
        page: 1,
        user_name: "",
      },
      showAddNewReferralModal: false,
      fields: [
        "index",
        // A column that needs custom formatting
        { key: "user_id", sortable: true },
        { key: "username", sortable: true },
        { key: "referrer_name", sortable: true },
        // A regular column
        { key: "description", sortable: true },
        // A regular column
        // "details",
        "actions",
      ],
      logs: [],
    };
  },
  methods: {
    createNewReferral() {
      this.showAddNewReferralModal = true;
    },

    getItemIndex(index) {
      // logsPerPage: 10,
      // logsCount: 0,
      // currentPage: 1,
      if (this.currentPage == 1) {
        return index;
      } else {
        return this.logsPerPage * (this.currentPage - 1) + index;
      }
    },
    getLogs() {
      this.loading = true;
      const params = this.searchObj;
      return this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/users/referrals/admin/logs/report`, { params })
        .then(({ data }) => {
          this.logs = data.data;
          console.log("logslogslogs", this.logs);
          this.isLoading = false;

          return this.logs;
        })
        .finally(() => {
          this.isLoading = false;

          this.isFetching = false;
        });
    },
  },
  mounted() {
    this.getLogs();
  },
};
</script>

<style>
.checkbox-input {
  width: 20px;
  height: 20px;
}
</style>
