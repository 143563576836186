<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mt-2 mb-2"
      @click="() => goToCreateService()"
    >
      Add Service</b-button
    >
    <b-table striped :items="exchangeList" :fields="fields" :busy="isBusy">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(price)="price">
        {{ calculatePrice(price.item) }}
      </template>

      <template #cell(type)="type">
        <b-badge :variant="typesVariant(type.item.type)">
          {{ type.item.type }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>
          <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }"> -->
          <b-dropdown-item @click="() => goToServiceDetail(data.item.id)">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details/Update</span>
          </b-dropdown-item>
          <b-dropdown-item @click="() => showDeleteModal(data.item)">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <!-- <template #cell(index)="data">{{ data.index + 1 }}</template> -->
    </b-table>

    <b-modal
      id="delete-exchange"
      v-model="showDeleteService"
      size="lg"
      title="Delete Service"
      hide-footer
      centered
      scrollable
    >
      <div class="d-flex flex-column align-items-center">
        <span>
          Are you sure you want to delete
          <span style="font-size: 20px">{{ selectedService.name }}</span>
          ?
        </span>

        <div class="d-flex justify-content-between">
          <button class="btn btn-success mr-4 ml-4" @click="() => deleteService(selectedService.id)">YES</button>
          <button class="btn btn-warning mr-4 ml-4" @click="$bvModal.hide('delete-exchange')">NO</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exchangeList: [],
      fields: ["name", "type", "price", "actions"],
      isBusy: false,
      showDeleteService: false,
      selectedService: {},
    };
  },
  computed: {
    typesVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        SpotTradeService: "light-primary",
        StopLossTradeService: "light-success",
        SBBalanceTradeService: "light-danger",
        SBPercentTradeService: "light-warning",
        PingpongTradeService: "light-info",
        TradingviewTradeService: "primary",
        AiSignalService: "success",
        IpService: "danger",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  mounted() {
    this.getServicesList();
  },
  methods: {
    goToCreateService() {
      this.$router.push({ name: "packages-service-create" });
    },
    calculatePrice(item) {
      switch (item.type) {
        case "SpotTradeService":
        case "StopLossTradeService":
        case "SBBalanceTradeService":
        case "SBPercentTradeService":
        case "PingpongTradeService":
        case "TradingviewTradeService":
          return `${item.price24} (24H)`;

        case "AiSignalService":
        case "LandcruiserSignalService":
        case "OptimumSignalService":
        case "AlarmService":
          return `${item.totalPrice} (Total) / ${item.eachSuccessPrice} (Each Success)`;

        default:
          return `${item.price24} (24H)`;
      }
    },
    showDeleteModal(service) {
      this.selectedService = service;
      this.showDeleteService = true;
    },
    goToServiceDetail(id) {
      this.$router.push({ name: "packages-service-create", query: { id } });
    },
    getServicesList() {
      // this.isBusy = true;
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageServices`).then((res) => {
        this.exchangeList = res.data.data;
        this.isBusy = false;
      });
    },
    deleteService(id) {
      this.isBusy = true;
      this.$http
        .delete(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageServices/delete/${id}`)
        .then((res) => {
          this.getServicesList();
          this.$bvToast.toast("Deleted", {
            title: "Service Deleted",
            variant: "success",
            solid: true,
          });
          this.isBusy = false;
          this.showDeleteService = false;
        });
    },
  },
};
</script>

<style></style>
