import Vue from "vue";
import router from "./../router";
import { ToastPlugin } from "bootstrap-vue";

import "./toastification";

// axios
import axios from "axios";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let current = router.history.current.path;

    if (error.response.status === 401) {
      localStorage.setItem("accessToken", "");
      if (current != "/login") {
        router.push("/login");
      }
    }

    if (error.response.status > 299 || error.response.status < 200) {
      router.app.$bvToast.toast(`${error.response.data.message}`, {
        title: `Error`,
        variant: "danger",
        solid: true,
      });
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
