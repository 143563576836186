<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>Range</label>
            <form class="d-flex" @submit.prevent="clearRange">
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                @on-close="doSomethingOnClose"
                :config="{
                  Local: 'uk',
                  mode: 'range',
                  dateFormat: 'Y-m-d',
                }"
              />
              <button class="btn btn-outline-primary ml-2">
                <feather-icon icon="XCircleIcon" size="14" />
              </button>
            </form>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Admin Role </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchObj.admin"
            :options="tierOptions"
            class="w-100"
            :reduce="(val) => val"
            label="name"
            key="id"
          />
        </b-col>

        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Packages </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchObj.package"
            :options="packages"
            class="w-100"
            :reduce="(val) => val"
            label="name"
            key="name"
          />
        </b-col>

        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Exchanges </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchObj.ex"
            :options="exchanges"
            class="w-100"
            :reduce="(val) => val"
            label="name"
            key="name"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Number of exchanges </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchObj.numberOfExchange"
            :options="exchanges_count"
            class="w-100"
            :reduce="(val) => val"
            label="name"
            key="name"
          />
        </b-col>

        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Suspended </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchObj.suspend"
            :options="suspendList"
            class="w-100"
            :reduce="(val) => val"
            label="key"
          />
        </b-col>

        <b-col md="4">
          <div>
            <label>Min Number of referrals </label>
            <div class="d-flex">
              <input v-model="numberOfReferrals" type="number" class="form-control" />
              <button @click="() => searchNumberOfReferrals()" class="btn btn-outline-primary ml-2">
                <feather-icon icon="SearchIcon" size="14" />
              </button>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Has Refferel </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchObj.hasReferral"
            :options="Refferel_options"
            class="w-100"
            :reduce="(val) => val"
            label="key"
          />
        </b-col>

        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Server </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchObj.server"
            :options="servers"
            class="w-100"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <div>
            <label>Search </label>
            <form @submit.prevent="searchText" class="d-flex">
              <input v-model="textToSearch" type="text" class="form-control" placeholder="Name, Username, email" />
              <button class="btn btn-outline-primary ml-2">
                <feather-icon icon="SearchIcon" size="14" />
              </button>
            </form>
          </div>
        </b-col>

        <b-col md="4">
          <div class="ml-2">
            <label>No Filter</label>
            <form @submit.prevent="showAllUsers" class="d-flex">
              <button class="btn btn-outline-primary">No Filter</button>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { getExchangesEnumAsList } from "./../exchange-enum";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      exchanges: [],
      packages: [],
      servers: [],
      exchanges_count: [],
      Refferel_options: [],
      rangeDate: null,
      suspendList: [
        {
          key: "All",
          value: undefined,
        },
        {
          key: "Yes",
          value: true,
        },
        {
          key: "No",
          value: false,
        },
      ],
      textToSearch: "",
      numberOfReferrals: "",
      searchObj: {
        admin: "",
        suspend: false,
        hasReferral: undefined,
        searchText: "",
        fromDate: "",
        toDate: "",
        ex: "",
        package: "",
        server: "",
        numberOfExchange: 0,
        numOfReff: 0,
      },
    };
  },
  methods: {
    getServers() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/servers`).then((res) => {
        this.servers = res.data.data.map((s) => s.ip);
        console.log("servers", res.data.data);
        // this.isBusy = false;
      });
    },
    generatePackageName(data) {
      console.log(data.name);
      return { Lable: "teset" };
    },
    getPackages() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packages`).then(({ data }) => {
        this.packages = data.data;
      });
    },
    getExchanges() {
      this.exchanges = getExchangesEnumAsList();
      for (let index = 0; index < this.exchanges.length; index++) {
        this.exchanges_count.push(index);
      }
    },
    doSomethingOnClose(data) {
      var from = new Date(
        data[0].getUTCFullYear(),
        data[0].getUTCMonth(),
        data[0].getDate(),
        data[0].getUTCHours(),
        data[0].getUTCMinutes(),
        data[0].getUTCSeconds()
      );

      data[1].setDate(data[1].getDate() + 1);

      var to = new Date(data[1]);

      this.searchObj.fromDate = from;
      this.searchObj.toDate = to;
    },
    clearRange() {
      this.rangeDate = null;
      this.searchObj.fromDate = "";
      this.searchObj.toDate = "";
    },
    showAllUsers() {
      this.searchObj.searchText = "";
      this.searchObj.fromDate = "";
      this.searchObj.toDate = "";
      this.searchObj.textToSearch = "";
      this.searchObj.ex = "";
      this.searchObj.numberOfExchange = 0;
      this.searchObj.numOfReff = 0;
      this.searchObj.hasReferral = undefined;

      this.textToSearch = "";
      this.fromDate = "";
      this.toDate = "";
      this.ex = "";
      this.numberOfExchange = 0;
      this.numberOfReferrals = 0;
    },
    searchText() {
      this.searchObj.searchText = this.textToSearch;
      // this.$emit("search", this.searchObj);
    },
    searchNumberOfReferrals() {
      this.searchObj.numOfReff = this.numberOfReferrals;
    },
  },
  props: {
    tierOptions: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.Refferel_options.push("has");
    this.Refferel_options.push("has not");
    this.getExchanges();
    this.getPackages();
    this.getServers();

    for (let index = 0; index < this.tierOptions.length; index++) {
      let element = this.tierOptions[index];
      element.id = index;
    }
  },
  watch: {
    searchObj: {
      handler(newVal) {
        this.$emit("search", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
