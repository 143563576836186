export default [
  {
    path: "/token-managements/exchange",
    name: "exchange",
    meta: {
      action: "read",
      resource: "exchange",
    },
    component: () => import("@/views/pages/exchange"),
  },
  // {
  //   path: "/token-managements/exchange/edit",
  //   name: "exchange-edit",
  //   meta: {
  //     action: "edit",
  //     resource: "exchange",
  //   },
  //   component: () => import("@/views/exchange/Edit.vue"),
  // },
];
