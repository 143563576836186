export default [
  {
    path: "/servers",
    name: "servers",
    meta: {
      action: "read",
      resource: "servers",
    },
    component: () => import("@/views/pages/servers"),
  },
];
