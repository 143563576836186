export default [
  {
    title: "Users",
    icon: "UsersIcon",
    tagVariant: "light-warning",
    children: [
      {
        title: "List",
        route: "users-list",
        resource: "user",
        action: "read",
      },
      {
        title: "Admin Roles",
        route: "admin-roles",
        resource: "adminrole",
        action: "read",
      },
    ],
  },
];
