export default [
  {
    path: "/referrals",
    name: "referral",
    component: () => import("@/views/pages/referrals/index.vue"),
    meta: {
      resource: "referral",
      action: "read",
    },
  },
  {
    path: "/referral-logs",
    name: "referral-logs",
    component: () => import("@/views/pages/referrals/logs.vue"),
    meta: {
      resource: "referral",
      action: "read",
    },
  },

  {
    path: "/create-referrals/:id/:username/:email/:full_name",
    name: "create-referrals",
    component: () => import("@/views/pages/referrals/create.vue"),
    meta: {
      resource: "referral",
      action: "create",
    },
  },
];
