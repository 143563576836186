export default [
  {
    path: "/users/list",
    name: "users-list",
    meta: {
      action: "read",
      resource: "user",
    },
    component: () => import("@/views/pages/users/List.vue"),
  },
  {
    path: "/users/admin-roles",
    name: "admin-roles",
    meta: {
      action: "read",
      resource: "adminrole",
    },
    component: () => import("@/views/pages/users/AdminsTiers.vue"),
  },
  {
    path: "/users/profile/:id",
    name: "user-profile",
    meta: {
      action: "read",
      resource: "user",
    },
    component: () => import("@/views/pages/users/profile"),
  },
];
