<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>Range</label>
            <form class="d-flex" @submit.prevent="clearRange">
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                @on-close="doSomethingOnClose"
                :config="{
                  Local: 'uk',
                  mode: 'range',
                  dateFormat: 'Y-m-d',
                }"
              />
              <button class="btn btn-outline-primary ml-2">
                <feather-icon icon="XCircleIcon" size="14" />
              </button>
            </form>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Product Category </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchObj.admin"
            :options="tierOptions"
            class="w-100"
            :reduce="(val) => val"
            label="name"
            key="id"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <div>
            <label>Search </label>
            <form @submit.prevent="searchText" class="d-flex">
              <input v-model="textToSearch" type="text" class="form-control" placeholder="Name, Username, email" />
              <button class="btn btn-outline-primary ml-2">
                <feather-icon icon="SearchIcon" size="14" />
              </button>
            </form>
          </div>
        </b-col>

        <b-col md="4">
          <div class="ml-2">
            <label>No Filter</label>
            <form @submit.prevent="showAllUsers" class="d-flex">
              <button class="btn btn-outline-primary">No Filter</button>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      exchanges: [],
      packages: [],
      exchanges_count: [],
      Refferel_options: [],
      rangeDate: null,
      suspendList: [
        {
          key: "All",
          value: undefined,
        },
        {
          key: "Yes",
          value: true,
        },
        {
          key: "No",
          value: false,
        },
      ],
      textToSearch: "",
      numberOfReferrals: "",
      searchObj: {
        admin: "",
        suspend: false,
        hasReferral: undefined,
        searchText: "",
        fromDate: "",
        toDate: "",
        ex: "",
        package: "",
        numberOfExchange: 0,
        numOfReff: 0,
      },
    };
  },
  methods: {
    generatePackageName(data) {
      console.log(data.name);
      return { Lable: "teset" };
    },
    getPackages() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packages`).then(({ data }) => {
        this.packages = data.data;
      });
    },
    getExchanges() {},
    doSomethingOnClose(data) {},
    clearRange() {},
    showAllUsers() {},
    searchText() {},
    searchNumberOfReferrals() {},
  },
  props: {
    tierOptions: {
      type: Array,
      required: true,
    },
  },
  created() {},
  watch: {
    searchObj: {
      handler(newVal) {
        this.$emit("search", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
