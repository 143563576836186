<template>
  <b-card no-body class="border-primary">
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">Admin Role</h5>
      <b-badge variant="light-primary"> {{ userData.user_tier }} </b-badge>
      <small class="text-muted w-100">{{ userData.register_date }}</small>
    </b-card-header>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
};
</script>

<style></style>
