export default [
  {
    title: "Token Managements",
    icon: "HomeIcon",
    route: "token-managements-index",
    tagVariant: "light-warning",
    resource: "tokenmanagements",
    action: "manage",
    // children: [
    //     {
    //         title: 'Token Managements',
    //         route: 'token-managements-index',
    //     },
    //     {
    //         title: 'Value of RMZr',
    //         route: 'token-managements-rmzr',
    //     },
    //     {
    //         title: 'Withdrawl Requests',
    //         route: 'token-managements-withdrawl-requests',
    //     },
    //     {
    //         title: 'Withdrawls',
    //         route: 'token-managements-withdrawls',
    //     },
    //     {
    //         title: 'Token Distribution',
    //         route: 'token-managements-token-distribution',
    //     },
    //     {
    //         title: 'Transaction Fees',
    //         route: 'token-managements-transaction-fees',
    //     },

    //     {
    //         title: 'SRRM',
    //         route: 'token-managements-srrm',
    //     },

    // ],
  },
];
