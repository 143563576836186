<template>
  <div>
    <div v-if="isLoading">
      <loading />
    </div>
    <div v-else>
      <b-form>
        <b-row>
          <b-col class="col-12">
            <b-form-group label="Select Coin" label-for="select-coin-id" label-cols-md="2">
              <b-row>
                <b-dropdown variant="primary" id="select-coin-id" :text="values.coin.toUpperCase()" class="mr-4 ml-4">
                  <b-dropdown-item v-for="unit in units" :key="unit" @click="() => changeTokenUnit(unit)">{{
                    unit
                  }}</b-dropdown-item>
                </b-dropdown>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col class="col-12">
            <b-form-group label="Estimated Rewards Percent" label-for="estimate-id" label-cols-md="2">
              <b-row>
                <b-form-input
                  v-model="values.estimated_rewards_percent"
                  type="number"
                  placeholder="Usd Value"
                  class="mr-4 ml-4 col-4"
                  id="estimate-id"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col class="col-12">
            <b-form-group label="Min To Stake" label-for="min-to-stake-id" label-cols-md="2">
              <b-row>
                <b-form-input
                  v-model="values.min_to_stake"
                  type="number"
                  id="min-to-stake-id"
                  placeholder="Usd Value"
                  class="mr-4 ml-4 col-4"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col class="col-12">
            <b-form-group label="Reward Payout Interval" label-for="reward-id" label-cols-md="2">
              <b-row>
                <b-form-input
                  v-model="values.reward_payout_interval"
                  type="number"
                  id="reward-id"
                  placeholder="Usd Value"
                  class="mr-4 ml-4 col-4"
                ></b-form-input>
                <span style="white-space: nowrap">Days</span>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="btn btn-success pr-3 pl-3" @click="() => updateSRRM()">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import loading from "./../../../components/loading";

export default {
  components: { loading },
  data() {
    return {
      isLoading: false,
      values: {
        coin: "BTC",
        estimated_rewards_percent: 0,
        min_to_stake: 0,
        reward_payout_interval: 0,
        id: 0,
      },
      units: [
        "BTC",
        "USDT",
        "ETH",
        "XHT",
        "XRP",
        "BCH",
        "LINK",
        "XLM",
        "XMR",
        "ADA",
        "DAI",
        "UNI",
        "BNB",
        "LTC",
        "DOGE",
        "DOT",
      ],
    };
  },
  methods: {
    changeTokenUnit(value) {
      this.values.coin = value;
      this.getSRRM();
    },
    getSRRM() {
      this.isLoading = true;
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/getSRRM/${this.values.coin}`)
        .then((res) => {
          this.isLoading = false;
          if (res.data.data) {
            this.values = res.data.data;
          } else {
            this.values.estimated_rewards_percent = 0;
            this.values.min_to_stake = 0;
            this.values.reward_payout_interval = 0;
          }
        })
        .catch((err) => {
          // console.log(err);
          this.isLoading = false;

          this.values.estimated_rewards_percent = 0;
          this.values.min_to_stake = 0;
          this.values.reward_payout_interval = 0;
        });
    },

    updateSRRM() {
      this.isLoading = true;

      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/updateSRRM`, this.values)
        .then((res) => {
          this.isLoading = false;

          this.getSRRM();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "InfoIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.isLoading = false;

          console.log(err);
        });
    },
  },
  mounted() {
    this.getSRRM();
  },
};
</script>

<style></style>
