export default [
  {
    path: "/packages/list",
    name: "packages",
    meta: {
      action: "read",
      resource: "packages",
    },
    component: () => import("@/views/pages/packages"),
  },
  {
    path: "/packages/category",
    name: "packages-category",
    meta: {
      action: "read",
      resource: "packages",
    },
    component: () => import("@/views/pages/packages/PackageCategory.vue"),
  },
  {
    path: "/packages/service",
    name: "packages-service",
    meta: {
      action: "read",
      resource: "packages",
    },
    component: () => import("@/views/pages/packages/services"),
  },
  {
    path: "/packages/service/create",
    name: "packages-service-create",
    meta: {
      action: "create",
      resource: "packages",
    },
    component: () => import("@/views/pages/packages/services/create.vue"),
  },
  {
    path: "/packages/service/edit",
    name: "packages-service-edit",
    meta: {
      action: "edit",
      resource: "packages",
    },
    component: () => import("@/views/pages/packages/services/edit.vue"),
  },
  {
    path: "/packages/features",
    name: "features",
    meta: {
      action: "read",
      resource: "packagefeatures",
    },
    component: () => import("@/views/pages/packages/features"),
  },
  {
    path: "/packages/botservices",
    name: "packages-bot-ervices",
    meta: {
      action: "read",
      resource: "packagebotservices",
    },
    component: () => import("@/views/pages/packages/BotServices"),
  },
];
