<template>
  <div style="height: 100%">
    <lottie-animation :width="256" :height="256" path="img/lotties/loading.json" />
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  components: { LottieAnimation },
  data() {
    return {};
  },
};
</script>

<style></style>
