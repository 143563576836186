<template>
  <div class="content-box">
    <div>
      <b-card>
        <div class="d-flex flex-nowrap overflow-auto mx-0 py-3 px-2 bg-light border rounded">
          <button
            v-for="tier in tiersTitles"
            :key="tier"
            @click="changeCurrentTier(tier)"
            :class="currentTier && tier === currentTier.name ? 'btn-info' : 'btn-outline-info'"
            class="btn w-100 tier-button"
          >
            {{ tier }}
          </button>
        </div>
      </b-card>
    </div>
    <b-button @click="() => createNewTier()" variant="primary" class="mt-2 mb-2"> Add Role </b-button>

    <br />
    <div class="text-center">
      <b-spinner v-if="isFetching" />
    </div>
    <b-card>
      <div v-if="currentTier">
        <div class="row">
          <div class="col-12 col-md-6">
            <label class="text-bold">Role Name</label>
            <b-form-input v-model="currentTier.name"></b-form-input>
          </div>
          <div class="col-12 col-md-6">
            <label class="text-bold">Role Description</label>
            <b-form-input v-model="currentTier.description"></b-form-input>
          </div>
        </div>
        <br />
        <br />
        <b-col cols="12" lg="12">
          <user-view-user-permissions-card
            v-if="emptyTier.admin_abilities.length != 0"
            :defaultPermissions="emptyTier.admin_abilities"
            :currentTier="currentTier"
            @changePermissions="changePermissions"
          />
        </b-col>
      </div>

      <button class="btn btn-outline-danger" @click="showDeleteTier">Delete</button>

      <button v-if="currentTier.id == 0" @click="createTier()" class="btn btn-info">Add Role</button>

      <button v-else @click="updateTier(currentTier.id)" class="btn btn-info mr-1 ml-1">Update Role</button>
    </b-card>

    <b-modal v-model="showDeleteTierModal" size="lg" hide-footer title="Delete Role" centered scrollable>
      <div>
        <div>
          <delete-tier-modal
            @cancel="showDeleteTierModal = false"
            :tiers="tierDetails"
            :currentTier="currentTier"
            :isAdmin="true"
            @updated="() => getTiers()"
          />
        </div>
      </div>
    </b-modal>
    <!-- {{ defaultAbilities }}
    <button v-if="currentTier.id == 0" @click="createTier()">Add Tier</button>
    <button v-else @click="updateTier(currentTier.id)">Update Tier</button> -->
  </div>
</template>

<script>
import ToggleButton from "./components/ToggleButton.vue";
import validate from "@/helpers/validate";
import UserViewUserPermissionsCard from "./components/UserViewUserPermissionsCard.vue";
import DeleteTierModal from "./components/DeleteTierModal.vue";

export default {
  name: "Tiers",
  components: { ToggleButton, UserViewUserPermissionsCard, DeleteTierModal },
  data() {
    return {
      currentTier: null,
      showDeleteTierModal: false,
      isNewTier: false,
      tiersTitles: [],
      tierDetails: [],
      defaultAbilities: [],
      emptyTier: {
        id: 0,
        name: "",
        description: "",
        admin_abilities: [],
      },
      isSaving: false,
      isFetching: false,
      errors: {},
    };
  },
  methods: {
    changePermissions(data) {
      console.log(data);
      this.currentTier.admin_abilities = data;
    },
    changeCurrentTier(tier) {
      this.isNewTier = false;
      this.currentTier = this.tierDetails.find((t) => t.name === tier);
    },
    showDeleteTier() {
      this.showDeleteTierModal = true;
    },
    getTiers() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/roles`).then(({ data }) => {
        this.tierDetails = data.data;
        this.tiersTitles = data.data?.map((t) => t.name);
        if (this.tiersTitles.length) this.changeCurrentTier(this.tiersTitles[0]);
      });
    },
    getTiersAbilities() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/roles/abilities`).then(({ data }) => {
        this.emptyTier.admin_abilities = data.data;
      });
    },
    createTier() {
      this.errors = {};
      let validationResult = validate("tier", this.currentTier);
      if (validationResult) return (this.errors = validationResult);

      const payload = JSON.parse(JSON.stringify(this.currentTier));
      this.isSaving = true;
      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/admin/roles`, payload)
        .then(({ data }) => {
          // this.tierDetails.push(data.data);
          // this.tiersTitles.push(data.data.name);
          // this.changeCurrentTier(data.data.name);
          this.$bvToast.toast(`"${data.data.name}" was created successfully`, {
            title: `Tier Created`,
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.error("Error creating tier", err);
          this.$bvToast.toast(`An error occured`, {
            title: `Tier Create Error`,
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    updateTier() {
      this.errors = {};
      // let validationResult = validate("tier", this.currentTier);
      // if (validationResult) return (this.errors = validationResult);
      const payload = JSON.parse(JSON.stringify(this.currentTier));
      this.isSaving = true;
      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/admin/roles/${this.currentTier.id}`, payload)
        .then(({ data }) => {
          this.getTiers();
          this.$bvToast.toast(`"${data.data.name}" was updated successfully`, {
            title: `Tier Updated`,
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.error("Error saving tier", err);
          this.$bvToast.toast(`An error occured`, {
            title: `Tier Update Error`,
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    createNewTier() {
      this.currentTier = this.emptyTier;
      this.isNewTier = true;
    },
    deleteTier() {
      this.$bvModal
        .msgBoxConfirm(`Do you really want to delete "${this.currentTier.name}"?`, {
          title: `Delete Tier`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete Tier",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (!value) return;
          this.$http
            .delete(`${this.$store.state.appConfig.base_url}/api/v1/admin/roles/${this.currentTier.id}`)
            .then(({ data }) => {
              this.currentTier = null;
              this.getTiers();
              this.$bvToast.toast(`${this.currentTier.name} was deleted successfully`, {
                title: `Delete Tier`,
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              console.error("Error deleting tier", err);
              this.$bvToast.toast(`Error deleting ${this.currentTier.name}`, {
                title: `Delete Tier Error`,
                variant: "danger",
                solid: true,
              });
            })
            .finally(() => {});
        });
    },
  },
  mounted() {
    this.getTiers();
    this.getTiersAbilities();
  },
};
</script>

<style scoped>
.tier-button {
  flex: 0 0 150px;
  margin: 0 5px;
}
input[type="checkbox"] {
  transform: scale(1.5);
}
.tier-option {
  border: 1px solid #ddd;
  padding: 5px 10px;
  cursor: pointer;
}
.tier-section {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px 5px 5px;
}

.tier-section-label {
  position: absolute;
  top: 0;
  font-weight: bold;
  transform: translateY(-50%);
  padding: 0 5px;
  margin-left: 10px;
  font-size: 110%;
  background: white;
}
.tier-item-title {
  min-width: 150px;
}
</style>
