<template>
  <div>
    <div>
      <b-card>
        <b-table striped responsive :items="requests" :fields="fields">
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item @click="() => showUpdatefeatureModal(data.item, false)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Update</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(features)="features">
            {{ getFeaturesName(features.item.features) }}
          </template>
          <template #cell(is_active)="is_active">
            <button
              class="btn btn-success"
              @click="() => toggleFeatureActivation(is_active.item)"
              v-if="is_active.item.is_active"
            >
              Active
            </button>

            <button class="btn btn-danger" @click="() => toggleFeatureActivation(is_active.item)" v-else>
              Inactive
            </button>
          </template>
        </b-table>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-card>
    </div>

    <b-modal
      id="delete-exchange"
      v-model="showUpdatefeature"
      size="md"
      title="Update feature"
      hide-footer
      centered
      scrollable
    >
      {{ selectedFeature.id }}
      <div class="d-flex flex-column align-items-center">
        <div class="input-item m-1">
          <div class="input-label">Name</div>
          <input class="form-control" type="text" v-model="selectedFeature.name" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Price</div>
          <input class="form-control" type="text" v-model="selectedFeature.price" />
        </div>

        <button @click="() => update(selectedFeature)" class="btn btn-primary" type="submit">Update</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BFormCheckbox, BCardText } from "bootstrap-vue";

export default {
  data() {
    return {
      rows: 10,
      deletingfeatureId: 0,
      deleteError: "",
      feature: {
        name: 0,
        feature: 0,
      },

      showAddfeature: false,
      showDeleteConfirm: false,
      showUpdatefeature: false,
      selectedUnit: "btc",

      selectedFeature: {
        price: 0,
        name: "",
      },
      requests: [],

      fields: ["name", "type", "price", "is_active", "actions"],
    };
  },
  methods: {
    getFeatureNameByEnum(item) {
      for (let index = 0; index < this.originalFeatures.length; index++) {
        const element = this.originalFeatures[index];
        if (element.type == item) {
          console.log("originalFeaturesoriginalFeaturesoriginalFeaturesoriginalFeatures", element);
          return element.name;
        }
      }
    },
    getFeaturesName(features) {
      console.log("features", features);
      // return features;
      features = features.map((item) => {
        return this.getFeatureNameByEnum(item);
      });
      return features;
    },
    changeAction(data) {
      if (this.addingNewPackage) {
        if (!data.selected) {
          const index = this.newPackage.features.findIndex((item) => item.type == data.type);
          console.log("0 this.newPackage.features", this.newPackage.features);

          this.newPackage.features = this.newPackage.features.slice(0, index);
          console.log("index", index);
          console.log("this.newPackage.features", this.newPackage.features);
        } else {
          this.newPackage.features.push(data);
        }
      } else {
        this.selectedFeature.features.forEach((item) => {
          if (item.type == data.type) {
            item = data;
          }
        });
      }

      console.log(data);
    },
    ConfirmDeletefeature(id) {
      this.deletefeature(id, true);
    },
    update(feature) {
      // this.feature = feature;
      this.showUpdatefeature = true;
      console.log("feature", feature);

      let activation = false;
      if (feature.is_active) {
        activation = feature.is_active;
      }
      const payload = {
        name: feature.name,
        price: Number(feature.price),
        is_active: activation,
      };
      console.log("packagee payload", payload);

      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/features/update/${feature.id}`, payload)
        .then((result) => {
          this.getRequests();
          this.showUpdatefeature = false;
          // this.feature=
        })
        .catch((err) => {
          this.$bvToast.toast(`${err.response?.data?.message}`, {
            title: `Edit Error`,
            variant: "danger",
            solid: true,
          });
        });
    },

    showAddfeatureModal() {
      this.showAddfeature = true;
      this.addingNewPackage = true;
    },
    showUpdatefeatureModal(seletpackage, confirm) {
      this.addingNewPackage = false;

      this.selectedFeature = JSON.parse(JSON.stringify(seletpackage));

      this.showUpdatefeature = true;
    },
    getRequests() {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/features`)
        .then((result) => {
          this.requests = result.data.data;
        })
        .catch((err) => {});
    },
    getCopyOfFeatures() {
      return JSON.parse(JSON.stringify(this.originalFeatures));
    },
    getFeatures() {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/features`)
        .then((result) => {
          this.originalFeatures = result.data.data.map((item) => {
            item.selected = false;
            return item;
          });
          console.log("this.featuresCategory features", this.originalFeatures);

          let features1 = [];
          let features2 = [];
          for (let index = 0; index < this.originalFeatures.length; index++) {
            const element = this.originalFeatures[index];
            console.log("this.featuresCategory element", element);

            if (index < 10) {
              features1.push(element);
              console.log("this.featuresCategory", this.featuresCategory);
            }
            if (index == 10) {
              features1.push(element);
              this.featuresCategory[0].push(features1);
            }
            if (10 < index < 20) {
              features2.push(element);
            }

            if (index == 20) {
              features2.push(element);
              this.featuresCategory[1].push(features2);
            }

            // if (20 < index < 30) {
            //   this.featuresCategory[2].push(element)
            //   console.log('this.featuresCategory', this.featuresCategory)

            // }
          }
        })
        .catch((err) => {});
    },
    toggleFeatureActivation(exchange) {
      this.isBusy = !this.isBusy;
      let act = exchange.is_active === true ? false : true;
      console.log(exchange.is_active === 1);

      const payload = JSON.parse(JSON.stringify({ is_active: act }));

      console.log(exchange);
      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/features/changeActivation/${exchange.id}`, payload)
        .then((res) => {
          console.log(res);
          this.getRequests();
        });
    },
  },
  mounted() {
    this.getRequests();
    this.getFeatures();
  },
};
</script>

<style></style>
