<template>
  <div>
    <!-- <sale-list-filters :tier-options="tiers" @search="search" /> -->

    <div>
      <div v-if="isLoading">
        <b-card>
          <loading />
        </b-card>
      </div>
      <b-card v-else>
        <b-table responsive @sort-changed="sortingChanged" id="users-table" striped :items="signals" :fields="fields">
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'user-profile', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{
                  name: 'create-referrals',
                  params: {
                    id: data.item.id,
                    username: data.item.username,
                    email: data.item.email,
                    full_name: data.item.name,
                  },
                }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Create Referral</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import loading from "./../../components/loading";

import vSelect from "vue-select";
//   import SaleListFilters from "./components/SaleListFilters";

export default {
  name: "UsersList",
  components: { vSelect, loading },
  data() {
    return {
      fields: [
        { key: "provider", sortable: true },
        { key: "total", sortable: true },
        { key: "buy", sortable: true },
        { key: "sell", sortable: true },
        { key: "stopLoss", sortable: true },
        { key: "won", sortable: true },
        { key: "total_profit", sortable: true },
      ],
      signals: [],
      isLoading: false,
    };
  },

  methods: {
    getPrime() {
      this.isLoading = true;
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/cryptoSignals/report?start_date=0`)
        .then(({ data }) => {
          data.data.provider = "PRIME";
          this.signals.push(data.data);
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getOptimum() {
      this.isLoading = true;
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/crypto-bot-signals/report?start_date=0&provider=optimum`)
        .then(({ data }) => {
          data.data.provider = "optimum";
          this.signals.push(data.data);
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getLandcruiser() {
      this.isLoading = true;
      this.$http
        .get(
          `${this.$store.state.appConfig.base_url}/api/v1/crypto-bot-signals/report?start_date=0&provider=landcruiser`
        )
        .then(({ data }) => {
          data.data.provider = "landcruiser";
          this.signals.push(data.data);
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getPrime();
    this.getOptimum();
    this.getLandcruiser();
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
