<template>
  <div>
    <h3 class="m-1">Contract</h3>
    <div class="input-item m-1">
      <div class="input-label">Percent</div>
      <input class="form-control" required type="number" max="99" min="0" v-model="level.contract.percent" />

      <b-alert v-if="level.contract.percent > 100 || level.contract.percent < 0" class="mb-0" show variant="warning">
        <div class="alert-body">
          <span>The </span>
          <code>Percent</code>
          <span> should be between 0 and 100 </span>
        </div>
      </b-alert>
    </div>

    <div class="d-flex flex-row m-1 mb-2">
      <div class="input-label">Descending Slope</div>
      <input class="form-control" required type="checkbox" v-model="level.contract.is_descending_slope" />
    </div>

    <div v-if="level.contract.is_descending_slope" class="m-1">
      <div class="input-label">Descending Slope Percentage</div>

      <div>
        <div>
          <b-form ref="form" class="" @submit.prevent="repeateAgain">
            <div
              v-for="(item, index) in level.contract.descending_slope_percentage"
              :key="item"
              class="d-flex justify-content-between"
            >
              <div>
                <input
                  class="form-control mt-1"
                  v-model="level.contract.descending_slope_percentage[index]"
                  :disabled="index == 0"
                  placeholder="Enter value"
                />
              </div>
              <div>
                <b-button
                  variant="outline-danger"
                  class="mt-1 ml-1 mr-1"
                  @click="removeItem(index, level.contract.descending_slope_percentage)"
                >
                  <feather-icon icon="XIcon" />
                  <span>Delete</span>
                </b-button>
              </div>
            </div>
          </b-form>
        </div>
        <b-button variant="primary" class="mt-1" @click="repeateAgain(level.contract.descending_slope_percentage)">
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Add New</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: [],
  data() {
    return {};
  },
  props: ["level"],
  methods: {
    repeateAgain(data) {
      console.log(data);
      console.log(data[data.length - 1]);
      if (data[data.length - 1] != 0) {
        console.log(data);
        data.push(0);
      }
    },
    removeItem(index, data) {
      data.splice(index, 1);
    },
  },
};
</script>
