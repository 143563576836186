<template>
  <div>
    <sale-list-filters :tier-options="tiers" @search="search" />

    <div>
      <div v-if="isLoading">
        <b-card>
          <loading />
        </b-card>
      </div>
      <b-card v-else>
        <b-table responsive @sort-changed="sortingChanged" id="users-table" striped :items="users" :fields="fields">
          <template #cell(index)="data">
            {{ getItemIndex(data.index + 1) }}
          </template>
          <template #cell(details)="data">
            <button class="btn btn-sm text-muted" @click="showUserDetails(data.item.id)">
              <feather-icon icon="EyeIcon" size="24" />
            </button>
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'user-profile', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{
                  name: 'create-referrals',
                  params: {
                    id: data.item.id,
                    username: data.item.username,
                    email: data.item.email,
                    full_name: data.item.name,
                  },
                }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Create Referral</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-row class="m-2">
          <b-col cols="3" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="usersPerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="4">
            <b-pagination v-model="currentPage" hide-goto-end-buttons :total-rows="usersCount" />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import loading from "./../../components/loading";

import vSelect from "vue-select";
import SaleListFilters from "./components/SaleListFilters";

export default {
  name: "UsersList",
  components: { vSelect, loading, SaleListFilters },
  data() {
    return {
      fields: [
        "index",
        { key: "name", sortable: true },
        { key: "username", sortable: true },
        { key: "type", sortable: true },
        { key: "amount", sortable: true },
        { key: "date", sortable: true },
        "actions",
      ],
    };
  },

  methods: {},
  mounted() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
