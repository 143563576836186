export default [
  {
    path: "/payment-gateway",
    name: "payment-gateway",
    meta: {
      action: "read",
      resource: "paymentgateway",
    },
    component: () => import("@/views/pages/payment-gateway"),
  },
];
