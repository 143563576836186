<template>
  <b-card>
    <b-row>
      <b-col md="3">
        <div class="input-item m-1">
          <div class="input-label">Name</div>
          <input v-model="service.name" class="form-control" type="text" required />
        </div>
      </b-col>
      <b-col md="3">
        <div class="input-item m-1">
          <div class="input-label">Profile Logo</div>
          <input v-model="service.profileLogo" class="form-control" type="text" />
        </div>
      </b-col>
      <b-col md="3">
        <div class="input-item m-1">
          <div class="input-label">WebPage Link</div>
          <input v-model="service.webPageLink" class="form-control" type="text" />
        </div>
      </b-col>
      <b-col md="3">
        <div class="input-item m-1">
          <div class="input-label">Developer Team</div>
          <input v-model="service.developerTeam" class="form-control" type="text" />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <div class="input-item m-1">
          <div class="input-label">Number Of Users</div>
          <input v-model="service.noOfUsers" class="form-control" type="number" required min="0" />
        </div>
      </b-col>
      <b-col md="3">
        <div class="input-item m-1">
          <div class="input-label">Number Of Exchanges</div>
          <input v-model="service.noOfExchanges" class="form-control" type="number" required min="0" />
        </div>
      </b-col>
      <b-col md="3">
        <div class="input-item m-1">
          <div class="input-label">Number Of Pairs</div>
          <input v-model="service.noOfPairs" class="form-control" type="number" required min="0" />
        </div>
      </b-col>
      <b-col md="3">
        <div class="input-item m-1">
          <div class="input-label">Number Of Open Position</div>
          <input v-model="service.noOfOpenPositions" class="form-control" type="number" required min="0" />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <div class="input-item m-1">
          <div class="input-label">24H Price</div>
          <input v-model="service.price24" class="form-control" type="number" required step="0.0001" min="0" />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    service: {
      name: String,
      profileLogo: String,
      webPageLink: String,
      developerTeam: String,
      noOfUsers: Number,
      noOfExchanges: Number,
      noOfPairs: Number,
      noOfOpenPositions: Number,
      price24: Number,
    },
  },
};
</script>
<style lang=""></style>
