<template>
  <div>
    <b-row>
      <b-col class="col-12">
        <b-form-group label="1 RMZ Value" label-for="value-rmz" label-cols-md="2">
          <b-row>
            <b-form-input
              v-model="value.rmz_value"
              type="number"
              placeholder="Usd Value"
              class="mr-4 ml-4 col-4"
              id="value-rmz"
            ></b-form-input>
            <span class="d-flex justify-content-center align-items-center">{{ value.rmz_value_unit }}</span>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col class="col-12">
        <b-form-group label="1 RMZr Value" label-for="value-rmzr" label-cols-md="2">
          <b-row>
            <b-form-input
              v-model="value.rmzr_value"
              type="number"
              placeholder="Usd Value"
              class="mr-4 ml-4 col-4"
              id="value-rmzr"
            ></b-form-input>
            <span class="d-flex justify-content-center align-items-center">{{ value.rmzr_value_unit }}</span>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
    <button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="btn btn-success pr-3 pl-3"
      @click="() => updateRMZrValue()"
    >
      Save
    </button>
  </div>
</template>

<script>
// import { BFormInput, BRow, BCol, BFormGroup, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  data() {
    return {
      value: {
        rmz_value: 0,
        rmzr_value: 0,
        rmz_value_unit: "",
        rmzr_value_unit: "",
      },
    };
  },
  components: {
    // BFormInput,
    // BFormGroup,
    // BRow,
    // BCol,
    // BButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    getRMZValue() {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/getRMZ_RMZRValue`)
        .then((res) => {
          console.log(res.data.data);
          this.value = res.data.data;
        });
    },
    selectUnit(unit) {
      this.unit = unit;
    },
    updateRMZrValue() {
      this.value.rmz_value = Number(this.value.rmz_value);
      this.value.rmzr_value = Number(this.value.rmzr_value);
      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/updateRMZ_RMZRValue`, this.value)
        .then((res) => {
          console.log(res.data);
          this.usd_value = res.data.data.usd_value;
        });
    },
  },
  mounted() {
    this.getRMZValue();
  },
};
</script>

<style></style>
