<template>
  <div>
    <div>
      <div v-if="isLoading">
        <b-card>
          <loading />
        </b-card>
      </div>
      <b-card v-else>
        <b-table responsive @sort-changed="sortingChanged" id="users-table" striped :items="users" :fields="fields">
          <template #cell(status)="status">
            <b-alert v-if="status.item.status == 'COMPLETED'" class="mb-0" show variant="success">
              <div class="alert-body">COMPLETED</div>
            </b-alert>
            <b-alert v-else-if="status.item.status == 'PENDDING'" class="mb-0" show variant="warning">
              <div class="alert-body">PENDDING</div>
            </b-alert>
            <b-alert v-else-if="status.item.status == 'REJECTED'" class="mb-0" show variant="danger">
              <div class="alert-body">REJECTED</div>
            </b-alert>
            <b-alert v-else-if="status.item.status == 'CANCELED'" class="mb-0" show variant="secondary">
              <div class="alert-body">CANCELED</div>
            </b-alert>
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="() => changeRequestStatus(data.item.user_id, data.item.id, 'COMPLETED')">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">COMPLETED</span>
              </b-dropdown-item>

              <b-dropdown-item @click="() => changeRequestStatus(data.item.user_id, data.item.id, 'REJECTED')">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">REJECTED</span>
              </b-dropdown-item>

              <b-dropdown-item @click="() => changeRequestStatus(data.item.user_id, data.item.id, 'CANCELED')">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">CANCELED</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-row class="m-2">
          <b-col cols="3" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="usersPerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="4">
            <b-pagination v-model="currentPage" hide-goto-end-buttons :total-rows="usersCount" />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
//   import UsersListFilters from "./components/UsersListFilters.vue";
import loading from "./../../components/loading";

import vSelect from "vue-select";
//   import AddNewUser from "./components/AddNewUserModal.vue";

export default {
  name: "UsersList",
  components: { vSelect, loading },
  data() {
    return {
      fields: [
        // "index",
        // A column that needs custom formatting
        { key: "email", sortable: true },
        { key: "name", sortable: true },
        // A regular column
        { key: "address", sortable: true },
        { key: "network", sortable: true },
        { key: "amount", sortable: true },
        { key: "status", sortable: true },
        "actions",
      ],
      users: [],
      searchObject: {},
      usersPerPage: 10,
      usersCount: 0,
      currentPage: 1,
      isFetching: false,
      isLoading: true,
      currentUser: null,
      showAddNewUserModal: false,
      isTogglingSuspend: {},
      searchText: "",
      perPageOptions: [10, 25, 50, 100],
      suspendList: [null, false, true],
      selectedTier: null,
      selectedTier1: null,
      suspended: null,
      sortBy: "register_date",
      sort: "DESC",
    };
  },

  methods: {
    changeRequestStatus(user_id, request_id, status) {
      this.isLoading = true;

      this.$http
        .put(`${this.$store.state.appConfig.base_url}/api/v1/exchange-token`, { user_id, request_id, status })
        .then(({ data }) => {
          this.getUsers();
          // this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;

          this.isFetching = false;
        });
    },
    sortingChanged(ctx) {
      if (ctx.sortDesc) {
        this.sort = "DESC";
      } else {
        this.sort = "ASC";
      }
      if (ctx.sortBy) {
        this.sortBy = ctx.sortBy;
      }
      this.getUsers();
    },
    linkGen(pageNum) {
      console.log(pageNum);
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    search(value) {
      this.searchObject = value;
      this.getUsers(value);
    },
    getUsers(searchObject) {
      this.isLoading = true;

      const params = {
        page: this.currentPage,
        limit: this.usersPerPage,
        sortBy: this.sortBy,
        sort: this.sort,
      };

      if (this.searchText && this.searchText.trim()) this.searchText.trim();
      this.isFetching = true;
      return this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/exchange-token/all`, { params })
        .then(({ data }) => {
          console.log(data);
          this.usersCount = data.count;
          this.users = data.data;
          console.log("usersusersusers", this.users);
          this.isLoading = false;

          return this.users;
        })
        .finally(() => {
          this.isLoading = false;

          this.isFetching = false;
        });
    },
    getUserDetails(id) {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/admin/users/getUser/${id}`)
        .then(({ data }) => {
          this.currentUser = data.data;
        })
        .finally(() => {});
    },
  },
  mounted() {
    this.getUsers();
  },
  watch: {
    currentPage: function () {
      this.getUsers(this.searchObject);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
