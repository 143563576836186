<template>
  <div>
    <b-table striped responsive :items="requests" :fields="fields">
      <!-- <template #cell(status)="data">
        <div>
          <span class="btn btn-warning" v-if="data.item.rejected">Rejected</span>
          <span class="btn btn-success" v-else-if="data.item.processing">Processing</span>
          <span class="btn btn-primary" v-else>Waiting</span>
        </div>
      </template> -->

      <!-- <template #cell(status)="data">
        <b-badge variant="success" class="btn-block">
          {{ data.item.status }}
        </b-badge>
      </template> -->

      <template #cell(address)="data">
        <span :id="`address-hover+${data.item.address}`" class="text">
          {{ data.item.address }}
        </span>
        <b-tooltip :target="`address-hover+${data.item.address}`" triggers="hover">
          {{ data.item.address }}
        </b-tooltip>
      </template>

      <template #cell(status)="data">
        <b-badge pill v-if="data.item.status" :variant="`light-success`" class="text-capitalize">
          {{ data.item.status }}
        </b-badge>

        <b-badge pill v-else :variant="`light-warning`" class="text-capitalize">
          {{ data.item.status }}
        </b-badge>
      </template>
    </b-table>
    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rows: 10,
      enumStatus: [
        "PENDING_USER",
        "PENDING_ADMIN",
        "USER_APPROVED",
        "ADMIN_APPROVED",
        "APPROVED",
        "USER_REJECTED",
        "ADMIN_REJECTED",
        "REJECTED",
      ],
      autoWithdrawalAmount: 0,
      selectedUnit: "btc",
      currencies: [
        "BTC",
        "USDT",
        "ETH",
        "XHT",
        "XRP",
        "BCH",
        "LINK",
        "XLM",
        "XMR",
        "ADA",
        "DAI",
        "UNI",
        "BNB",
        "LTC",
        "DOGE",
        "DOT",
      ],
      requests: [],
      fields: [
        "amount",
        "charge_rmz",
        "fee",
        "address",
        "charge",
        "charge-done",
        "withdraw_done",
        "currency",
        "email",
        "fullname",
        "status",
      ],
    };
  },
  methods: {
    selectUnit(unit) {
      console.log(unit);
      this.selectedUnit = unit;
      this.getAutoWithdrawalValue();
    },

    getRequests() {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/getWithdrawals`)
        .then((result) => {
          console.log(result);
          this.requests = result.data.data;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getRequests();
  },
};
</script>

<style>
.text {
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
