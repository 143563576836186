<template>
  <div>
    <b-card>
      <div>
        <b-card> Coinpayments Tokens </b-card>
        <b-table striped :items="tokenList" :fields="fields" :busy="isBusy">
          <template #cell(is_active)="is_active">
            <button
              class="btn btn-success"
              @click="() => toggleActivation(is_active.item)"
              v-if="is_active.item.is_active"
            >
              Active
            </button>

            <button class="btn btn-danger" @click="() => toggleActivation(is_active.item)" v-else>Inactive</button>
          </template>

          <template #cell(saved)="saved">
            <button class="btn btn-success" @click="() => deleteCoin(saved.item)" v-if="saved.item.saved">
              Delete
            </button>

            <button class="btn btn-danger" @click="() => ShowAddCoinModal(saved.item)" v-else>Add</button>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }"> -->
              <b-dropdown-item @click="() => showTokenDetails(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </b-card>

    <b-modal id="add-coin" v-model="showAddCoin" size="md" title="Coin Details" hide-footer centered scrollable>
      <div class="d-flex flex-column align-items-center">
        <span>{{ selectedCoin.coin }} </span>

        <div class="input-item m-1">
          <div class="input-label">chain</div>
          <input class="form-control" required type="text" v-model="selectedCoin.chain" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Decimal</div>
          <input class="form-control" required type="text" v-model="selectedCoin.fixed_decimal" />
        </div>
        <div class="d-flex justify-content-between">
          <button v-if="selectedCoin.saved" @click="() => addCoin(selectedCoin)" class="btn btn-success mr-4 ml-4">
            Update
          </button>
          <button v-else @click="() => addCoin(selectedCoin)" class="btn btn-success mr-4 ml-4">Save</button>

          <button @click="$bvModal.hide('add-coin')" class="btn btn-warning mr-4 ml-4">Cancel</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "Tokens",
  components: {},
  data() {
    return {
      selectedCoin: {},
      switchGroup: "",
      TokenGoingToDelete: "",
      isBusy: false,
      showAddCoin: false,
      showDeleteToken: false,
      page: 1,
      TokenPerPage: 12,
      idFetchingData: false,
      tokenList: [],
      fields: [
        {
          key: "coin",
          label: "Name",
          sortable: true,
        },
        {
          key: "is_active",
          label: "change Status",
          sortable: true,
        },
        {
          key: "saved",
          label: "saved",
          sortable: true,
        },
        {
          key: "fixed_decimal",
          label: "fixed_decimal",
        },
        {
          key: "actions",
          label: "actions",
        },
      ],
    };
  },
  methods: {
    showDeleteWxchangeModal(data) {
      this.showDeleteToken = true;
      this.TokenGoingToDelete = data;
    },
    showTokenDetails(data) {
      console.log("showTokenDetails", data);
      this.selectedCoin = data;
      this.showAddCoin = true;
    },
    addToken() {
      this.selectedToken = {};
      this.showAddToken = true;
    },
    toggleActivation(token) {
      this.isBusy = !this.isBusy;

      const payload = JSON.parse(JSON.stringify({ coin: token.coin }));

      console.log(token);
      if (token.is_active) {
        this.$http
          .put(`${this.$store.state.appConfig.base_url}/api/v1/gateways/symbols/disable?pg_id=COINPAYMENTS`, payload)
          .then((res) => {
            this.gettokenList();
          });
      } else {
        this.$http
          .put(`${this.$store.state.appConfig.base_url}/api/v1/gateways/symbols/enable?pg_id=COINPAYMENTS`, payload)
          .then((res) => {
            this.gettokenList();
          });
      }
    },
    ShowAddCoinModal(token) {
      console.log("ShowAddCoinModal", token);

      this.selectedCoin = token;
      this.showAddCoin = true;
    },
    addCoin(token) {
      console.log(token);
      const payload = {
        coin: token.coin,
        fixed_decimal: +token.fixed_decimal,
      };
      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/gateways/symbols?pg_id=COINPAYMENTS`, payload)
        .then((res) => {
          this.showAddCoin = false;
          this.gettokenList();
        });
    },
    deleteCoin(token) {
      console.log(token);
      const payload = {
        coin: token.coin,
      };
      console.log("payload", payload);
      this.$http
        .delete(`${this.$store.state.appConfig.base_url}/api/v1/gateways/symbols?pg_id=COINPAYMENTS`, { data: payload })
        .then((res) => {
          this.gettokenList();
        });
    },
    gettokenList() {
      const params = {
        pg_id: "COINPAYMENTS",
      };
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/gateways/coinpayments-symbols`, {
          params,
        })
        .then((res) => {
          this.tokenList = res.data.data;
          this.isBusy = false;
        });
    },
  },
  mounted() {
    this.gettokenList();
  },
};
</script>
