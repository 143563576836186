export default [
  {
    path: "/not-auth",
    name: "not-auth",
    meta: {
      action: "read",
      resource: "auth",
    },
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
  },
];
