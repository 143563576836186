<template>
  <form class="col" @submit.prevent="submit">
    <b-card>
      <div class="input-item">
        <div class="input-label">type</div>

        <v-select v-model="newService.type" class="select-type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="serviceTypes" @input="setSelectedType" />
      </div>
      <trade v-if="getMainCategory() == 'TRADES'" :service.sync="newService"></trade>
      <signal v-if="getMainCategory() == 'SIGNALS'" :service.sync="newService"></signal>
      <bot v-if="getMainCategory() == 'BOTS'" :service.sync="newService"></bot>
      <copyTrading v-if="getMainCategory() == 'COPY_TRADING'" :service.sync="newService"></copyTrading>
      <alarm v-if="getMainCategory() == 'ALARM'" :service.sync="newService"></alarm>
    </b-card>

    <div class="row d-flex justify-content-center">
      <exchange v-if="exchangesVisibility" :service.sync="newService"></exchange>
      <div v-if="pairsVisibility" class="card col-12 col-sm-5">
        <b-card>
          <div class="input-item">
            <div class="input-label">Pairs</div>
            <div v-if="getMainCategory() == 'TRADES'">
              <span class="m-1">Select All</span>
              <input type="checkbox" v-model="newService.hasAllPairs" />
            </div>

            <b />
            <div v-if="!newService.hasAllPairs">
              <b-form-group v-if="this.newService.type == 'AiSignalService' ||
                this.newService.type == 'LandcruiserSignalService' ||
                this.newService.type == 'TradingviewBotService' ||
                this.newService.type == 'OptimumSignalService' ||
                this.newService.type == 'CopyTrading'
                ">
                <v-select key="value" v-model="newService.pairs" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="allCoinPairs" class="select-size-md" label="value" multiple />
              </b-form-group>
              <b-form-group v-else>
                <v-select key="value" v-model="selectedExchange.pairs" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="coinPairs" class="select-size-md" label="value" multiple />
              </b-form-group>
              <vue-perfect-scrollbar :settings="settings" class="scroll-area">
                <div v-for="item in selectedExchangesPairs" :key="item" class="selectable-exchange">
                  <div>
                    {{ item }}
                  </div>
                </div>
              </vue-perfect-scrollbar>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <button class="btn btn-primary" type="submit">Submit</button>
  </form>
</template>

<script>
import vSelect from "vue-select";
import { BRow, BCol, BFormGroup } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import serviceTypes from "../serviceTypes.json";
import trade from "./components/trade";
import signal from "./components/signal";
import bot from "./components/bot";
import copyTrading from "./components/copy-trading.vue";
import alarm from "./components/alarm.vue";
import exchange from "./components/exchange";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    exchange,
    // 3rd party
    VuePerfectScrollbar,
    trade,
    bot,
    signal,
    alarm,
    copyTrading,
  },
  data() {
    return {
      serviceTypes: serviceTypes.Types,
      settings: {
        maxScrollbarLength: 60,
      },
      coinPairs: [],
      allCoinPairs: [],
      newService: {
        name: "",
        profileLogo: "",
        webPageLink: "",
        developerTeam: "",
        price24: 0,
        noOfExchanges: 0,
        noOfUsers: 0,
        exchanges: [],
        noOfPairs: 0,
        pairs: [],
        type: "SpotTradeService",
        noOfOpenPositions: 0,
        noOfStrategies: 0,
        totalPrice: 0,
        eachSuccessPrice: 0,
      },

      exchangesWithPairs: [
        {
          exchange: "",
          pairs: [],
        },
      ],
      selectedExchange: {
        pairs: [],
      },
      exchangesVisibility: true,
      pairsVisibility: true,
    };
  },
  computed: {
    // a computed getter
    selectedExchangesPairs() {
      const pairs = [];
      let finalPairs = [];
      this.newService.exchanges.forEach((ex) => {
        if (finalPairs.length == 0) {
          finalPairs = pairs.concat(ex.pairs);
        } else {
          finalPairs = pairs.concat(finalPairs, ex.pairs);
        }
      });
      if (finalPairs.length > this.newService.noOfPairs) {
        this.$bvToast.toast("You should not choose pairs more than 'Number Of Pairs'", {
          title: "Please check inputs",
          variant: "warning",
          solid: true,
        });
      }
      // `this` points to the vm instance
      return finalPairs;
    },
  },
  watch: {
    newService: {
      handler(newValue, oldValue) {
        this.changeFormVisibility();
        this.checkNumberOfExchanges();
      },
      deep: true,
    },
  },
  mounted() {
    this.getServiceById();
  },

  methods: {
    submit() {
      // const serviceId = this.$route.query.id;

      const serviceId = this.$route.query.id;

      if (serviceId) {
        this.editService();
      } else {
        this.addService();
      }
    },
    getMainCategory() {
      if (
        this.newService.type == "AiSignalService" ||
        this.newService.type == "LandcruiserSignalService" ||
        this.newService.type == "OptimumSignalService"
      ) {
        return "SIGNALS";
      }

      if (
        this.newService.type == "SpotTradeService" ||
        this.newService.type == "StopLossTradeService" ||
        this.newService.type == "SBBalanceTradeService" ||
        this.newService.type == "PingpongTradeService" ||
        this.newService.type == "TradingviewTradeService" ||
        this.newService.type == "FutureService" ||
        this.newService.type == "SBPercentTradeService"
      ) {
        return "TRADES";
      }

      if (this.newService.type == "CopyTrading") {
        return "COPY_TRADING";
      }

      if (this.newService.type == "AlarmService") {
        return "ALARM";
      }

      return "BOTS";
    },
    setSelectedType(type) {
      this.resetForm();
      this.newService.type = type;
    },
    resetForm() {
      this.selectedExchanges = [];
      this.selectedExchange.pairs = [];
      this.newService = {
        name: "",
        profileLogo: "",
        developerTeam: "",
        webPageLink: "",
        price: 0,
        noOfExchanges: 0,
        exchanges: [],
        noOfPairs: 0,
        noOfUsers: 0,
        pairs: [],
        type: "SpotTradeService",
        noOfOpenPositions: 0,
        noOfStrategies: 0,
        totalPrice: 0,
        eachSuccessPrice: 0,
      };
    },
    getServiceById() {
      const serviceId = this.$route.query.id;
      if (serviceId) {
        this.$http
          .get(`${this.$store.state.appConfig.base_url}/api/v1/packageServices/${serviceId}`)
          .then((result) => {
            this.newService = result.data.data;
          })
          .catch((err) => { });
      }
    },

    async getAllAiPairs() {
      let uniquePairs = [];
      if (process.env.NODE_ENV === "development") {
        uniquePairs = ["CTHAI/USDT", "YUCJ/USDT", "JEWEL/USDT", "EOS/USDC", "ATOM/USDC", "LTC/USDC", "BABYDOGEK/USDT"];
      } else {
        const result = await this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/cryptoSignals/assets`);
        // eslint-disable-next-line no-unused-expressions
        result?.data?.data.forEach((asset) => {
          uniquePairs.push(asset.symbol);
        });
        uniquePairs = [...new Set(uniquePairs || [])];
      }

      this.allCoinPairs = [...new Set(uniquePairs)];
    },
    async getAllPairs() {
      let uniquePairs = [];
      if (process.env.NODE_ENV === "development") {
        uniquePairs = ["CTHAI/USDT", "YUCJ/USDT", "JEWEL/USDT", "EOS/USDC", "ATOM/USDC", "LTC/USDC", "BABYDOGEK/USDT"];
      } else {
        const result = await this.$http.get(
          `${this.$store.state.appConfig.base_url}/api/v2/trades/pairs?exchange=binance`
        );
        uniquePairs = [...new Set(result?.data?.data.result || [])];
      }

      this.allCoinPairs = [...new Set(uniquePairs)];
    },
    async getPairs() {
      this.coinPairs = [];
      const exId = this.exchanges.find((ex) => ex === this.selectedExchange.exchange);
      const checkCach = this.exchangesWithPairs.filter((item) => item.exchange == exId);
      if (checkCach.length > 0) {
        this.coinPairs = checkCach[0].pairs;
        return;
      }

      let uniquePairs = [];
      if (process.env.NODE_ENV === "development") {
        uniquePairs = ["CTHAI/USDT", "YUCJ/USDT", "JEWEL/USDT", "EOS/USDC", "ATOM/USDC", "LTC/USDC", "BABYDOGEK/USDT"];
      } else {
        const result = await this.$http.get(
          `${this.$store.state.appConfig.base_url}/api/v2/trades/pairs?exchange=${exId}`
        );
        uniquePairs = [...new Set(result?.data?.data.result || [])];
      }

      if (checkCach == 0) {
        const newData = {
          exchange: exId,
          pairs: uniquePairs,
        };
        this.exchangesWithPairs.push(newData);
      }
      this.coinPairs = uniquePairs;
    },
    addService() {
      this.newService.price24 = Number(this.newService.price24);
      this.newService.noOfUsers = Number(this.newService.noOfUsers);
      this.newService.noOfExchanges = Number(this.newService.noOfExchanges);
      this.newService.pricePerUnit = Number(this.newService.pricePerUnit);
      this.newService.noOfPairs = Number(this.newService.noOfPairs);
      this.newService.noOfOpenPositions = Number(this.newService.noOfOpenPositions);
      this.newService.noOfStrategies = Number(this.newService.noOfStrategies);
      this.newService.totalPrice = Number(this.newService.totalPrice);
      this.newService.eachSuccessPrice = Number(this.newService.eachSuccessPrice);
      this.newService.alarmConfigCount = Number(this.newService.alarmConfigCount);
      this.newService.alarmCount = Math.round(Number(this.newService.totalPrice / this.newService.eachSuccessPrice));
      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageServices/create`, this.newService)
        .then((result) => {
          this.resetForm();
          this.showaddService = false;
          this.$bvToast.toast(`${result.data.data.name}  Created`, {
            title: "Service Created",
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "packages-service" });
        })
        .catch((err) => { });
    },
    editService() {
      this.newService.price24 = Number(this.newService.price24);
      this.newService.noOfUsers = Number(this.newService.noOfUsers);
      this.newService.noOfExchanges = Number(this.newService.noOfExchanges);
      this.newService.pricePerUnit = Number(this.newService.pricePerUnit);
      this.newService.noOfPairs = Number(this.newService.noOfPairs);
      this.newService.noOfOpenPositions = Number(this.newService.noOfOpenPositions);
      this.newService.noOfStrategies = Number(this.newService.noOfStrategies);
      this.newService.totalPrice = Number(this.newService.totalPrice);
      this.newService.eachSuccessPrice = Number(this.newService.eachSuccessPrice);
      this.newService.noOfSubscribers = Number(this.newService.noOfSubscribers);
      this.$http
        .patch(
          `${this.$store.state.appConfig.base_url}/api/v1/admin/packageServices/edit/${this.newService.id}`,
          this.newService
        )
        .then((result) => {
          this.showeditService = false;
          this.$bvToast.toast(`${this.newService.name}  Edited`, {
            title: "Service Edited",
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "packages-service" });
        })
        .catch((err) => { });
    },
    changeFormVisibility() {
      // eslint-disable-next-line default-case
      this.financialMarketVisibility = false;
      this.providerVisibility = false;

      switch (this.newService.type) {
        case "AlarmService":
          this.pairsVisibility = false;
          this.exchangesVisibility = false;

          break;
        case "TradingviewBotService":
          this.pairsVisibility = false;
          this.exchangesVisibility = false;
          this.getAllPairs();

          break;

        case "TradingviewTradeService":
          this.noOfPairsVisibility = true;
          this.pairsVisibility = true;
          break;

        case "AiSignalService":
          this.noOfPairsVisibility = true;
          this.pairsVisibility = true;
          this.getAllAiPairs();
          break;

        case "LandcruiserSignalService":
        case "OptimumSignalService":
          this.noOfPairsVisibility = true;
          this.pairsVisibility = true;
          this.exId = "binance";
          this.getAllPairs();
          break;

        case "CopyTrading":
          this.noOfPairsVisibility = true;
          this.pairsVisibility = true;
          this.exchangesVisibility = false;
          this.getAllAiPairs();
          break;
      }
    },
    checkNumberOfExchanges() {
      if (this.newService.exchanges.length > this.newService.noOfExchanges) {
        this.$bvToast.toast("You should not choose exchanges more than 'Number Of Exchanges'", {
          title: "Please check inputs",
          variant: "warning",
          solid: true,
        });
      }
    },
    getServiceById() {
      const serviceId = this.$route.query.id;
      if (serviceId) {
        this.$http
          .get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageServices/${serviceId}`)
          .then((result) => {
            this.newService = result.data.data;
            this.newService.exchanges.forEach((item) => {
              this.selectedExchanges.push(item.exchange);
            });
            this.changeFormVisibility();
          })
          .catch((err) => { });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../@core/scss/base/components.scss";

.select-type {
  width: 100% !important;
}

.selectable-exchange {
  padding: 2px;
  margin: 2px;
}

.selectable-exchange:hover {
  cursor: pointer;
}

.active-exchange {
  border: 0 solid rgba(60, 60, 60, 0.26);
  // background: $theme-dark-body-bg;
  background: $theme-dark-body-color;
  padding: 20px;
  margin: 2px;
  border-radius: 0.428rem;
}

.unactive-exchange {
  background: whitesmoke;
  padding: 20px;
  margin: 2px;
  border-radius: 0.428rem;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 400px;
  height: 50vh;
}

.select-type {
  width: 220px;
  display: block;
}
</style>
