<template>
  <form class="col" @submit.prevent="editService">
    <div class="row d-flex justify-content-center">
      <div class="card m-1 col-12 col-sm-2">
        <b-card>
          <b-col cols="12">
            <div class="d-flex flex-column align-items-center">
              <div class="input-item">
                <div class="input-label">type</div>

                <v-select
                  v-model="newService.type"
                  class="select-type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="serviceTypes"
                  @input="setSelectedType"
                />
              </div>

              <div class="input-item" v-if="financialMarketVisibility">
                <div class="input-label">Financial Market</div>

                <v-select
                  v-model="newService.financialMarket"
                  class="select-type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="markets"
                />
              </div>

              <div class="input-item" v-if="providerVisibility">
                <div class="input-label">Provider</div>

                <v-select
                  v-model="newService.provider"
                  class="select-type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="tradingviewbotserviceProviders"
                />
              </div>

              <div class="input-item m-1">
                <div class="input-label">Name</div>
                <input v-model="newService.name" class="form-control" type="text" required />
              </div>

              <div class="input-item m-1">
                <div class="input-label">Profile Logo</div>
                <input v-model="newService.profileLogo" class="form-control" type="text" required />
              </div>

              <div class="input-item m-1">
                <div class="input-label">WebPage Link</div>
                <input v-model="newService.webPageLink" class="form-control" type="text" required />
              </div>

              <div class="input-item m-1">
                <div class="input-label">Developer Team</div>
                <input v-model="newService.developerTeam" class="form-control" type="text" required />
              </div>

              <div v-if="noOfUsersVisibility" class="input-item m-1">
                <div class="input-label">Number Of Users</div>
                <input v-model="newService.noOfUsers" class="form-control" type="number" required min="1" />
              </div>

              <div v-if="noOfExchangesVisibility" class="input-item m-1">
                <div class="input-label">Number Of Exchanges</div>
                <input v-model="newService.noOfExchanges" class="form-control" type="number" required min="1" />
              </div>

              <div v-if="noOfPairsVisibility" class="input-item m-1">
                <div class="input-label">Number Of Pairs</div>
                <input v-model="newService.noOfPairs" class="form-control" type="number" required min="1" />
              </div>
              <div v-if="noOfOpenPositionVisibility" class="input-item m-1">
                <div class="input-label">Number Of Open Position</div>
                <input v-model="newService.noOfOpenPositions" class="form-control" type="number" required min="1" />
              </div>

              <div v-if="noOfStrategyVisibility" class="input-item m-1">
                <div class="input-label">Number Of Strategy</div>
                <input v-model="newService.noOfStrategies" class="form-control" type="number" required min="1" />
              </div>

              <div v-if="price24Visibility" class="input-item m-1">
                <div class="input-label">24H Price</div>
                <input v-model="newService.price24" class="form-control" type="number" required min="1" />
              </div>

              <div v-if="totalPriceVisibility" class="input-item m-1">
                <div class="input-label">Total Price</div>
                <input v-model="newService.totalPrice" class="form-control" type="number" required min="1" />
              </div>

              <div v-if="eachSuccessPriceVisibility" class="input-item m-1">
                <div class="input-label">Each Success Price</div>
                <input v-model="newService.eachSuccessPrice" class="form-control" type="number" required min="1" />
              </div>
            </div>
          </b-col>
        </b-card>
      </div>

      <div v-if="exchangesVisibility" class="card m-1 col-12 col-sm-4">
        <b-card>
          <div class="input-item m-1">
            <div class="input-label">Exchanges</div>
            <b-form-group>
              <v-select
                key="label"
                v-model="selectedExchanges"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="exchanges"
                class="select-size-md"
                label="label"
                multiple
                @input="setSelected"
              />
            </b-form-group>
          </div>
          <vue-perfect-scrollbar :settings="settings" class="scroll-area">
            <div v-for="item in newService.exchanges" :key="item.id" class="selectable-exchange">
              <div
                :class="{
                  'unactive-exchange': selectedExchange.exchange != item.exchange,
                  'active-exchange': selectedExchange.exchange == item.exchange,
                }"
                @click="() => selectExchange(item)"
              >
                {{ item.exchange }}
              </div>
            </div>
          </vue-perfect-scrollbar>
        </b-card>
      </div>

      <div v-if="pairsVisibility" class="card m-1 col-12 col-sm-4">
        <b-card>
          <div class="input-item m-1">
            <div class="input-label">Pairs</div>
            <b />
            <b-form-group v-if="this.newService.type == 'AiSignalService'">
              <v-select
                key="value"
                v-model="newService.pairs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="allCoinPairs"
                class="select-size-md"
                label="value"
                multiple
              />
            </b-form-group>
            <b-form-group v-else>
              <v-select
                key="value"
                v-model="selectedExchange.pairs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="coinPairs"
                class="select-size-md"
                label="value"
                multiple
              />
            </b-form-group>
            <vue-perfect-scrollbar :settings="settings" class="scroll-area">
              <div v-for="item in selectedExchangesPairs" :key="item" class="selectable-exchange">
                <div>
                  {{ item }}
                </div>
              </div>
            </vue-perfect-scrollbar>
          </div>
        </b-card>
      </div>
    </div>
    <button class="btn btn-primary" type="submit">Update</button>
  </form>
</template>

<script>
import vSelect from "vue-select";
import { BRow, BCol, BFormGroup } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import serviceTypes from "../serviceTypes.json";
import markets from "../financialMarkets.json";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    // 3rd party
    VuePerfectScrollbar,
  },
  data() {
    return {
      selectedExchanges: [],
      serviceTypes: serviceTypes.Types,
      markets: markets.markets,
      tradingviewbotserviceProviders: ["Optimum", "Landcruiser"],
      settings: {
        maxScrollbarLength: 60,
      },
      coinPairs: [],
      allCoinPairs: [],
      newService: {
        name: "",
        price24: 0,
        noOfExchanges: 0,
        exchanges: [],
        noOfPairs: 0,
        pairs: [],
        type: "SpotTradeService",
        noOfOpenPositions: 0,
        noOfStrategies: 0,
        totalPrice: 0,
        eachSuccessPrice: 0,
      },
      exchanges: [],
      exchangesWithPairs: [
        {
          exchange: "",
          pairs: [],
        },
      ],
      selectedExchange: {
        pairs: [],
      },
      noOfExchangesVisibility: true,
      exchangesVisibility: true,
      noOfPairsVisibility: true,
      pairsVisibility: true,
      price24Visibility: true,
      noOfOpenPositionVisibility: false,
      noOfStrategyVisibility: false,
      totalPriceVisibility: false,
      eachSuccessPriceVisibility: false,
      financialMarketVisibility: false,
      providerVisibility: false,
    };
  },
  computed: {
    // a computed getter
    // eslint-disable-next-line vue/return-in-computed-property, consistent-return
    selectedExchangesPairs() {
      const pairs = [];
      let finalPairs = [];
      if (this.newService.exchanges) {
        this.newService.exchanges.forEach((ex) => {
          if (finalPairs.length == 0) {
            finalPairs = pairs.concat(ex.pairs);
          } else {
            finalPairs = pairs.concat(finalPairs, ex.pairs);
          }
        });
        if (finalPairs.length > this.newService.noOfPairs) {
          this.$bvToast.toast("You should not choose pairs more than 'Number Of Pairs'", {
            title: "Please check inputs",
            variant: "warning",
            solid: true,
          });
        }
        // `this` points to the vm instance
        return finalPairs;
      }
    },
  },
  watch: {
    newService: {
      handler(newValue, oldValue) {
        this.changeFormVisibility();
        this.checkNumberOfExchanges();
      },
      deep: true,
    },
    selectedExchanges: {
      handler(newValue, oldValue) {
        console.log("exElement this.selectedExchanges.length", this.selectedExchanges.length);
        // console.log("exElement this.newService.exchanges.length", this.newService.exchanges.length);
        if (this.newService.exchanges.length != this.selectedExchanges.length) {
          for (let exIndex = 0; exIndex < this.newService.exchanges.length; exIndex++) {
            const exElement = this.newService.exchanges[exIndex];
            console.log("exElement", exElement.exchange);

            let findedIndex = -1;
            for (let index = 0; index < this.selectedExchanges.length; index++) {
              const element = this.selectedExchanges[index];
              console.log("exElement element", element);

              if (element == exElement.exchange) {
                if (findedIndex == -1) {
                  findedIndex = index;
                }
                console.log("exElement findedIndex", findedIndex);
              }
            }

            if (findedIndex == -1) {
              console.log("exElement not found", exElement.exchange);
              this.newService.exchanges.splice(exIndex, 1);
            }

            if (this.selectedExchanges.length == 0) {
              this.selectedExchange.pairs = [];
            } else {
              // eslint-disable-next-line prefer-destructuring
              this.selectedExchange = this.newService.exchanges[0];
            }
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getExchanges();
    this.getServiceById();
  },

  methods: {
    submit() {},
    resetForm() {
      this.selectedExchanges = [];
      this.newService = {
        name: "",
        price: 0,
        noOfExchanges: 0,
        exchanges: [],
        noOfPairs: 0,
        pairs: [],
        type: "SpotTradeService",
        noOfOpenPositions: 0,
        noOfStrategies: 0,
        totalPrice: 0,
        eachSuccessPrice: 0,
      };
    },
    getServiceById() {
      const serviceId = this.$route.query.id;
      if (serviceId) {
        this.$http
          .get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageServices/${serviceId}`)
          .then((result) => {
            this.newService = result.data.data;
            console.log("this.newService", this.newService);
            this.newService.exchanges.forEach((item) => {
              this.selectedExchanges.push(item.exchange);
            });
            this.changeFormVisibility();
          })
          .catch((err) => {});
      }
    },
    setSelected(value) {
      value.forEach((item) => {
        console.log("item", item);
        // const filt = this.newService.exchanges.filter(e => e.exchange == item)

        if (this.newService.exchanges.filter((e) => e.exchange == item).length > 0) {
        } else {
          this.newService.exchanges.push({
            exchange: item,
            pairs: [],
          });
        }
      });
    },
    selectExchange(exchange) {
      this.selectedExchange = exchange;
      this.getPairs();
    },
    async getAllPairs() {
      let uniquePairs = [];
      const result = await this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/cryptoSignals/assets`);
      // eslint-disable-next-line no-unused-expressions
      result?.data?.data.forEach((asset) => {
        uniquePairs.push(asset.symbol);
      });
      uniquePairs = [...new Set(uniquePairs || [])];
      // let uniquePairs = [
      //   "CTHAI/USDT",
      //   "YUCJ/USDT",
      //   "JEWEL/USDT",
      //   "EOS/USDC",
      //   "ATOM/USDC",
      //   "LTC/USDC",
      //   "BABYDOGEK/USDT",
      // ];
      this.allCoinPairs = [...new Set(uniquePairs)];
    },
    async getPairs() {
      this.coinPairs = [];
      const exId = this.exchanges.find((ex) => ex === this.selectedExchange.exchange);
      const checkCach = this.exchangesWithPairs.filter((item) => item.exchange == exId);
      if (checkCach.length > 0) {
        this.coinPairs = checkCach[0].pairs;
        return;
      }

      const result = await this.$http.get(
        `${this.$store.state.appConfig.base_url}/api/v2/trades/pairs?exchange=${exId}`
      );
      const uniquePairs = [...new Set(result?.data?.data.result || [])];
      // let uniquePairs = [
      //   "CTHAI/USDT",
      //   "YUCJ/USDT",
      //   "JEWEL/USDT",
      //   "EOS/USDC",
      //   "ATOM/USDC",
      //   "LTC/USDC",
      //   "BABYDOGEK/USDT",
      // ];
      if (checkCach == 0) {
        const newData = {
          exchange: exId,
          pairs: uniquePairs,
        };
        this.exchangesWithPairs.push(newData);
      }
      this.coinPairs = uniquePairs;
    },
    getExchanges() {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v2/trades`)
        .then((result) => {
          this.exchanges = result.data.data;
        })
        .catch((err) => {});
    },
    editService() {
      this.newService.price24 = Number(this.newService.price24);
      this.newService.noOfExchanges = Number(this.newService.noOfExchanges);
      this.newService.pricePerUnit = Number(this.newService.pricePerUnit);
      this.newService.noOfPairs = Number(this.newService.noOfPairs);
      this.newService.noOfOpenPositions = Number(this.newService.noOfOpenPositions);
      this.newService.noOfStrategies = Number(this.newService.noOfStrategies);
      this.newService.totalPrice = Number(this.newService.totalPrice);
      this.newService.eachSuccessPrice = Number(this.newService.eachSuccessPrice);
      this.$http
        .patch(
          `${this.$store.state.appConfig.base_url}/api/v1/admin/packageServices/edit/${this.newService.id}`,
          this.newService
        )
        .then((result) => {
          this.showeditService = false;
          this.$bvToast.toast(`${this.newService.name}  Edited`, {
            title: "Service Edited",
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "packages-service" });
        })
        .catch((err) => {});
    },
    changeFormVisibility() {
      this.financialMarketVisibility = false;
      this.providerVisibility = false;
      // eslint-disable-next-line default-case
      switch (this.newService.type) {
        case "TradingviewBotService":
          this.price24Visibility = true;
          this.profileLogoVisibility = true;
          this.webPageLinkVisibility = true;
          this.developerTeamVisibility = true;
          this.noOfUsersVisibility = true;
          this.financialMarketVisibility = true;
          this.providerVisibility = true;
          this.noOfExchangesVisibility = false;
          this.exchangesVisibility = false;
          this.noOfPairsVisibility = false;
          this.noOfOpenPositionVisibility = false;
          this.noOfStrategyVisibility = false;
          this.totalPriceVisibility = false;
          this.eachSuccessPriceVisibility = false;
          this.pairsVisibility = true;
          break;

        case "SpotTradeService":
        case "StopLossTradeService":
        case "SBBalanceTradeService":
        case "SBPercentTradeService":
        case "PingpongTradeService":
        case "FutureService":
          this.noOfExchangesVisibility = true;
          this.exchangesVisibility = true;
          this.noOfPairsVisibility = true;
          this.pairsVisibility = true;
          this.price24Visibility = true;
          this.noOfOpenPositionVisibility = true;
          this.noOfStrategyVisibility = false;
          this.totalPriceVisibility = false;
          this.eachSuccessPriceVisibility = false;
          break;

        case "TradingviewTradeService":
          this.noOfExchangesVisibility = true;
          this.exchangesVisibility = true;
          this.noOfPairsVisibility = true;
          this.pairsVisibility = true;
          this.price24Visibility = true;
          this.noOfOpenPositionVisibility = true;
          this.noOfStrategyVisibility = true;
          this.totalPriceVisibility = false;
          this.eachSuccessPriceVisibility = false;

          break;

        case "AiSignalService":
          this.noOfExchangesVisibility = false;
          this.exchangesVisibility = false;
          this.noOfPairsVisibility = true;
          this.pairsVisibility = true;
          this.price24Visibility = false;
          this.noOfOpenPositionVisibility = false;
          this.noOfStrategyVisibility = false;
          this.totalPriceVisibility = true;
          this.eachSuccessPriceVisibility = true;
          this.getAllPairs();
          break;

        case "LandcruiserSignalService":
        case "OptimumSignalService":
          this.noOfExchangesVisibility = false;
          this.exchangesVisibility = false;
          this.noOfPairsVisibility = true;
          this.pairsVisibility = true;
          this.price24Visibility = false;
          this.noOfOpenPositionVisibility = false;
          this.noOfStrategyVisibility = false;
          this.totalPriceVisibility = true;
          this.eachSuccessPriceVisibility = true;
          this.getAllPairs();
          break;
      }
    },
    checkNumberOfExchanges() {
      if (
        this.newService.exchanges &&
        this.newService.noOfExchanges &&
        this.newService.exchanges.length > this.newService.noOfExchanges
      ) {
        this.$bvToast.toast("You should not choose exchanges more than 'Number Of Exchanges'", {
          title: "Please check inputs",
          variant: "warning",
          solid: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../@core/scss/base/components.scss";

.selectable-exchange {
  padding: 2px;
  margin: 2px;
}

.selectable-exchange:hover {
  cursor: pointer;
}

.active-exchange {
  background: $theme-dark-body-bg;
  padding: 20px;
  margin: 2px;
}

.unactive-exchange {
  background: whitesmoke;
  padding: 20px;
  margin: 2px;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 400px;
  height: 50vh;
}

.select-type {
  width: 220px;
  display: block;
}
</style>
