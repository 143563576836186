export default [
    {
      path: "/signals",
      name: "signals",
      meta: {
        action: "read",
        resource: "packages",
      },
      component: () => import("@/views/pages/packages/SignalsReport.vue"),
    },
  ];
  