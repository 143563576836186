export default [
  {
    path: "/sales",
    name: "sales",
    meta: {
      action: "read",
      resource: "salesreport",
    },
    component: () => import("@/views/pages/sale-report/SaleReport"),
  },
];
