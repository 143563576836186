export enum ExchangesEnum {
  ALL,
  BINANCE,
  EXIR,
  COINEX,
  KUCOIN,
  MAZDAX,
  NOBITEX,
  WALLEX,
  RAMZINEX,
}



export const getExchangesEnumAsList = () => {
  let map: { id: number; name: string }[] = [];

  for (var n in ExchangesEnum) {
    if (typeof ExchangesEnum[n] === 'number') {
      map.push({ id: <any>ExchangesEnum[n], name: n });
    }
  }
  return map;
}
