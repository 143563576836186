<template>
  <div>
    <b-card>
      <div>
        <b-button
          @click="() => addCategory()"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mb-2"
        >
          Add Category</b-button
        >
        <b-table striped :items="exchangeList" :fields="fields" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(types)="types">
            <!-- {{ types.item.types }} -->
            <div v-for="t in types.item.types" :key="t">
              <b-badge :variant="typesVariant(t)">
                {{ t }}
              </b-badge>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }"> -->
              <b-dropdown-item @click="() => showDetail(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details/Update</span>
              </b-dropdown-item>
              <b-dropdown-item @click="() => showDeleteModal(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <!-- <template #cell(index)="data">{{ data.index + 1 }}</template> -->
        </b-table>
      </div>
    </b-card>

    <b-modal
      id="delete-exchange"
      v-model="showDeleteCategory"
      size="lg"
      title="Delete Category"
      hide-footer
      centered
      scrollable
    >
      <div class="d-flex flex-column align-items-center">
        <span>
          Are you sure you want to delete
          <span style="font-size: 20px">{{ selectedCategory.name }}</span>
          ?
        </span>

        <div class="d-flex justify-content-between">
          <button @click="() => deleteCategory(selectedCategory.id)" class="btn btn-success mr-4 ml-4">YES</button>
          <button @click="$bvModal.hide('delete-exchange')" class="btn btn-warning mr-4 ml-4">NO</button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="showCreateCategoryModal"
      size="lg"
      title="Create Category"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div class="d-flex flex-column align-items-center pb-10">
        <div class="input-item m-1">
          <div class="input-label">category type</div>

          <v-select
            class="select-type"
            style="min-width: 250px"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="packageCategoryTypes"
            v-model="newCategory.categoryType"
          />
        </div>

        <div class="input-item m-1">
          <div class="input-label">type</div>

          <v-select
            class="select-type"
            style="min-width: 250px"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="serviceTypes"
            v-model="newCategory.types"
            multiple
          />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Name</div>
          <input class="form-control" type="text" v-model="newCategory.name" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Fa Name</div>
          <input class="form-control" type="text" v-model="newCategory.fa_name" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Color</div>
          <input class="form-control" type="text" v-model="newCategory.color" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Description</div>

          <!-- <quill-editor v-model="content" /> -->

          <b-form-textarea
            id="textarea-default"
            placeholder="Package Description"
            rows="3"
            v-model="newCategory.description"
            class="char-textarea"
            :state="newCategory.description.length <= 2024"
            :class="newCategory.description.length >= 2024 ? 'text-danger' : ''"
          />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Special Offer</div>
          <input class="form-control" type="checkbox" v-model="newCategory.isSpecialOffer" />
        </div>
        <button @click="() => createCategory()" class="btn btn-primary" type="submit">Create</button>
      </div>
    </b-modal>

    <b-modal
      v-model="showUpdateCategoryModal"
      size="lg"
      title="Update Category"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div class="d-flex flex-column align-items-center pb-10">
        <div class="input-item m-1">
          <div class="input-label">Name</div>
          <input class="form-control" type="text" v-model="selectedCategory.name" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Fa Name</div>
          <input class="form-control" type="text" v-model="selectedCategory.fa_name" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Color</div>
          <input class="form-control" type="text" v-model="selectedCategory.color" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Description</div>
          <b-form-textarea
            id="textarea-default"
            placeholder="Package Description"
            rows="3"
            v-model="selectedCategory.description"
            class="char-textarea"
            :state="selectedCategory.description.length <= 1024"
            :class="selectedCategory.description.length >= 1024 ? 'text-danger' : ''"
          />
        </div>

        <div class="input-item m-1">
          <div class="input-label">type</div>

          <v-select
            class="select-type"
            style="min-width: 250px"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="serviceTypes"
            v-model="selectedCategory.types"
            multiple
          />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Special Offer</div>
          <input class="form-control" type="checkbox" v-model="selectedCategory.isSpecialOffer" />
        </div>
        <button @click="() => updateCategory()" class="btn btn-primary" type="submit">Update</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import serviceTypes from "./serviceTypes.json";
import packageCategoryTypes from "./packageCategoryType.json";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      content: "",
      exchangeList: [],
      fields: ["name", "fa_name", "types", "actions"],
      isBusy: false,
      showCreateCategoryModal: false,
      showDeleteCategory: false,
      showUpdateCategoryModal: false,
      newCategory: {
        description: "",
      },
      serviceTypes: serviceTypes.Types,
      packageCategoryTypes: packageCategoryTypes.Types,
      selectedCategory: {
        description: "",
      },
    };
  },
  methods: {
    resetCreateForm() {
      this.newCategory = {
        description: "",
      };
    },
    showDetail(data) {
      this.selectedCategory = data;
      this.showUpdateCategoryModal = true;
    },
    showDeleteModal(data) {
      this.selectedCategory = data;
      this.showDeleteCategory = true;
    },
    updateCategory() {
      this.$http
        .patch(
          `${this.$store.state.appConfig.base_url}/api/v1/admin/packageCategory/update/${this.selectedCategory.id}`,
          this.selectedCategory
        )
        .then((res) => {
          this.$bvToast.toast(`Updated`, {
            title: `Category Updated`,
            variant: "success",
            solid: true,
          });
          this.showUpdateCategoryModal = false;
          this.getCategoriesList();
        });
    },
    createCategory() {
      // this.newCategory.description = JSON.stringify(this.newCategory.description);

      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageCategory/create`, this.newCategory)
        .then((res) => {
          this.resetCreateForm();
          this.showCreateCategoryModal = false;

          this.$bvToast.toast(`${res.data.data.name}  Created`, {
            title: `Category Created`,
            variant: "success",
            solid: true,
          });
          this.getCategoriesList();
        });
    },
    addCategory() {
      this.showCreateCategoryModal = true;
    },
    deleteCategory(id) {
      this.isBusy = true;
      this.$http
        .delete(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageCategory/delete/${id}`)
        .then((res) => {
          this.getCategoriesList();
          this.$bvToast.toast(`Deleted`, {
            title: `Category Deleted`,
            variant: "success",
            solid: true,
          });
          this.isBusy = false;
          this.showDeleteCategory = false;
        });
    },
    getCategoriesList() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageCategory`).then((res) => {
        this.exchangeList = res.data.data;
        console.log(res);
        this.isBusy = false;
      });
    },
  },
  computed: {
    typesVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        SpotTradeService: "light-primary",
        StopLossTradeService: "light-success",
        SBBalanceTradeService: "light-danger",
        SBPercentTradeService: "light-warning",
        PingpongTradeService: "light-info",
        TradingviewTradeService: "primary",
        AiSignalService: "success",
        LandcruiserSignalService: "success",
        OptimumSignalService: "success",
        IpService: "danger",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  mounted() {
    this.getCategoriesList();
  },
};
</script>

<style></style>
