<template>
  <div>
    <div>
      <b-card>
        <b-button v-if="$can(this.$store.state.acl.actions.create, 'packages')" variant="primary" class="mt-2 mb-2"
          @click="() => showaddPackageModal()">
          Add Package
        </b-button>
        <b-table striped responsive :items="packages" :fields="fields">
          <template #cell(price)="price">
            {{ calculatePrice(price.item) }}
          </template>
          <template #cell(discount)="discount">
            {{ calculateDiscount(discount.item) }}
          </template>
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }"> -->
              <b-dropdown-item @click="() => showPackageDetail(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>
              <b-dropdown-item @click="() => showPackageDelete(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="rows" first-number last-number prev-class="prev-item"
            next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-card>
    </div>

    <b-modal id="delete-exchange" v-model="showAddPackage" no-close-on-backdrop size="lg" title="Create Package"
      hide-footer centered>
      <form class="col" @submit.prevent="createPackage">
        <div class="d-flex flex-column align-items-center">
          <div class="input-item m-1">
            <div class="input-label">Name</div>
            <input v-model="newPackage.name" class="form-control" required type="text" />
          </div>

          <div class="nput-item">
            <div class="input-label">webPageLink</div>
            <input v-model="newPackage.webPageLink" class="form-control" type="text" />
          </div>

          <div class="nput-item">
            <div class="input-label">profileLogo</div>
            <input v-model="newPackage.profileLogo" class="form-control" type="text" />
          </div>

          <div class="nput-item">
            <div class="input-label">developerTeam</div>
            <input v-model="newPackage.developerTeam" class="form-control" type="text" />
          </div>
          <div class="input-item m-1">
            <div class="input-label">Description</div>
            <b-form-textarea id="textarea-default" v-model="newPackage.description" placeholder="Package Description"
              rows="3" class="char-textarea" :state="newPackage.description.length <= 1024"
              :class="newPackage.description.length >= 1024 ? 'text-danger' : ''" />
          </div>

          <div class="input-item m-1">
            <div class="input-label">Reward</div>
            <input v-model="newPackage.reward" class="form-control" required type="text" />
          </div>
          <div>
            <div class="input-label">Category</div>

            <v-select v-model="newPackage.category" class="select-type" style="min-width: 250px"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="categories" label="name" name="name"
              @change="changeCategory" @input="changeCategory" />
            <div>
              <div class="input-label">Services</div>

              <v-select v-model="newPackage.services" class="select-type" style="min-width: 250px"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="services" multiple label="name"
                name="name" />
            </div>
          </div>
          <div v-if="durationVisibility" class="nput-item">
            <div class="input-label">Duration</div>
            <input v-model="newPackage.duration" class="form-control" required min="1" type="number" />
          </div>

          <div class="nput-item">
            <div class="input-label">Discount</div>
            <input v-model="newPackage.discount" class="form-control" required min="0" type="number" />
          </div>
          <div class="nput-item">
            <div class="input-label">Pure Price</div>
            <div class="form-control" type="text">
              {{ calculateNewPackagePurePrice(newPackage) }}
            </div>
          </div>
          <div v-if="calculateNewPackagePrice(newPackage) != calculateNewPackagePurePrice(newPackage)">
            <div class="nput-item">
              <div class="input-label">After Discount Price</div>
              <div class="form-control" type="text">
                {{ calculateNewPackagePrice(newPackage) }}
              </div>
            </div>
          </div>
          <div class="nput-item">
            <div class="input-label">Is Free</div>
            <input v-model="newPackage.isFree" class="form-control" type="checkbox" />
          </div>
          <button class="mt-2 btn btn-primary" type="submit">Submit</button>
        </div>
      </form>
    </b-modal>

    <b-modal id="delete-package" v-model="showDetailModal" no-close-on-backdrop size="lg" title="Package Details"
      hide-footer centered>
      <div v-if="selectedPackage" class="d-flex flex-column align-items-center">
        <div class="m-1 d-flex flex-column justify-content-center align-items-center">
          <div class="mt-1 d-flex flex-row">
            <div class="mr-1 nput-item">
              <div class="input-label">Name</div>
              <input v-model="selectedPackage.name" class="form-control" type="text" />
            </div>

            <div class="ml-1 nput-item">
              <div class="input-label">webPageLink</div>
              <input v-model="selectedPackage.webPageLink" class="form-control" type="text" />
            </div>
          </div>

          <div class="mt-1 d-flex flex-row">
            <div class="mr-1 nput-item">
              <div class="input-label">profileLogo</div>
              <input v-model="selectedPackage.profileLogo" class="form-control" type="text" />
            </div>

            <div class="ml-1 nput-item">
              <div class="input-label">developerTeam</div>
              <input v-model="selectedPackage.developerTeam" class="form-control" type="text" />
            </div>
          </div>

          <div class="mt-1 d-flex flex-row">
            <div class="mr-1 input-item">
              <div class="input-label">Description</div>
              <b-form-textarea id="textarea-default" v-model="selectedPackage.description"
                placeholder="Package Description" rows="3" class="char-textarea" />
            </div>
            <div class="d-flex flex-column">
              <div class="input-item ml-1">
                <div class="input-label">Reward</div>
                <input v-model="selectedPackage.reward" class="form-control" required type="number" />
              </div>
              <div v-if="durationVisibility" class="input-item ml-1">
                <div class="input-label">Duration</div>
                <input v-model="selectedPackage.duration" class="form-control" type="number" />
              </div>
            </div>
          </div>

          <div class="mt-1 d-flex flex-row">
            <div class="mr-1 nput-item">
              <div class="input-label">Discount</div>
              <input v-model="selectedPackage.discount" class="form-control" type="text" />
            </div>

            <div class="ml-1 nput-item">
              <div class="input-label">Pure Price</div>
              <div class="form-control" type="text">
                {{ calculateNewPackagePurePrice(selectedPackage) }}
              </div>
            </div>
          </div>

          <div v-if="selectedPackage.categoryType == 'SIGNALS'">
            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">noOfSubscribers</div>
                <input v-model="selectedPackage.noOfSubscribers" class="form-control" type="number" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">profitIndex</div>
                <input v-model="selectedPackage.profitIndex" class="form-control" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">riskIndex</div>
                <input v-model="selectedPackage.riskIndex" class="form-control" type="number" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">riskRewardRatio</div>
                <input v-model="selectedPackage.riskRewardRatio" class="form-control" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">suggestedLeverage</div>
                <input v-model="selectedPackage.suggestedLeverage" class="form-control" type="number" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">formula</div>
                <input v-model="selectedPackage.formula" class="form-control" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">image</div>
                <input v-model="selectedPackage.image" class="form-control" type="text" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">startDate</div>
                <input v-model="selectedPackage.startDate" class="form-control" />
              </div>
            </div>
          </div>

          <div v-if="selectedPackage.categoryType == 'BOTS'">
            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">noOfSubscribers</div>
                <input v-model="selectedPackage.noOfSubscribers" class="form-control" type="number" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">profitIndex</div>
                <input v-model="selectedPackage.profitIndex" class="form-control" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">riskIndex</div>
                <input v-model="selectedPackage.riskIndex" class="form-control" type="number" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">riskRewardRatio</div>
                <input v-model="selectedPackage.riskRewardRatio" class="form-control" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">suggestedLeverage</div>
                <input v-model="selectedPackage.suggestedLeverage" class="form-control" type="number" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">sumProfit</div>
                <input v-model="selectedPackage.sumProfit" class="form-control" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">totalWinRate</div>
                <input v-model="selectedPackage.totalWinRate" class="form-control" type="text" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">botType</div>
                <input v-model="selectedPackage.botType" class="form-control" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">chartLink</div>
                <input v-model="selectedPackage.chartLink" class="form-control" type="text" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">approximate_winrate</div>
                <input v-model="selectedPackage.approximate_winrate" class="form-control" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">Back Test</div>
                <input v-model="selectedPackage.back_test" class="form-control" type="checkbox" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">customization</div>
                <input v-model="selectedPackage.customization" class="form-control" type="checkbox" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">multiTimeFrame</div>
                <input v-model="selectedPackage.multiTimeFrame" class="form-control" type="checkbox" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">showEntryPoint</div>
                <input v-model="selectedPackage.showEntryPoint" class="form-control" type="checkbox" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">showSL</div>
                <input v-model="selectedPackage.showSL" class="form-control" type="checkbox" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">showTP</div>
                <input v-model="selectedPackage.showTP" class="form-control" type="checkbox" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">trading_view_notificastion</div>
                <input v-model="selectedPackage.trading_view_notificastion" class="form-control" type="checkbox" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">financialMarket</div>
                <input v-model="selectedPackage.financialMarket" class="form-control" type="text" />
              </div>

              <div class="ml-1 nput-item">
                <div class="input-label">goodMarket</div>
                <input v-model="selectedPackage.goodMarket" class="form-control" />
              </div>
            </div>

            <div class="mt-1 d-flex flex-row">
              <div class="mr-1 nput-item">
                <div class="input-label">suggestionTimeFrame</div>
                <input v-model="selectedPackage.suggestionTimeFrame" class="form-control" type="text" />
              </div>
            </div>
          </div>

          <div class="mt-1 d-flex flex-row">
            <div v-if="calculateNewPackagePrice(selectedPackage) != calculateNewPackagePurePrice(selectedPackage)">
              <div class="nput-item">
                <div class="input-label">After Discount Price</div>
                <div class="form-control" type="text">
                  {{ calculateNewPackagePrice(selectedPackage) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-1 d-flex flex-row">
          <div class="input-item m-1">
            <div class="input-label">Categories</div>
            <v-select key="name" v-model="selectedPackage.category" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categories" label="name" class="select-type" style="min-width: 250px" @change="changeCategory"
              @input="changeCategory" />
          </div>

          <div>
            <div class="input-label">Services</div>

            <b-form-group>
              <v-select v-model="selectedPackage.services" key="id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="services" label="name" class="select-type" style="min-width: 250px" multiple />
            </b-form-group>
          </div>
        </div>

        <button class="btn btn-primary" type="submit" @click="() => updatePackage()">Update</button>
      </div>
    </b-modal>

    <b-modal id="delete-exchange" v-model="showPackageDeleteModal" size="lg" title="Delete Package" hide-footer centered
      scrollable>
      <div class="d-flex flex-column align-items-center">
        <span>
          Are you sure you want to delete
          <span style="font-size: 20px">{{ selectedPackage.name }}</span>
          ?
        </span>

        <div class="d-flex justify-content-between">
          <button class="btn btn-success mr-4 ml-4" @click="() => deletePackage(selectedPackage.id)">YES</button>
          <button class="btn btn-warning mr-4 ml-4" @click="$bvModal.hide('delete-exchange')">NO</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      fields: ["name", "category.name", "Price", "discount", "duration", "actions"],
      rows: 10,
      deletingfeatureId: 0,
      durationVisibility: false,
      deleteError: "",
      feature: {
        name: 0,
        feature: 0,
      },
      newPackage: {
        description: "",
        isFree: false,
      },
      selectedPackage: {
        name: "",
        description: "",
        discount: 0,
        duration: 1,
        category: { types: [] },
        services: [],
      },
      showAddPackage: false,
      showDetailModal: false,
      addingNewPackage: false,
      showaddPackage: false,
      showDeleteConfirm: false,
      showUpdatefeature: false,
      showPackageDeleteModal: false,
      selectedUnit: "btc",
      packages: [],
      requests: [],
      categories: [],
      originalFeatures: [],
      services: [],
      originalServices: [],
      copyOfFeatures: [],
      featuresCategory: [[]],
    };
  },
  computed: {},
  watch: {
    newPackage: {
      handler(newValue, oldValue) {
        this.filterService();
      },
      deep: true,
    },
    selectedPackage: {
      handler(newValue, oldValue) {
        this.filterService();
      },
      deep: true,
    },
  },
  mounted() {
    this.getCategoriesList();
    this.getServicesList();
    this.getPackages();
  },
  methods: {
    resetPackage() {
      this.selectedPackage = {
        name: "",
        description: "",
        discount: 0,
        duration: 1,
        category: { types: [] },
        services: [],
      };
      this.newPackage = {
        description: "",
        isFree: false,
      };
    },
    calculateNewPackagePurePrice(pack) {
      let price = 0;
      if (pack.services) {
        pack.services.forEach((item) => {
          if (item.totalPrice) {
            price += item.totalPrice;
          }
          if (item.price24) {
            price += item.price24;
          }
        });
        if (pack.duration > 0) {
          price = Number(pack.duration) * price;
        }
        return price;
      }
      if (pack.duration > 0) {
        price = Number(pack.duration) * price;
      }
      return price;
    },
    calculateNewPackagePrice(pack) {
      // return 12;
      let price = 0;
      if (pack.services) {
        pack.services.forEach((item) => {
          if (item.totalPrice) {
            price += item.totalPrice;
          }
          if (item.price24) {
            price += item.price24;
          }
        });
        if (pack.duration > 0) {
          price = Number(pack.duration) * price;
        }
        if (pack.discount > 0) {
          price -= (Number(pack.discount) * Number(price)) / 100;
        }
        return price;
      }
      return price;
    },
    deletePackage(id) {
      this.isBusy = true;
      this.$http.delete(`${this.$store.state.appConfig.base_url}/api/v1/admin/packages/delete/${id}`).then((res) => {
        this.getPackages();
        this.$bvToast.toast("Deleted", {
          title: "Package Deleted",
          variant: "success",
          solid: true,
        });
        this.isBusy = false;
        this.showPackageDeleteModal = false;
      });
    },
    showPackageDelete(service) {
      this.selectedPackage = service;
      this.showPackageDeleteModal = true;
    },
    changeCategory() {
      this.clearService();
    },
    calculateDiscount(data) {
      let price = 0;
      data.services.forEach((item) => {
        if (item.totalPrice) {
          price += item.totalPrice;
        }
        if (item.price24) {
          price += item.price24;
        }
      });
      if (data.duration > 0) {
        price = Number(data.duration) * price;
      }
      if (data.discount > 0) {
        price -= (Number(data.discount) * Number(price)) / 100;
      }
      return `${data.discount}% - ${price}`;
    },
    calculatePrice(data) {
      let price = 0;
      data.services.forEach((item) => {
        if (item.totalPrice) {
          price += item.totalPrice;
        }
        if (item.price24) {
          price += item.price24;
        }
      });
      if (data.duration > 0) {
        price *= Number(data.duration);
      }
      return price;
    },
    updatePackage() {
      this.selectedPackage.duration = Number(this.selectedPackage.duration);
      this.selectedPackage.discount = Number(this.selectedPackage.discount);
      this.selectedPackage.reward = Number(this.selectedPackage.reward);
      this.selectedPackage.category = this.selectedPackage.category.id;
      this.selectedPackage.services = this.selectedPackage.services.map((item) => item.id);
      this.$http
        .patch(
          `${this.$store.state.appConfig.base_url}/api/v1/admin/packages/update/${this.selectedPackage.id}`,
          this.selectedPackage
        )
        .then((res) => {
          this.$bvToast.toast("Updated", {
            title: "Package Updated",
            variant: "success",
            solid: true,
          });
          this.showDetailModal = false;
          this.getPackages();
        });
    },
    showPackageDetail(data) {
      this.selectedPackage = data;
      this.showDetailModal = true;
      if (this.selectedPackage.categoryType == "SIGNALS" || this.selectedPackage.categoryType == "COPY_TRADING" || this.selectedPackage.categoryType == "ALARM") {
        this.durationVisibility = false;
      } else {
        this.durationVisibility = true;
      }
      this.services = this.selectedPackage.services.concat(this.originalServices);

      this.services = this.services.filter(
        (item) => this.selectedPackage.category.types.findIndex((i) => i == item.type) > -1
      );
    },
    getCategoriesList() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageCategory`).then((res) => {
        this.categories = res.data.data;
        this.categories.map((item) => {
          delete item._id;
        });
        this.isBusy = false;
      });
    },
    createPackage() {
      this.newPackage.discount = Number(this.newPackage.discount);
      this.newPackage.duration = Number(this.newPackage.duration);
      this.newPackage.reward = Number(this.newPackage.reward);
      this.newPackage.category = this.newPackage.category.id;
      this.newPackage.services = this.newPackage.services.map((item) => item.id);

      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/admin/packages/create`, this.newPackage)
        .then((res) => {
          this.$bvToast.toast(`${res.data.data.name}  Created`, {
            title: "Package Created",
            variant: "success",
            solid: true,
          });
          this.showAddPackage = false;
          this.getPackages();
        });
    },
    getPackages() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packages`).then((res) => {
        this.packages = res.data.data;
        this.isBusy = false;
      });
    },
    getServicesList() {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packageServices`).then((res) => {
        this.originalServices = res.data.data;
        this.originalServices.map((item) => {
          delete item._id;
        });
        this.isBusy = false;
      });
    },
    getFeatureNameByEnum(item) {
      for (let index = 0; index < this.originalFeatures.length; index++) {
        const element = this.originalFeatures[index];
        if (element.type == item) {
          return element.name;
        }
      }
    },
    clearService() {
      if (this.newPackage.services && this.newPackage.services.length > 0) {
        this.newPackage.services = this.newPackage.services.filter(
          (item) => this.newPackage.category.types.findIndex((i) => i == item.type) > -1
        );
      }
      if (this.selectedPackage.services) {
        this.selectedPackage.services = this.selectedPackage.services.filter(
          (item) => this.selectedPackage.category.types.findIndex((i) => i == item.type) > -1
        );
      }
      // this.filterService()
    },
    filterService() {
      if (
        (this.newPackage &&
          this.newPackage.category &&
          this.newPackage.category.types &&
          this.newPackage.category.types.includes("AiSignalService")) ||
        this.newPackage.category.types.includes("LandcruiserSignalService") ||
        this.newPackage.category.types.includes("CopyTrading") ||
        this.newPackage.category.types.includes("AlarmService") ||
        this.newPackage.category.types.includes("OptimumSignalService")
      ) {
        this.durationVisibility = false;
      } else {
        this.durationVisibility = true;
      }
      if (this.newPackage && this.newPackage.category && this.newPackage.category.types) {
        this.services = this.originalServices.filter(
          (item) => this.newPackage.category.types.findIndex((i) => i == item.type) > -1
        );
      } else if (this.selectedPackage && this.selectedPackage.category) {
        this.services = this.originalServices.filter(
          (item) => this.selectedPackage.category.types.findIndex((i) => i == item.type) > -1
        );
      }
    },

    showaddPackageModal() {
      this.resetPackage();
      this.showAddPackage = true;
    },
    showUpdatefeatureModal(seletpackage, confirm) {
      this.addingNewPackage = false;

      this.selectedPackage = JSON.parse(JSON.stringify(seletpackage));
      let activeFeatures = [];
      this.getCopyOfFeatures().forEach((item) => {
        this.selectedPackage.features.forEach((feature) => {
          if (feature == item.type) {
            item.selected = true;
          }
          activeFeatures.push(item);
        });
      });
      activeFeatures = [...new Set(activeFeatures)];
      this.selectedPackage.features = activeFeatures;

      this.showUpdatefeature = true;
    },

    getCopyOfFeatures() {
      return JSON.parse(JSON.stringify(this.originalFeatures));
    },
  },
};
</script>

<style></style>
