export default [
  {
    path: "/token-managements/index",
    name: "token-managements-index",
    meta: {
      action: "read",
      resource: "tokenmanagements",
    },
    component: () => import("@/views/pages/token-managements"),
  },
  {
    path: "/token-managements/rmzr",
    name: "token-managements-rmzr",
    component: () => import("@/views/pages/token-managements/value-of-RMZr"),
  },

  {
    path: "/token-managements/withdrawl-requests",
    name: "token-managements-withdrawl-requests",
    component: () => import("@/views/pages/token-managements/withdrawl-requests"),
  },
  {
    path: "/token-managements/withdrawls",
    name: "token-managements-withdrawls",
    component: () => import("@/views/pages/token-managements/withdrawls"),
  },

  {
    path: "/token-managements/token-distribution",
    name: "token-managements-token-distribution",
    component: () => import("@/views/pages/token-managements/token-distribution"),
  },

  {
    path: "/token-managements/transaction-fees",
    name: "token-managements-transaction-fees",
    component: () => import("@/views/pages/token-managements/transaction-fees"),
  },

  {
    path: "/token-managements/srrm",
    name: "token-managements-srrm",
    component: () => import("@/views/pages/token-managements/srrm"),
  },
];
