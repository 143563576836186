<template>
  <div>
    <div v-if="isLoading">
      <loading />
    </div>
    <div v-else>
      <b-form>
        <b-row>
          <b-col class="col-12" v-for="item in values" :key="item.id">
            <b-form-group :label="item.type" label-for="sign-up-id" label-cols-md="2">
              <b-row>
                <b-form-input v-model="item.amount" type="number" placeholder="Value" class="mr-4 ml-4 col-4">
                </b-form-input>
                <b-dropdown variant="primary" id="dropdown-1" :text="item.unit.toUpperCase()" class="mr-4 ml-4">
                  <b-dropdown-item @click="() => (item.unit = 'RMZ')">RMZ</b-dropdown-item>
                  <b-dropdown-item @click="() => (item.unit = 'RMZR')">RMZR</b-dropdown-item>
                </b-dropdown>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn btn-success pr-3 pl-3"
        @click="() => updateDistrobution()"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import loading from "./../../../components/loading";

export default {
  components: { loading },
  data() {
    return {
      isLoading: false,
      values: [
        {
          type: 0,
          amount: "RMZ",
          unit: 0,
        },
      ],
    };
  },
  methods: {
    changeTokenUnit(key, value) {
      switch (key) {
        case "signup":
          this.values.signup_token_unit = value;
          break;
        case "mobile_verify":
          this.values.mobile_verify_token_unit = value;
          break;
        case "direct_ref":
          this.values.direct_ref_token_unit = value;
          break;
        case "indirect_ref":
          this.values.indirect_ref_token_unit = value;
          break;

        default:
          break;
      }
    },
    updateDistrobution() {
      this.isLoading = true;
      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/token-managements`, this.values)
        .then((res) => {
          this.values = res.data.data;
          this.isLoading = false;
          console.log(res);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "InfoIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    getDistribution() {
      this.isLoading = true;

      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/token-distribution`)
        .then((res) => {
          this.values = res.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getDistribution();
  },
};
</script>

<style></style>
