<template>
  <div>
    <b-card>
      <div v-if="isLoading == false">
        <div>
          <b-table striped :items="botsList" :fields="fields" :busy="isBusy">
            <template #cell(isApprovedByAdmin)="isApprovedByAdmin">
              <!-- <div v-if="isApprovedByAdmin.item.remainingDuration > 0"> -->
              <button
                v-if="isApprovedByAdmin.item.isApprovedByAdmin"
                class="btn btn-success"
                @click="() => toggleActivation(isApprovedByAdmin.item)"
              >
                Active
              </button>

              <button v-else class="btn btn-danger" @click="() => toggleActivation(isApprovedByAdmin.item)">
                Inactive
              </button>
              <!-- </div> -->

              <!-- <div v-else></div> -->
            </template>

            <template #cell(remainingDuration)="remainingDuration">
              <b-alert v-if="remainingDuration.item.remainingDuration > 0" class="mb-0" show variant="success">
                <div class="alert-body">
                  Active<span>-Remaining {{ remainingDuration.item.remainingDuration }} days</span>
                </div>
              </b-alert>
              <b-alert v-else class="mb-0" show variant="warning">
                <div class="alert-body">Expired</div>
              </b-alert>
            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(purchaseDate)="purchaseDate">
              <div class="text-center text-danger my-2">
                {{ getDate(purchaseDate.item.purchaseDate) }}
              </div>
            </template>
          </b-table>
        </div>
        <b-col cols="4">
          <b-pagination v-model="page" :per-page="exchangePerPage" hide-goto-end-buttons :total-rows="packagesCount" />
          <span>{{ packagesCount }} Count</span>
        </b-col>
      </div>
      <div v-else>
        <b-card>
          <loading />
        </b-card>
      </div>
    </b-card>
  </div>
</template>
<script>
import loading from "./../../components/loading";

export default {
  name: "Exchanges",
  components: { loading },
  data() {
    return {
      isLoading: true,
      selectedExchange: {},
      switchGroup: "",
      exchangeGoingToDelete: "",
      isBusy: false,
      showAddExchange: false,
      showDeleteExchange: false,
      page: 1,
      packagesCount: 0,
      exchangePerPage: 12,
      idFetchingData: false,
      botsList: [],
      fields: [
        {
          key: "name",
          label: "name",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
        {
          key: "purchaseDate",
          label: "purchaseDate",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
        {
          key: "isApprovedByAdmin",
          label: "isApprovedByAdmin",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
        {
          key: "remainingDuration",
          label: "remainingDuration",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
        },
        {
          key: "tradingViewId",
          label: "tradingViewId",
        },
      ],
    };
  },
  mounted() {
    this.getbotsList();
  },
  methods: {
    getDate(date) {
      // eslint-disable-next-line no-param-reassign
      date = new Date(date);
      return `${date.getDay()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    showDeleteWxchangeModal(data) {
      this.showDeleteExchange = true;
      this.exchangeGoingToDelete = data;
    },
    showExchangeDetails(data) {
      this.showAddExchange = true;
      this.selectedExchange = data;
    },
    deleteExchange(data) {
      console.log(this.exchangeGoingToDelete);
      this.isBusy = true;

      this.$http
        .delete(`${this.$store.state.appConfig.base_url}/api/v1/exchanges/deleteExchange/${data.id}`)
        .then((res) => {
          console.log(res);
          this.isBusy = false;

          this.getbotsList();
          this.showDeleteExchange = false;
        });
    },

    toggleActivation(exchange) {
      this.$http
        .patch(
          `${this.$store.state.appConfig.base_url}/api/v1/admin/packages/bots/${exchange.id}/${exchange.serviceId}`
        )
        .then((res) => {
          console.log(res);
          this.getbotsList();
        });
    },
    getbotsList() {
      this.isLoading = true;
      const params = {
        page: this.page,
        limit: this.exchangePerPage,
      };
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/admin/packages/bots`, {
          params,
        })
        .then((res) => {
          this.botsList = res.data.data.packages;
          // this.botsList = this.botsList.map(item => {
          //   item.status = 0;
          //   if (item.isApprovedByAdmin == false) {
          //     item.status = 0;
          //   }

          //   if (item.isApprovedByAdmin == true) {
          //     item.status = 1;
          //   }
          //   if (item.isActive == false) {
          //     item.status = 2;
          //   }

          //   return item;
          // })
          this.packagesCount = res.data.data.count;
          console.log(res);
          this.isBusy = false;
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    page: function () {
      this.getbotsList();
    },
  },
};
</script>
