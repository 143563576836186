<template>
  <div>
    <users-list-filters :tier-options="tiers" @search="search" />
    <div class="mb-3 d-flex align-items-center">
      <b-button variant="primary" @click="createNewUser"> Add New User </b-button>
      <!-- <b-badge variant="primary" class="mr-4 ml-4">
        <span>User Counts : </span>
        <span>{{ usersCount }}</span>
      </b-badge> -->
    </div>
    <div>
      <div v-if="isLoading">
        <b-card>
          <loading />
        </b-card>
      </div>
      <b-card v-else>
        <b-table responsive @sort-changed="sortingChanged" id="users-table" striped :items="users" :fields="fields">
          <template #cell(index)="data">
            {{ getItemIndex(data.index + 1) }}
            <!-- {{ usersPerPage * currentPage * data.index + 1 }} -->
          </template>
          <template #cell(details)="data">
            <button class="btn btn-sm text-muted" @click="showUserDetails(data.item.id)">
              <feather-icon icon="EyeIcon" size="24" />
            </button>
          </template>

          <template #cell(register_date)="data">
            <!-- {{ data.item.register_date }} -->
            {{ showRegisteredDate(data.item.register_date) }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'user-profile', params: { id: data.item.id } }">
                <!-- <b-dropdown-item @click="showUserDetails(data.item.id)"> -->
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{
                  name: 'create-referrals',
                  params: {
                    id: data.item.id,
                    username: data.item.username,
                    email: data.item.email,
                    full_name: data.item.name,
                  },
                }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Create Referral</span>
              </b-dropdown-item>

              <!-- 
            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item> -->

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-row class="m-2">
          <b-col cols="3" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="usersPerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="4">
            <b-pagination v-model="currentPage" hide-goto-end-buttons :total-rows="usersCount" />
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-modal v-model="showAddNewUserModal" size="lg" hide-footer title="User Details" centered scrollable>
      <div>
        <div>
          <AddNewUser @cancel="showAddNewUserModal = false" @updated="() => getUserDetails(userData.id)" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UsersListFilters from "./components/UsersListFilters.vue";
import loading from "./../../components/loading";

import vSelect from "vue-select";
import AddNewUser from "./components/AddNewUserModal.vue";

export default {
  name: "UsersList",
  components: { UsersListFilters, vSelect, loading, AddNewUser },
  data() {
    return {
      fields: [
        "index",
        // A column that needs custom formatting
        { key: "name", sortable: true },
        // A regular column
        { key: "username", sortable: true },
        { key: "total_rmz", sortable: true },
        { key: "total_rmzr", sortable: true },
        { key: "total_rmzr", sortable: true },
        { key: "register_date", sortable: true },
        // A regular column
        "email",
        // { key: "register_date", sortable: true },
        // "suspend",
        // "details",
        "actions",
      ],
      users: [],
      searchObject: {},
      usersPerPage: 10,
      usersCount: 0,
      currentPage: 1,
      isFetching: false,
      isLoading: true,
      currentUser: null,
      showAddNewUserModal: false,
      isTogglingSuspend: {},
      searchText: "",
      tiers: [
        {
          name: "All Roles",
          id: null,
        },
      ],
      perPageOptions: [10, 25, 50, 100],
      suspendList: [null, false, true],
      selectedTier: null,
      selectedTier1: null,
      suspended: null,
      sortBy: "register_date",
      sort: "DESC",
    };
  },

  methods: {
    showRegisteredDate(input) {
      const date = new Date(input);

      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    sortingChanged(ctx) {
      // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
      // ctx.sortDesc ==> true if sorting descending, false otherwise
      console.log("ctx.sortBy", ctx.sortBy);
      console.log("ctx.sortDesc", ctx.sortDesc);
      if (ctx.sortDesc) {
        this.sort = "DESC";
      } else {
        this.sort = "ASC";
      }
      if (ctx.sortBy) {
        this.sortBy = ctx.sortBy;
      }
      this.getUsers();
    },
    getItemIndex(index) {
      if (this.currentPage == 1) {
        return index;
      } else {
        return this.usersPerPage * (this.currentPage - 1) + index;
      }
    },
    linkGen(pageNum) {
      console.log(pageNum);
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    search(value) {
      this.searchObject = value;
      this.getUsers(value);
    },
    getUsers(searchObject) {
      this.isLoading = true;

      const params = {
        page: this.currentPage,
        limit: this.usersPerPage,
        sortBy: this.sortBy,
        sort: this.sort,
        hasReferral: undefined,
      };

      if (searchObject) {
        if (searchObject.admin) params.admin = Number(searchObject.admin.id);
        if (searchObject.ex && searchObject.ex.id != 0) params.ex_id = searchObject.ex.id;
        if (searchObject.numberOfExchange != 0) params.numberOfExchange = searchObject.numberOfExchange;
        // if (searchObject.suspend != undefined && searchObject.suspend != null) params.suspend = searchObject.suspend.value;
        if (searchObject.searchText != "") params.q = searchObject.searchText;
        if (searchObject.server != "") params.server = searchObject.server;
        if (searchObject.fromDate != "") params.fromDate = searchObject.fromDate;
        if (searchObject.toDate != "") params.toDate = searchObject.toDate;
        if (searchObject.numOfReff != 0) params.numOfReff = searchObject.numOfReff;
        if (searchObject.package != undefined && searchObject.package.id != undefined)
          params.packageId = searchObject.package.id;
        if (searchObject.hasReferral != undefined) {
          if (searchObject.hasReferral == "has") {
            params.hasReferral = true;
          } else {
            params.hasReferral = false;
          }
        } else {
          params.hasReferral = undefined;
        }

        if (searchObject.suspend != undefined && searchObject.suspend.value != undefined) {
          params.suspend = searchObject.suspend.value;
        } else {
          params.suspend = undefined;
        }
      }

      if (this.searchText && this.searchText.trim()) this.searchText.trim();
      this.isFetching = true;
      return this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/admin/users`, { params })
        .then(({ data }) => {
          this.usersCount = data.data.count;
          this.users = data.data.userList;
          this.isLoading = false;

          return this.users;
        })
        .finally(() => {
          this.isLoading = false;

          this.isFetching = false;
        });
    },
    getUserDetails(id) {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/admin/users/getUser/${id}`)
        .then(({ data }) => {
          this.currentUser = data.data;
        })
        .finally(() => {});
    },

    showUserDetails(id) {
      this.currentUser = null;
      this.getUserDetails(id);
      this.showUserDetailModal = true;
    },
    toggleSuspended(user) {
      if (this.isTogglingSuspend[user.id]) return;

      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to ${user.suspend ? "unsuspend" : "suspend"} "${user.username}"?`, {
          title: `Confirm ${user.suspend ? "Unsuspend" : "suspend"}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: user.suspend ? "success" : "danger",
          okTitle: user.suspend ? "Unsuspend" : "Suspend",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (!value) return;
          const urlParam = user.suspend ? "unSuspend" : "suspend";
          this.$set(this.isTogglingSuspend, user.id, true);
          this.$http
            .post(`${this.$store.state.appConfig.base_url}/api/v1/admin/users/${urlParam}/${user.id}`)
            .then(() => {
              this.$root.$emit("bv::refresh::table", "users-table");
              let userIndex = this.users.findIndex((u) => u.id === user.id);
              this.users.splice(userIndex, 1, { ...this.users[userIndex], suspend: !user.suspend });
            })
            .finally(() => {
              this.$set(this.isTogglingSuspend, user.id, false);
            });
        });
    },
    async getTiers() {
      console.log("get tiers");
      await this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/admin/roles`).then(({ data }) => {
        this.tiers.push(...data.data);
      });
    },
    showConfirm() {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to suspend #@$@?`, {
          title: "Confirm Suspend",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Suspend",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {})
        .catch((err) => {
          // An error occurred
        });
    },
    createNewUser() {
      this.showAddNewUserModal = true;
    },
  },
  mounted() {
    this.getTiers();
    this.getUsers();
  },
  watch: {
    currentPage: function () {
      this.getUsers(this.searchObject);
    },
    usersPerPage: function () {
      this.getUsers(this.searchObject);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
