export default [
  {
    title: "Referrals",
    icon: "LayersIcon",
    tagVariant: "light-warning",
    children: [
      {
        title: "Referrals",
        route: "referral",
        resource: "referral",
        action: "read",
      },
      // {
      //   title: "Create",
      //   route: "create-referral",
      //   resource: "referral",
      //   action: "create",
      // },
      {
        title: "Logs",
        route: "referral-logs",
        resource: "referral",
        action: "read",
      },
    ],
  },
];
