<template>
  <div>
    <div class="justify-content-center row input-group">
      <b-card class="m-1 col-md-4 p-1">
        <div>
          <div class="input-item m-1">
            <div class="input-label">UserId</div>
            <input class="form-control" disabled required type="number" v-model="referral.user_id" />
          </div>
          <div class="input-item m-1">
            <div class="input-label">User Name</div>
            <input class="form-control" disabled required type="text" v-model="referral.username" />
          </div>

          <div class="input-item m-1">
            <div class="input-label">Email</div>
            <input class="form-control" disabled required type="text" v-model="referral.email" />
          </div>

          <div class="input-item m-1">
            <div class="input-label">Full Name</div>
            <input class="form-control" disabled required type="text" v-model="referral.full_name" />
          </div>

          <div class="input-item m-1">
            <div class="input-label">Referral Seasonal</div>
            <input
              class="form-control"
              style="width: 20px; height: 20px"
              type="checkbox"
              v-model="referral.is_referral_seasonal"
            />
          </div>
          <div class="input-item m-1">
            <div class="input-label">Referral Contract</div>

            <input
              class="form-control"
              style="width: 20px; height: 20px"
              type="checkbox"
              v-model="referral.is_referral_contract"
            />
          </div>
        </div>
      </b-card>

      <div v-if="referral.is_referral_contract" class="col-md-6 m-1 p-1 card contract-container">
        <h2>Referral Contract</h2>
        <div class="input-item m-1">
          <div class="input-label">Contract Time</div>
          <input class="form-control" required type="number" v-model="referral.referral_contract_data.contract_time" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Start Contract Time</div>
          <flat-pickr
            v-model="referral.referral_contract_data.contract_start_time"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
          />
        </div>

        <div class="input-item m-1" v-if="referral.referral_contract_code != undefined">
          <div class="input-label">Code</div>
          <input class="form-control" required type="string" v-model="referral.referral_contract_code" />
        </div>

        <div class="input-item m-1" v-if="referral.referral_code_contract != undefined">
          <div class="input-label">Code</div>
          <input class="form-control" required type="string" v-model="referral.referral_code_contract" />
        </div>

        <div
          class="input-item m-1"
          v-if="referral.referral_code_contract == undefined && referral.referral_contract_code == undefined"
        >
          <div class="input-label">Code</div>
          <input class="form-control" required type="string" v-model="referral.referral_contract_code" />
        </div>

        <b-card>
          <div
            class="contract-level"
            v-for="(level, index) in referral.referral_contract_data.referral_level"
            :key="level.level"
          >
            <div class="input-item m-1">
              <div class="input-label">level</div>

              <input class="form-control" required disabled type="number" :value="getLevel(index)" />
            </div>
            <div class="input-item m-1">
              <div class="input-label">Contract Time</div>
              <input
                v-if="level.contract_time"
                class="form-control"
                disabled
                type="number"
                v-model="level.contract_time"
              />
              <input
                v-if="referral.referral_contract_data.contract_time"
                class="form-control"
                disabled
                type="number"
                v-model="referral.referral_contract_data.contract_time"
              />
            </div>

            <div class="input-item m-1">
              <div class="input-label">Start Time</div>
              <input
                v-if="level.contract_start_time"
                class="form-control"
                disabled
                :value="timestampToDate(level.contract_start_time)"
              />
              <input
                v-else
                class="form-control"
                disabled
                :value="referral.referral_contract_data.contract_start_time"
              />
            </div>
            <div class="input-item m-1">
              <div class="input-label">End Time</div>
              <input
                v-if="level.contract_end_time"
                class="form-control"
                disabled
                :value="timestampToDate(level.contract_end_time)"
              />
              <input v-else class="form-control" disabled :value="referral.referral_contract_data.contract_end_time" />
            </div>

            <b-tabs content-class=" pt-1" fill>
              <b-tab title="Contract">
                <contract :level="level" />
              </b-tab>
              <b-tab title="Settings">
                <settings :setting="level.setting" />
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
        <button @click="() => addReferralContractLevel()" class="btn btn-primary" type="submit">Add</button>
      </div>

      <b-card v-if="referral.is_referral_seasonal" class="m-1 col-md-6 p-1">
        <h2>Referral Seasonal</h2>
      </b-card>
    </div>
    <div class="d-flex justify-content-around">
      <span class="input-group-btn">
        <button v-if="referral.id == 0" @click="() => addNewReferral()" class="btn btn-primary" type="submit">
          Submit
        </button>
        <button v-else @click="() => updatereferral()" class="btn btn-primary" type="submit">Update</button>
      </span>
      <span class="input-group-btn">
        <button @click="() => closeModal()" class="btn btn-primary" type="submit">Cancel</button>
      </span>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import contract from "./contract.vue";
import settings from "./settings.vue";
import { BTabs, BTab } from "bootstrap-vue";

export default {
  components: { BTab, BTabs, flatPickr, contract, settings },
  data() {
    return {
      errors: [],
      referral: {
        id: 0,
        user_id: 0,
        username: "",
        email: "",
        full_name: "",
        enable_referrals: false,
        is_referral_seasonal: false,
        referral_seasonal_data: { contract_time: 0, contract_start_time: 0 },
        is_referral_contract: false,
        referral_contract_data: {
          contract_time: 0,
          contract_start_time: 0,
          referral_level: [
            {
              level: 0,
              contract: {
                percent: 0,
                is_descending_slope: true,
                descending_slope_percentage: [0],
                contract_time: 0,
                contract_start_time: 0,
              },
              setting: {
                is_share_percent_for_levels: true,
                is_descending_slope: true,
                share_percent_for_levels: [0],
                descending_slope_percentage: [0],
              },
            },
          ],
        },
      },
    };
  },

  methods: {
    getLevel(index) {
      return index + 1;
    },
    timestampToDate(time) {
      try {
        if (time.length < 11) {
          time = time * 1000;
        }
        const d = new Date(time).toDateString();
        console.log({ d });
        return d;
      } catch (error) {
        console.log("tiiiiiiiime", error);
        const d = new Date(time).toDateString();
        console.log({ d });
        return d;
      }
    },
    resetPage() {
      this.referral = {
        id: 0,
        user_id: 0,
        username: "",
        email: "",
        full_name: "",
        enable_referrals: false,
        is_referral_seasonal: false,
        referral_seasonal_data: { contract_time: 0, contract_start_time: 0 },
        is_referral_contract: false,
        referral_contract_data: {
          contract_time: 0,
          contract_start_time: 0,
          referral_level: [],
        },
      };
    },
    addReferralContractLevel() {
      this.referral.referral_contract_data.referral_level.push({
        level: 0,
        setting: {
          share_percent_for_levels: [0],
          is_descending_slope: true,
          is_share_percent_for_levels: true,
          descending_slope_percentage: [0],
          share_percent_for_levels: new Array(this.referral.referral_contract_data.referral_level.length + 1).fill(0),
        },
        contract: { percent: 0, is_descending_slope: true, descending_slope_percentage: [0] },
      });
    },
    closeModal() {
      this.$emit("cancel");
    },
    updatereferral() {
      // const userId = this.$route.query.id;
      this.referral.user_id = Number(this.referral.user_id);
      this.referral.referral_contract_data.contract_time = Number(this.referral.referral_contract_data.contract_time);
      this.referral.referral_contract_data.contract_start_time =
        new Date(this.referral.referral_contract_data.contract_start_time).getTime() / 1000;

      this.referral.referral_contract_data.referral_level.map((item, index) => {
        item.level = Number(index + 1);

        item.contract.descending_slope_percentage = item.contract.descending_slope_percentage.map((i) => +i);
        item.setting.descending_slope_percentage = item.setting.descending_slope_percentage.map((i) => +i);
        item.setting.share_percent_for_levels = item.setting.share_percent_for_levels.map((i) => +i);
        item.setting.percent = +item.setting.percent;

        item.contract.percent = Number(item.contract.percent);
        return item;
      });

      const payload = JSON.parse(JSON.stringify(this.referral));

      this.$http
        .put(
          `${this.$store.state.appConfig.base_url}/api/v1/users/referrals/admin/contract/${this.referral.user_id}`,
          payload
        )
        .then((res) => {
          console.log(res.data.data.username);
          this.$bvToast.toast(`${res.data.data.username} referral's Updated`, {
            title: `Referral Created`,
            variant: "success",
            solid: true,
          });
          this.$emit("cancel");
        });
    },
    addNewReferral() {
      this.referral.user_id = Number(this.referral.user_id);
      this.referral.referral_contract_data.contract_time = Number(this.referral.referral_contract_data.contract_time);
      this.referral.referral_contract_data.contract_start_time =
        new Date(this.referral.referral_contract_data.contract_start_time).getTime() / 1000;

      this.referral.referral_contract_data.referral_level.map((item, index) => {
        item.level = Number(index + 1);

        item.contract_end_time =
          this.referral.referral_contract_data.contract_start_time +
          this.referral.referral_contract_data.contract_time * 86400;
        item.contract_start_time = this.referral.referral_contract_data.contract_start_time;

        item.contract.descending_slope_percentage = item.contract.descending_slope_percentage.map((i) => +i);
        item.setting.descending_slope_percentage = item.setting.descending_slope_percentage.map((i) => +i);
        item.setting.share_percent_for_levels = item.setting.share_percent_for_levels.map((i) => +i);
        item.setting.percent = +item.setting.percent;

        item.contract.percent = Number(item.contract.percent);
        return item;
      });

      const payload = JSON.parse(JSON.stringify(this.referral));
      console.log({ payload });

      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/users/referrals/admin/contract`, payload)
        .then((res) => {
          console.log(res.data.data.username);
          this.$bvToast.toast(`${res.data.data.username} referral's Created`, {
            title: `Referral Created`,
            variant: "success",
            solid: true,
          });
          this.$emit("cancel");
        });
    },
    getReferral(rId) {
      this.$http.get(`${this.$store.state.appConfig.base_url}/api/v1/users/referrals/admin/${rId}`).then((res) => {
        console.log(res.data.data);
        this.referral = res.data.data;
        this.referral.referral_contract_data.contract_start_time =
          this.referral.referral_contract_data.contract_start_time * 1000;
      });
    },
  },
  mounted() {
    const userId = this.$route.params.id;
    const username = this.$route.params.username;
    const email = this.$route.params.email;
    const full_name = this.$route.params.full_name;
    if (userId) {
      this.referral.user_id = userId;
      this.referral.username = username;
      this.referral.email = email;
      this.referral.full_name = full_name;
    }

    if (userId != 0) {
      this.getReferral(userId);
    }
    // this.getUserInfo(userId);
  },
  watch: {
    referral: {
      handler(newValue, oldValue) {
        for (let index = 0; index < newValue.referral_contract_data.referral_level.length; index++) {
          const element = newValue.referral_contract_data.referral_level[index];
          element.contract.descending_slope_percentage[0] = +element.contract.percent;
          for (let indexsp = 0; indexsp < element.contract.descending_slope_percentage.length; indexsp++) {
            const elementsp = element.contract.descending_slope_percentage[indexsp];
            if (
              element.contract.descending_slope_percentage[indexsp + 1] &&
              +element.contract.descending_slope_percentage[indexsp + 1] >
                +element.contract.descending_slope_percentage[indexsp]
            ) {
              element.contract.descending_slope_percentage[indexsp + 1] =
                element.contract.descending_slope_percentage[indexsp] - 1;
              if (element.contract.descending_slope_percentage[indexsp] < 0) {
                element.contract.descending_slope_percentage[indexsp] = 0;
              }
              if (element.contract.descending_slope_percentage[indexsp + 1] < 0) {
                element.contract.descending_slope_percentage[indexsp + 1] = 0;
              }
            }
          }

          for (let indexsp = 0; indexsp < element.setting.descending_slope_percentage.length; indexsp++) {
            if (
              element.setting.descending_slope_percentage[indexsp + 1] &&
              +element.setting.descending_slope_percentage[indexsp + 1] >
                +element.setting.descending_slope_percentage[indexsp]
            ) {
              element.setting.descending_slope_percentage[indexsp + 1] =
                element.setting.descending_slope_percentage[indexsp] - 1;
              if (element.setting.descending_slope_percentage[indexsp] < 0) {
                element.setting.descending_slope_percentage[indexsp] = 0;
              }
              if (element.setting.descending_slope_percentage[indexsp + 1] < 0) {
                element.setting.descending_slope_percentage[indexsp + 1] = 0;
              }
            }
          }

          if (element.setting.percent > 0) {
            element.setting.descending_slope_percentage[0] = element.setting.percent;
          }
          let share = 0;
          for (let indexss = 0; indexss < element.setting.share_percent_for_levels.length; indexss++) {
            const item = element.setting.share_percent_for_levels[indexss];

            if (+item > 100) {
              element.setting.share_percent_for_levels[indexss] = 99;
            }
            share = +item + share;
          }

          // for (let index = 0; index < newValue.referral_contract_data.referral_level.length; index++) {
          //   if (share > 100) {
          //     element.setting.share_percent_for_levels[element.setting.share_percent_for_levels.length - 1 + index] = 0;
          //     share = 0;
          //     for (let index = 0; index < element.setting.share_percent_for_levels.length; index++) {
          //       share = +item + share
          //     }

          //   }
          //   console.log({ share })
          // }

          // if (share > 100) {
          //   element.setting.share_percent_for_levels[element.setting.share_percent_for_levels.length - 1] = 0;
          //   share = 0;
          //   for (let index = 0; index < element.setting.share_percent_for_levels.length; index++) {
          //     share = +item + share
          //   }

          // }
          // if (share > 100) {
          //   element.setting.share_percent_for_levels[element.setting.share_percent_for_levels.length - 2] = 0;
          //   share = 0;
          //   for (let index = 0; index < element.setting.share_percent_for_levels.length; index++) {
          //     share = +item + share
          //   }

          // }
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.contract-level {
  background: rgba(128, 128, 128, 0.24);
  padding: 5px;
  border-radius: 10px;
  margin: 5px 5px;
}

.contract-container {
  height: 95vh;
  overflow: scroll;
}
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
