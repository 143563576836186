<template>
  <div>
    <div class="row input-group">
      <div class="col-md-4">
        <h5>Referral</h5>

        <div class="input-item m-1">
          <div class="input-label">UserId</div>
          <input class="form-control" required type="number" v-model="referral.user_id" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">is_referral_seasonal</div>
          <input
            class="form-control"
            style="width: 20px; height: 20px"
            type="checkbox"
            v-model="referral.is_referral_seasonal"
          />
        </div>
        <div class="input-item m-1">
          <div class="input-label">is_referral_contract</div>

          <input
            class="form-control"
            style="width: 20px; height: 20px"
            type="checkbox"
            v-model="referral.is_referral_contract"
          />
        </div>
      </div>

      <div class="col-md-4">
        <h5>Referral Contract Data</h5>
        <div class="input-item m-1">
          <div class="input-label">Share percent for users</div>
          <input
            class="form-control"
            required
            type="number"
            v-model="referral.referral_contract_data.share_percent_for_users"
          />
        </div>
        <div class="input-item m-1">
          <div class="input-label">is_descending_slope</div>

          <input
            class="form-control"
            style="width: 20px; height: 20px"
            type="checkbox"
            v-model="referral.referral_contract_data.is_descending_slope"
          />
        </div>

        <div class="input-item m-1" v-if="referral.referral_contract_data.is_descending_slope">
          <div class="input-label">Descending Slope Percentage</div>
          <input
            class="form-control"
            required
            type="text"
            v-model="referral.referral_contract_data.descending_slope_percentage"
          />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Contract Time</div>
          {{ referral_contract_contract_time }}
          <input class="form-control" required type="date" v-model="referral_contract_contract_time" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Contract Percent</div>
          <input
            class="form-control"
            required
            type="number"
            max="100"
            v-model="referral.referral_contract_data.contract_percent"
          />
        </div>
      </div>
      <div class="col-md-4">
        <h5>Referral Seasonal Data</h5>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <span class="input-group-btn">
        <button v-if="referralProp" @click="() => updatereferral()" class="btn btn-primary" type="submit">
          Update
        </button>
        <button v-else @click="() => addNewReferral()" class="btn btn-primary" type="submit">Submit</button>
      </span>
      <span class="input-group-btn">
        <button @click="() => closeModall()" class="btn btn-primary" type="submit">Cancel</button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errors: [],
      referral_contract_contract_time: 0,
      referral: {
        user_id: 0,
        is_referral_seasonal: true,
        referral_seasonal_data: {
          contract_time: 0,
        },
        is_referral_contract: true,
        referral_contract_data: {
          contract_time: 0,
          contract_percent: 0,
          is_descending_slope: true,
          descending_slope_percentage: [0],
          is_share_percent_for_users: true,
          share_percent_for_users: 0,
        },
      },
    };
  },
  props: {
    referralProp: {},
  },
  created() {
    console.log("referralProp", referralProp);
    if (this.referralProp.user_id) {
      console.log("referralProp", this.referralProp.name_fa);
      this.referral = this.referralProp;
    }
  },
  methods: {
    closeModall() {
      this.$emit("cancel");
    },
    addNewReferral() {
      const now = new Date();
      const contractDate = new Date(this.referral_contract_contract_time);
      const diff = Math.abs(now.getTime() - contractDate.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      this.referral.referral_contract_data.contract_time = diffDays;
      let contract_percent = this.referral.referral_contract_data.descending_slope_percentage.split(",");
      let p = [];
      contract_percent.forEach((item) => {
        p.push(Number(item));
      });
      this.referral.referral_contract_data.descending_slope_percentage = p;
      this.referral.referral_contract_data.contract_percent = Number(
        this.referral.referral_contract_data.contract_percent
      );
      this.referral.user_id = Number(this.referral.user_id);

      const payload = JSON.parse(JSON.stringify(this.referral));

      this.$http.post(`${this.$store.state.appConfig.base_url}/api/v1/users/referrals/admin`, payload).then((res) => {
        console.log(res);
        this.$emit("cancel");
      });
    },
  },
};
</script>
