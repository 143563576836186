<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col-12">
          <span>{{ `Do you really want to delete "${this.currentTier.name}"?` }}</span>
        </div>
        <div class="col-6">
          <div class="input-item">
            <div class="input-label">Select Alternative Role</div>
            <select class="form-control" v-model="selectedAlt">
              <option v-for="tier in tiers" :value="tier" :key="tier.name">{{ tier.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="m-2" @click="deleteTier">
        Delete
      </b-button>
      <b-button @click="$emit('cancel')" variant="danger" class="m-2">Cancel</b-button>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["currentTier", "tiers", "isAdmin"],
  data() {
    return {
      selectedAlt: {},
    };
  },
  methods: {
    deleteTier() {
      let url = "";
      if (!this.isAdmin) {
        url = `${this.$store.state.appConfig.base_url}/api/v1/userTiers/deleteTier/${this.currentTier.id}/${this.selectedAlt.id}`;
      } else {
        url = `${this.$store.state.appConfig.base_url}/api/v1/admin/roles/${this.currentTier.id}/${this.selectedAlt.id}`;
      }
      this.$http
        .delete(url)
        .then(({ data }) => {
          //   this.currentTier = null;
          //   this.$bvToast.toast(`${this.currentTier.name} was deleted successfully`, {
          //     title: `Delete Tier`,
          //     variant: "success",
          //     solid: true,
          //   });
          this.$emit("cancel");
        })
        .catch((err) => {
          //   console.error("Error deleting tier", err);
          //   this.$bvToast.toast(`Error deleting ${this.currentTier.name}`, {
          //     title: `Delete Tier Error`,
          //     variant: "danger",
          //     solid: true,
          //   });
          this.$emit("cancel");
        })
        .finally(() => {});
    },
  },
};
</script>

<style></style>
