export default [
  {
    title: "Packages",
    icon: "SquareIcon",
    tagVariant: "light-warning",
    children: [
      {
        title: "Categories",
        route: "packages-category",
        resource: "packages",
        action: "read",
      },
      {
        title: "Packages",
        route: "packages",
        resource: "packages",
        action: "read",
      },
      {
        title: "Services",
        route: "packages-service",
        resource: "packages",
        action: "read",
      },
      {
        title: "Bot Services",
        route: "packages-bot-ervices",
        resource: "packagebotservices",
        action: "read",
      },
    ],
  },
];
