export default [
    {
      path: "/exchange-token/",
      name: "exchange-token",
      meta: {
        action: "read",
        resource: "exchangetoken",
      },
      component: () => import("@/views/pages/exchange-token/ExchangeToken.vue"),
    },
  ];
  