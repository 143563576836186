<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title>Permission according to tiers</b-card-sub-title>
    </b-card-body>
    <b-table striped responsive :items="permissionsData" class="mb-0">
      <template #cell(subject)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox v-model="data.value" @change="changeAction(data)" />
      </template>
    </b-table>

    <!-- <div v-if="$ability.can('manage', 'user')">Read User0</div>
    {{ $ability.can("manage", "user") }}
    <div>Read User</div> -->
  </b-card>
</template>

<script>
import { BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox } from "bootstrap-vue";
import { AbilityBuilder, Ability } from "@casl/ability";
// import { ABILITY_TOKEN } from "@casl/vue";

export default {
  // inject: {
  //   $ability: { from: ABILITY_TOKEN },
  // },
  props: ["currentTier", "defaultPermissions"],
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
  },
  data() {
    return {
      Actions: ["manage", "read", "create", "edit", "delete"],
      Subjects: ["user", "tiers"],

      permissionsData: [],
    };
  },
  methods: {
    changeAction(data) {
      let subject = data.item.subject;
      let key = data.field.key;
      let value = data.value;
      console.log("subject", subject);
      console.log("key", key);
      console.log("value", value);
      for (let index = 0; index < this.permissionsData.length; index++) {
        let permision = this.permissionsData[index];
        if (permision.subject.toLowerCase() == subject.toLowerCase()) {
          permision[key] = value;
          if (key.toLowerCase() == "manage" && value) {
            permision["Edit"] = true;
            permision["Read"] = true;
            permision["Delete"] = true;
            permision["Create"] = true;
          } else {
            if (!value) {
              permision["Manage"] = false;
            }
          }
          if (permision["Read"] && permision["Edit"] && permision["Delete"] && permision["Create"]) {
            permision["Manage"] = true;
          }
        }
        this.permissionsData[index] = permision;
        this.$emit("changePermissions", this.permissionsData);
      }
    },
    saveTier() {},

    createPermissions() {
      this.permissionsData = this.currentTier.admin_abilities;
      this.defaultPermissions.forEach((element) => {
        let contains = false;
        let sub = element.subject;
        console.log("element.subject", element.subject);

        this.permissionsData.forEach((item) => {
          if (sub == item.subject) {
            contains = true;
          }
        });
        console.log("contains", contains);
        if (!contains) {
          this.permissionsData.push(element);
        }
      });
    },
  },
  created() {
    this.createPermissions();
  },
  watch: {
    currentTier: function (val) {
      this.createPermissions();
    },
  },
};
</script>

<style></style>
