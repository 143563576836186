<template>
  <layout-vertical>
    <router-view />

    <app-customizer v-if="showCustomizer" slot="customizer" />
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import { $themeConfig } from "@themeConfig";
import { AbilityBuilder, Ability } from "@casl/ability";

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
  methods: {
    getUserProfile() {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/userprofile`)
        .then((res) => {
          const { can, rules } = new AbilityBuilder(Ability);
          can("read", "auth");

          localStorage.setItem("userData", JSON.stringify(res.data.data));

          res.data.data.admin_abilities.forEach((item) => {
            let keys = Object.keys(item);
            keys.forEach((key) => {
              let obj = item[key];
              if (typeof obj == "boolean") {
                if (obj == true) {
                  let action = "can " + key;
                  let subject = item["subject"];
                  can(key.toLowerCase(), subject.toLowerCase());
                } else {
                  let action = "canNot " + key;
                  let subject = item["subject"];
                }
              }
            });
          });

          this.$ability.update(rules);
          this.$store.commit("appConfig/SET_ABILITIES", true);

          // localStorage.setItem("isAbilitiesSet",true);
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.$store.commit("appConfig/SET_ABILITIES", false);

    this.getUserProfile();
  },
};
</script>
