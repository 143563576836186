<template>
  <div>
    <div class="d-flex d-flex-column">
      <div class="d-flex flex-row justify-content-center align-items-center mb-4">
        <span style="white-space: nowrap">Auto Withdrawal Amount</span>
        <b-form-input
          v-model="autoWithdrawalAmount"
          type="number"
          placeholder="Usd Value"
          class="mr-4 ml-4"
        ></b-form-input>
        <b-dropdown variant="primary" id="dropdown-1" :text="selectedUnit.toUpperCase()" class="mr-4 ml-4">
          <b-dropdown-item v-for="item in currencies" :key="item" @click="() => selectUnit(item)">{{
            item.toUpperCase()
          }}</b-dropdown-item>
        </b-dropdown>
        <b-button variant="success" class="mr-4 ml-4" @click="() => updateAutoWithdrawalAmount()">Save</b-button>
      </div>
    </div>
    <b-table striped responsive :items="requests" :fields="fields">
      <!-- <template #cell(status)="data">
        <div>
          <span class="btn btn-warning" v-if="data.item.rejected">Rejected</span>
          <span class="btn btn-success" v-else-if="data.item.processing">Processing</span>
          <span class="btn btn-primary" v-else>Waiting</span>
        </div>
      </template> -->

      <template #cell(status)="data">
        <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
          {{ data.item.status }}
        </b-badge>
      </template>

      <!-- <template #cell(status)="data">
        <b-badge variant="success" class="btn-block">
          {{ data.item.status }}
        </b-badge>
      </template> -->

      <template #cell(options)="data">
        <div>
          <b-dropdown id="dropdown-1" text="Options" class="mr-4 ml-4" variant="gradient-primary">
            <b-dropdown-item @click="() => acceptReq(data.ite.id)">Accept</b-dropdown-item>
            <b-dropdown-item @click="() => rejectReq(data.ite.id)">Reject</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>
          <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }"> -->
          <b-dropdown-item @click="changeRequestStatus(data.item.id, 'USER_APPROVED')">
            <feather-icon icon="ShieldIcon" class="text-success" />
            <span class="align-middle ml-50">Accept</span>
          </b-dropdown-item>

          <b-dropdown-item @click="() => changeRequestStatus(data.item.id, 'ADMIN_REJECTED')">
            <feather-icon icon="ShieldOffIcon" class="text-danger" />
            <span class="align-middle ml-50">Reject</span>
          </b-dropdown-item>

          <b-dropdown-item @click="() => changeRequestStatus(data.item.id, 'PENDING_ADMIN')">
            <feather-icon icon="AlertTriangleIcon" class="text-warning" />
            <span class="align-middle ml-50">Pending</span>
          </b-dropdown-item>
          <!-- <b-dropdown-item>
            <feather-icon icon="EyeIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item> -->
        </b-dropdown>
      </template>
    </b-table>
    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rows: 10,
      enumStatus: [
        "PENDING_USER",
        "PENDING_ADMIN",
        "USER_APPROVED",
        "ADMIN_APPROVED",
        "APPROVED",
        "USER_REJECTED",
        "ADMIN_REJECTED",
        "REJECTED",
      ],
      autoWithdrawalAmount: 0,
      selectedUnit: "btc",
      currencies: [
        "BTC",
        "USDT",
        "ETH",
        "XHT",
        "XRP",
        "BCH",
        "LINK",
        "XLM",
        "XMR",
        "ADA",
        "DAI",
        "UNI",
        "BNB",
        "LTC",
        "DOGE",
        "DOT",
      ],
      requests: [],
      fields: ["amount", "currency", "email", "fullname", "address", "network", "status", "actions"],
    };
  },
  methods: {
    resolveUserStatusVariant(status) {
      if (status.includes("PENDING")) return "warning";
      if (status.includes("REJECTED")) return "danger";
      if (status.includes("APPROVED")) return "success";
      return "primary";
    },
    selectUnit(unit) {
      console.log(unit);
      this.selectedUnit = unit;
      this.getAutoWithdrawalValue();
    },
    getAutoWithdrawalValue() {
      this.$http
        .get(
          `${
            this.$store.state.appConfig.base_url
          }/api/v1/token-managements/getAutoWithdrawalAmount/${this.selectedUnit.toUpperCase()}`
        )
        .then((res) => {
          console.log(res.data.data);
          if (res) {
            this.autoWithdrawalAmount = res.data.data.amount;
          } else {
            this.autoWithdrawalAmount = 0;
          }
        })
        .catch((err) => (this.autoWithdrawalAmount = 0));
    },
    updateAutoWithdrawalAmount() {
      let data = {
        amount: this.autoWithdrawalAmount,
        unit: this.selectedUnit.toUpperCase(),
      };
      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/updateAutoWithdrawalAmount`, data)
        .then((res) => {
          this.getAutoWithdrawalValue();
        })
        .catch((err) => this.getAutoWithdrawalValue());
    },
    getRequests() {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/getAdminPendingWithdrawals`)
        .then((result) => {
          console.log(result);
          this.requests = result.data.data;
        })
        .catch((err) => {});
    },
    acceptReq(reqId) {
      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/adminApproveWithrawal/${reqId}`)
        .then((result) => {
          console.log(result);
          this.getRequests();
        })
        .catch((err) => {});
    },
    rejectReq(reqId) {
      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/adminApproveWithrawal/${reqId}`)
        .then((result) => {
          console.log(result);
          this.getRequests();
        })
        .catch((err) => {});
    },

    changeRequestStatus(reqId, status) {
      this.$http
        .patch(
          `${this.$store.state.appConfig.base_url}/api/v1/token-managements/changeWithrawalStatus/${reqId}/${status}`
        )
        .then((result) => {
          console.log(result);
          this.getRequests();
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getRequests();
    this.getAutoWithdrawalValue();
  },
};
</script>

<style></style>
