import { $themeConfig } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    actions: {
      Manage: "Manage",
      Read: "Read",
      create: "create",
      Edit: "Edit",
      Delete: "Delete",
    },
    subjects: {},
  },
  getters: {},
  actions: {},
};
