/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import tokenManagements from "./token-managements";
import exchange from "./exchange";
import users from "./users";
import exchangeKeys from "./exchange-keys";
import packages from "./packages";
import referral from "./referral";
import contactUs from "./contact-us";
import paymentGateway from "./payment-gateway";
import servers from "./servers";
import saleReport from "./sales-report";
import exchangeToken from "./exchange-token";
import signalsReport from "./signals-report";

// Array of sections
export default [
  ...users,
  ...packages,
  ...referral,
  ...tokenManagements,
  ...exchange,
  ...exchangeKeys,
  ...contactUs,
  ...paymentGateway,
  ...servers,
  ...saleReport,
  ...exchangeToken,
  ...signalsReport,
];
