export default [
  {
    path: "/contact-us/",
    name: "contact-us",
    meta: {
      action: "read",
      resource: "support",
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
    },
    component: () => import("@/views/pages/contact-us/Email"),
  },
  {
    path: "/contact-us/:folder",
    name: "apps-email-folder",
    component: () => import("@/views/pages/contact-us/Email"),
    meta: {
      action: "read",
      resource: "support",
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (["sent", "draft", "starred", "spam", "trash", "inbox"].includes(to.params.folder)) next();
      else next({ name: "error-404" });
    },
  },
];
