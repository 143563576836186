import * as yup from "yup";

const passwordShape = yup
  .string()
  .matches(/\d/, "Should contain at least one lowercase, one uppercase, one digit and an special character (!,#,$,...)")
  .matches(
    /[a-z]/,
    "Should contain at least one lowercase, one uppercase, one digit and an special character (!,#,$,...)"
  )
  .matches(
    /[A-Z]/,
    "Should contain at least one lowercase, one uppercase, one digit and an special character (!,#,$,...)"
  )
  .matches(/\W/, "Should contain at least one lowercase, one uppercase, one digit and an special character (!,#,$,...)")
  .min(8)
  .required();

const login = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const newUser = yup.object().shape({
  name: yup.string().required(),
  username: yup.string().required(),
  email: yup.string().email().required(),
  password: passwordShape,
  user_tier: yup.string().required(),
});

const tier = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
});

const passwordComplexity = yup.object().shape({
  password: passwordShape,
});

const validationSchemas = {
  login,
  passwordComplexity,
  tier,
  newUser,
};

const validate = (type, payload) => {
  if (!validationSchemas[type]) return console.error(`Validation "${type}" not found`);

  try {
    validationSchemas[type].validateSync(payload, { abortEarly: false });
    return null;
  } catch (err) {
    let validationErrors = {};
    err.inner.forEach((errObj) => {
      let { path, errors } = errObj;
      validationErrors[path] = errors[0];
    });
    return validationErrors;
  }
};

export default validate;
