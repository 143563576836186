<template>
  <div>
    <!-- <p v-if="errors.length">
    <b>Please correct the following error(s):</b>
    <ul>
      <li v-for="error in errors" :key="error.text">{{ error.text }}</li>
    </ul>
      </p>-->
    <div class="row input-group">
      <div class="col-md-4">
        <div class="input-item m-1">
          <div class="input-label">Fa name</div>
          <input class="form-control" required type="text" v-model="exchange.name_fa" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">En name</div>
          <input class="form-control" required type="text" v-model="exchange.name_en" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">market Count</div>
          <input class="form-control" disabled type="number" step="0.01" v-model="exchange.market_count" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Coins Count</div>
          <input class="form-control" disabled type="number" step="0.01" v-model="exchange.coins_count" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">pairs</div>
          <input class="form-control" disabled type="number" step="0.01" v-model="exchange.pairs" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Market Cap</div>
          <input class="form-control" disabled type="number" step="0.01" v-model="exchange.market_cap" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Last Update</div>
          <input class="form-control" disabled type="text" v-model="exchange.last_update" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Base</div>
          <input class="form-control" disabled type="number" step="0.01" v-model="exchange.base" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Score</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.score" />
        </div>
      </div>

      <div class="col-md-4">
        <div class="input-item m-1">
          <div class="input-label">Logo</div>
          <input class="form-control" required type="text" v-model="exchange.logo" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Website</div>
          <input class="form-control" required type="text" v-model="exchange.website" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Activity Start</div>
          <input class="form-control" required type="text" v-model="exchange.activity_start" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Rank</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.rank" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Grade</div>
          <input class="form-control" maxlength="2" type="text" v-model="exchange.grade" required />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Investment</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.investment" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Legal</div>

          <input class="form-control" required type="number" step="0.01" v-model="exchange.legal" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">is_active</div>
          <input class="form-control" style="width: 20px; height: 20px" type="checkbox" v-model="exchange.is_active" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Deal Available</div>

          <input
            class="form-control"
            style="width: 20px; height: 20px"
            type="checkbox"
            v-model="exchange.deal_available"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="input-item m-1">
          <div class="input-label">Security</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.security" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Data Provition</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.data_provision" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Market Quality</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.market_quality" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Trade Surveillence</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.trade_surveillence" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Executive Management</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.executive_management" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Negative Reports</div>
          <input class="form-control" disabled type="number" step="0.01" v-model="exchange.negative_reports" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">User Experience</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.user_experience" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Incentives</div>
          <input class="form-control" disabled type="number" step="0.01" v-model="exchange.incentives" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Encouragements</div>
          <input class="form-control" required type="number" step="0.01" v-model="exchange.encouragements" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <span class="input-group-btn">
        <button v-if="exchange.id == 0" @click="() => addNewWxchange()" class="btn btn-primary" type="submit">
          Submit
        </button>
        <button v-else @click="() => updateExchange()" class="btn btn-primary" type="submit">Update</button>
      </span>
      <span class="input-group-btn">
        <button @click="() => closeModal()" class="btn btn-primary" type="submit">Cancel</button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errors: [],
      exchange: {
        id: "",
        name_fa: "",
        name_en: "",
        market_count: "",
        coins_count: "",
        pairs: "",
        market_cap: "",
        logo: "",
        website: "",
        activity_start: "",
        last_update: "2022",
        rank: "",
        grade: "",
        is_active: false,

        base: "",
        score: "",
        legal: "",
        security: "",
        data_provision: "",
        market_quality: "",
        trade_surveillence: "",
        investment: "",
        executive_management: "",
        negative_reports: "",
        user_experience: "",
        incentives: "",
        encouragements: "",
        deal_available: false,
      },
    };
  },
  props: {
    referralProp: {},
  },
  created() {
    if (this.exchangeProp.name_fa) {
      console.log("exchangeProp", this.exchangeProp.name_fa);
      this.exchange = this.exchangeProp;
    }
  },
  methods: {
    closeModal() {
      this.$emit("cancel");
    },
    addNewWxchange() {
      if (typeof this.exchange.is_active == "number") {
        this.exchange.is_active = this.exchange.is_active == 1;
      }
      if (typeof this.exchange.deal_available == "number") {
        this.exchange.deal_available = this.exchange.deal_available == 1;
      }
      const payload = JSON.parse(JSON.stringify(this.exchange));

      this.$http.post(`${this.$store.state.appConfig.base_url}/api/v1/exchanges/addExchange`, payload).then((res) => {
        console.log(res);
        this.$emit("cancel");
      });
    },

    updateExchange() {
      if (typeof this.exchange.is_active == "number") {
        this.exchange.is_active = this.exchange.is_active == 1;
      }
      if (typeof this.exchange.deal_available == "number") {
        this.exchange.deal_available = this.exchange.deal_available == 1;
      }
      this.exchange.last_update = new Date();
      const payload = JSON.parse(JSON.stringify(this.exchange));

      this.$http
        .post(`${this.$store.state.appConfig.base_url}/api/v1/exchanges/updateExchange/${this.exchange.id}`, payload)
        .then((res) => {
          console.log(res);
          this.$emit("cancel");
        });
    },
  },
};
</script>
