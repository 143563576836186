var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('users-list-filters',{attrs:{"tier-options":_vm.tiers},on:{"search":_vm.search}}),_c('div',{staticClass:"mb-3 d-flex align-items-center"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.createNewUser}},[_vm._v(" Add New User ")])],1),_c('div',[(_vm.isLoading)?_c('div',[_c('b-card',[_c('loading')],1)],1):_c('b-card',[_c('b-table',{attrs:{"responsive":"","id":"users-table","striped":"","items":_vm.users,"fields":_vm.fields},on:{"sort-changed":_vm.sortingChanged},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getItemIndex(data.index + 1))+" ")]}},{key:"cell(details)",fn:function(data){return [_c('button',{staticClass:"btn btn-sm text-muted",on:{"click":function($event){return _vm.showUserDetails(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"24"}})],1)]}},{key:"cell(register_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.showRegisteredDate(data.item.register_date))+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'user-profile', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Details")])],1),_c('b-dropdown-item',{attrs:{"to":{
                name: 'create-referrals',
                params: {
                  id: data.item.id,
                  username: data.item.username,
                  email: data.item.email,
                  full_name: data.item.name,
                },
              }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Create Referral")])],1),_c('b-dropdown-item',[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]}}])}),_c('b-row',{staticClass:"m-2"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"3","md":"3"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.usersPerPage),callback:function ($$v) {_vm.usersPerPage=$$v},expression:"usersPerPage"}}),_c('label',[_vm._v("entries")])],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-pagination',{attrs:{"hide-goto-end-buttons":"","total-rows":_vm.usersCount},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('b-modal',{attrs:{"size":"lg","hide-footer":"","title":"User Details","centered":"","scrollable":""},model:{value:(_vm.showAddNewUserModal),callback:function ($$v) {_vm.showAddNewUserModal=$$v},expression:"showAddNewUserModal"}},[_c('div',[_c('div',[_c('AddNewUser',{on:{"cancel":function($event){_vm.showAddNewUserModal = false},"updated":function () { return _vm.getUserDetails(_vm.userData.id); }}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }