<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <h2 class="brand-text text-primary ml-1">Ramzarz</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2"> Welcome to Ramzarz! 👋 </b-card-title>
          <b-card-text class="mb-2"> Please sign-in to your account and start the adventure </b-card-text>
          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group v-if="!otpInputStep" label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group v-if="!otpInputStep">
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group v-if="otpInputStep">
                <div class="d-flex justify-content-between">
                  <label for="login-password">2FA Code</label>
                </div>
                <validation-provider #default="{ errors }" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-otp"
                      v-model="otp_code"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-otp"
                      placeholder="2FA Code"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> Remember Me </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="invalid"> Sign in </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(VueReCaptcha, {
  siteKey: "6Lc1YnsaAAAAAOHriQlDAzPzbF4wpMPM8XYnm13e",
  loaderOptions: {
    useRecaptchaNet: true,
  },
});
import { AbilityBuilder, Ability } from "@casl/ability";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
      otpInputStep: false,
      otp_code: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    getUserProfile() {
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/userprofile`)
        .then((res) => {
          const { can, rules } = new AbilityBuilder(Ability);

          res.data.admin_abilities.forEach((item) => {
            console.log("getUserProfilegetUserProfilegetUserProfilegetUserProfilegetUserProfile", item);

            // if (user.role === "admin") {
            can(item.action, item.subject);
            // can("manage", "all");
            // } else {
            //   can("read", "all");
            // }
          });
          this.$ability.update(rules);
        })
        .catch((err) => {});
    },
    login() {
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          await this.$recaptchaLoaded();

          const token = await this.$recaptcha("login");
          console.log("tokentoken", token);

          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              captcha: token,
              otp_code: this.otp_code,
            })
            .then((response) => {
              useJwt.setToken(response.data.data.token);
              // useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem(
                "userData",
                '{"id":1,"fullName":"Admin","username":"johndoe","avatar":"/img/13-small.d796bffd.png","email":"admin@demo.com","role":"admin","ability":[{"action":"manage","subject":"all"}],"extras":{"eCommerceCartItemsCount":5}}'
              );

              this.$ability.update([{ action: "manage", subject: "all" }]);

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              let res = getHomeRouteForLoggedInUser("admin");
              this.$router.replace(getHomeRouteForLoggedInUser("admin")).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome ${userData.fullName || userData.username}`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                  },
                });
              });
            })
            .catch((error) => {
              console.log("errorerrorerrorerror", error.response);

              if (error.response?.data?.error) {
                let message = error.response.data?.message;
                let check1 = message.includes("کد");
                let check2 = message.includes("اشتباه");
                // let check3 = message.includes("نیست");
                if (error.response.status == 428) {
                  this.otpInputStep = true;
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `اعلان`,
                      icon: "CoffeeIcon",
                      variant: "warning",
                      text: `لطفا کد یک بار مصرف خود را وارد کنید`,
                    },
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Error`,
                      icon: "CoffeeIcon",
                      variant: "danger",
                      text: message,
                    },
                  });
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `خطا`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: ` ${error.response.data.message}`,
                  },
                });
              }

              // console.log("errorerrorerror", error.response.data.message);
              // this.$refs.loginForm.setErrors(error.response.data.message);
              // this.$refs.loginForm.setErrors("password");
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
