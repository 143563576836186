<template>
  <div>
    <div v-if="isLoading">
      <loading />
    </div>

    <div v-else>
      <b-form>
        <b-row>
          <b-col class="col-12">
            <b-form-group label="Select Coin" label-for="dropdown-1" label-cols-md="2">
              <b-dropdown variant="primary" id="dropdown-1" :text="values.coin.toUpperCase()" class="mr-4 ml-4">
                <b-dropdown-item v-for="unit in units" :key="unit" @click="() => changeTokenUnit(unit)">{{
                  unit
                }}</b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </b-col>
          <b-col class="col-12">
            <b-form-group label="Withdrawal Fee" label-for="h-first-name" label-cols-md="2">
              <b-form-input
                v-model="values.withdrawal_fee"
                type="number"
                placeholder="Usd Value"
                class="mr-4 ml-4 col-4"
                id="h-first-name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-12">
            <b-form-group label="Internal Transaction Fee" label-for="internal-fee-id" label-cols-md="2">
              <b-form-input
                v-model="values.internal_transaction_fee"
                type="number"
                placeholder="Usd Value"
                id="internal-fee-id"
                class="mr-4 ml-4 col-4"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col class="col-12">
            <b-form-group label="Min Withdrawal" label-for="min-withdrawal-id" label-cols-md="2">
              <b-form-input
                v-model="values.min_withdrawal"
                type="number"
                placeholder="Usd Value"
                class="mr-4 ml-4 col-4"
                id="min-withdrawal-id"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col class="col-12">
            <b-form-group label="Min Internal Transaction" label-for="min-internal-transaction-id" label-cols-md="2">
              <b-form-input
                v-model="values.min_internal_transaction"
                type="number"
                placeholder="Usd Value"
                class="mr-4 ml-4 col-4"
                id="min-internal-transaction-id"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="btn btn-success pr-3 pl-3" @click="() => updateFees()">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import loading from "./../../../components/loading";
export default {
  components: { Ripple, loading },
  data() {
    return {
      values: {
        coin: "BTC",
        internal_transaction_fee: 0,
        withdrawal_fee: 0,
        min_withdrawal: 0,
        min_internal_transaction: 0,
      },
      isLoading: false,
      units: [
        "BTC",
        "USDT",
        "ETH",
        "XHT",
        "XRP",
        "BCH",
        "LINK",
        "XLM",
        "XMR",
        "ADA",
        "DAI",
        "UNI",
        "BNB",
        "LTC",
        "DOGE",
        "DOT",
      ],
    };
  },
  methods: {
    changeTokenUnit(value) {
      this.values.coin = value;
      this.getFees();
    },
    getFees() {
      this.isLoading = true;
      console.log(this.values);
      this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/getTransactionFees/${this.values.coin}`)
        .then((res) => {
          this.isLoading = false;

          console.log(res);
          if (res.data.data) {
            this.values = res.data.data;
          } else {
            this.values.internal_transaction_fee = 0;
            this.values.withdrawal_fee = 0;
            this.values.min_withdrawal = 0;
            this.values.min_internal_transaction = 0;
          }
        })
        .catch((err) => {
          this.isLoading = false;

          console.log(err);
        });
    },
    updateFees() {
      this.isLoading = true;

      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/token-managements/updateTransactionFees`, this.values)
        .then((res) => {
          this.isLoading = false;

          console.log(res);
          this.getFees();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "InfoIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.isLoading = false;

          console.log(err);
        });
    },
  },
  mounted() {
    this.getFees();
  },
};
</script>

<style></style>
