import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";

import apps from "./routes/apps";
import pages from "./routes/pages";
import others from "./routes/others";
import packages from "./routes/packages";
import tokenManagements from "./routes/token-managements";
import exchange from "./routes/exchange";
import users from "./routes/users";
import exchangeKeys from "./routes/exchange-key";
import referral from "./routes/referral";
import contactUs from "./routes/contact-us";
import paymentGateway from "./routes/payment-gateway";
import servers from "./routes/servers";
import saleReport from "./routes/sale-report";
import exchangeToken from "./routes/exchange-token";
import signalsReport from "./routes/signals-report";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "users-list" } },
    ...apps,
    ...pages,
    ...others,
    ...tokenManagements,
    ...exchange,
    ...users,
    ...exchangeKeys,
    ...packages,
    ...referral,
    ...contactUs,
    ...paymentGateway,
    ...servers,
    ...saleReport,
    ...exchangeToken,
    ...signalsReport,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  checkCanRoute(to, next, isLoggedIn);

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

function checkCanRoute(to, next, isLoggedIn) {
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });

    if (store.state.appConfig.isAbilitiesSet) {
      return next({ name: "not-auth" });
    } else {
      setTimeout(() => {
        checkCanRoute(to, next, isLoggedIn);
      }, 100);
    }
  }
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
