export default {
  namespaced: true,
  state: {
    token: "",
    user_profile: "",
  },
  getters: {},
  mutations: {
    UPDATE_TOKEN(state, token) {
      state.token = token;
    },

    UPDATE_PROFILE(state, profile) {
      state.user_profile = profile;
    },
  },
};
