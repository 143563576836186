<template>
  <div>
    <!-- <p v-if="errors.length">
    <b>Please correct the following error(s):</b>
    <ul>
      <li v-for="error in errors" :key="error.text">{{ error.text }}</li>
    </ul>
      </p>-->
    <div class="row input-group">
      <div class="col-md-12">
        <div class="input-item m-1">
          <div class="input-label">Fa name</div>
          <input class="form-control" required type="text" v-model="server.fa_name" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">En name</div>
          <input class="form-control" required type="text" v-model="server.name" />
        </div>
        <div class="input-item m-1">
          <div class="input-label">Country Name</div>
          <input class="form-control" required type="text" v-model="server.country_name" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Fa Country Name</div>
          <input class="form-control" required type="text" v-model="server.fa_country_name" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">ip</div>

          <input v-if="server.ip" class="form-control" disabled type="text" v-model="server.ip" />
          <input v-else class="form-control" type="text" v-model="server.ip" />
        </div>

        <div class="input-item m-1">
          <div class="input-label">Capacity</div>
          <input class="form-control" type="number" step="1" v-model="server.capacity" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <span class="input-group-btn">
        <button v-if="server.id == 0" @click="() => addNewServer()" class="btn btn-primary" type="submit">
          Submit
        </button>
        <button v-else @click="() => updateServer()" class="btn btn-primary" type="submit">Update</button>
      </span>
      <span class="input-group-btn">
        <button @click="() => closeModal()" class="btn btn-primary" type="submit">Cancel</button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errors: [],
      server: {},
    };
  },
  props: {
    serverProp: {},
  },
  created() {
    if (this.serverProp.name) {
      console.log("serverProp", this.serverProp);
      this.server = this.serverProp;
    }
  },
  methods: {
    closeModal() {
      this.$emit("cancel");
    },
    addNewServer() {
      console.log("this.server", this.server);
      this.server.capacity = +this.server.capacity;

      const payload = JSON.parse(JSON.stringify(this.server));

      this.$http.post(`${this.$store.state.appConfig.base_url}/api/v1/servers`, payload).then((res) => {
        console.log(res);
        this.$emit("cancel");
      });
    },

    updateServer() {
      this.server.capacity = +this.server.capacity;
      const payload = JSON.parse(JSON.stringify(this.server));

      this.$http
        .patch(`${this.$store.state.appConfig.base_url}/api/v1/servers/${this.server.id}`, payload)
        .then((res) => {
          console.log(res);
          this.$emit("cancel");
        });
    },
  },
};
</script>
