<template>
  <div>
    <div>
      <div v-if="isLoading">
        <b-card>
          <loading />
        </b-card>
      </div>
      <b-card v-else>
        <b-table
          responsive
          @sort-changed="sortingChanged"
          id="referral-users-table"
          striped
          :items="users"
          :fields="fields"
        >
          <template #cell(index)="data">
            {{ getItemIndex(data.index + 1) }}
            <!-- {{ usersPerPage * currentPage * data.index + 1 }} -->
          </template>
          <template #cell(details)="data">
            <button class="btn btn-sm text-muted" @click="showUserDetails(data.item.id)">
              <feather-icon icon="EyeIcon" size="24" />
            </button>
          </template>

          <template #cell(enable_referrals)="is_active">
            <button
              class="btn btn-success"
              @click="() => toggleActivation(is_active.item)"
              v-if="is_active.item.enable_referrals"
            >
              Active
            </button>

            <button class="btn btn-danger" @click="() => toggleActivation(is_active.item)" v-else>Inactive</button>
          </template>

          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'create-referrals',
                  params: {
                    id: data.item.user_id,
                    username: data.item.username,
                    email: data.item.email,
                    full_name: data.item.full_name,
                  },
                }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details/Update</span>
              </b-dropdown-item>

              <b-dropdown-item @click="() => showSaleReportModal(data.item)">
                <feather-icon icon="CreditCardIcon" />
                <span class="align-middle ml-50">Sales Report</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-row class="m-2">
          <b-col cols="3" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            {{ usersCount }}
            <label>Show</label>
            <v-select
              v-model="usersPerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="4">
            <b-pagination v-model="currentPage" hide-goto-end-buttons :total-rows="usersCount" />
          </b-col>
        </b-row>
      </b-card>
    </div>

    <!-- modal -->
    <b-modal v-model="ShowSaleReport" ref="my-modal" title="Sale Report">
      <table>
        <tr>
          <th>Package Name</th>
          <th>Count</th>
        </tr>
        <tr v-for="p in salesReport">
          <td>{{ p.package }}</td>
          <td>{{ p.sales_count }}</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import loading from "./../../components/loading";
import AddReferralModal from "./AddReferralModal";
import vSelect from "vue-select";

export default {
  components: { AddReferralModal, loading, vSelect },
  data() {
    return {
      showAddNewReferralModal: false,
      ShowSaleReport: false,
      isLoading: false,
      usersPerPage: 5,
      usersCount: 0,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      selectedReferral: {},
      salesReport: [
        {
          package: "",
          sales_count: 0,
        },
      ],

      fields: [
        // A column that needs custom formatting
        { key: "email", sortable: true },
        { key: "referral_code_contract", sortable: true },
        // A regular column
        { key: "full_name", sortable: true },
        { key: "username", sortable: true },
        { key: "total_referrer", sortable: true },
        // A regular column
        "enable_referrals",
        // "details",
        "actions",
      ],
      users: [],
    };
  },
  methods: {
    showSaleReportModal(data) {
      this.ShowSaleReport = true;
      console.log("showSaleReportModal", data);
      this.getSalesReport(data.user_id);
    },
    toggleActivation(referral) {
      console.log(referral);
      this.isBusy = !this.isBusy;
      let act = referral.enable_referrals === true ? false : true;
      let status = "disable";
      if (act) {
        status = "enable";
      }
      this.$http
        .put(`${this.$store.state.appConfig.base_url}/api/v1/users/referrals/admin/${referral.user_id}/${status}`)
        .then((res) => {
          console.log(res);
          this.getUsers();
        });
    },

    getUsers() {
      this.isLoading = true;
      const params = {};
      return this.$http
        .get(
          `${this.$store.state.appConfig.base_url}/api/v1/users/referrals/admin?page=${this.currentPage}&limit=${this.usersPerPage}`,
          { params }
        )
        .then(({ data }) => {
          this.users = data.data;
          this.usersCount = data.count;
          this.isLoading = false;

          return this.users;
        })
        .finally(() => {
          this.isLoading = false;

          this.isFetching = false;
        });
    },
    getSalesReport(user_id) {
      return this.$http
        .get(`${this.$store.state.appConfig.base_url}/api/v1/users/referrals/admin/sales-report/${user_id}`)
        .then(({ data }) => {
          this.salesReport = data.data;
        })
        .finally(() => {
          this.isLoading = false;

          this.isFetching = false;
        });
    },
  },
  mounted() {
    this.getUsers();
  },
  watch: {
    currentPage: function () {
      this.getUsers();
    },
    usersPerPage: function () {
      this.getUsers();
    },
  },
};
</script>

<style></style>
